import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme, soldout, isMember }) => ({
  '&.BCcard': {
    height: '100%',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.16)',
    position: 'relative',
    opacity: soldout ? 0.4 : 1,
    '& .textTransformUppercase': {
      textTransform: 'uppercase',
    },
    '& .MuiCardActionArea-root': {
      height: '100%',
      '& .MuiCardActionArea-focusHighlight': {
        display: 'none',
      },
    },
    '& .BCHeader': {
      padding: theme.spacing(1.25),
      position: 'relative',
      '& .BCHeaderLabels': {
        position: 'absolute',
        width: `calc(100% - ${theme.spacing(2)})`,
        top: theme.spacing(1),
        left: theme.spacing(1),
        overflow: 'visible',
        // zIndex: 1,
      },
    },
    '& .BCBikeSpecs ': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(0.75, 0.5),
      borderRadius: theme.spacing(3),
      width: theme.spacing(3.5),
      height: theme.spacing(3.5),
      color: theme.palette.grey[900],
      transition: 'border-color 0.5s, width 0.5s',
      border: `1px solid ${theme.palette.grey.A000}`,
      position: 'absolute',
      zIndex: 1,
      right: theme.spacing(0.5),
      top: theme.spacing(0.75),
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.grey.A000,
        border: `1px solid ${theme.palette.grey['100']}`,
        width: `calc(100% - ${theme.spacing(1)})`,
        '& .BCBikeSpecsText': {
          padding: theme.spacing(0, 2, 0, 1.25),
          textTransform: 'uppercase',
          opacity: 1,
        },
      },
      '& .BCBikeSpecsIcon': {
        fontSize: theme.typography.pxToRem(20),
      },
      '& .BCBikeSpecsText': {
        transition: 'padding 0.5s, text-decoration 0.5s',
        fontSize: theme.typography.pxToRem(12),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        color: theme.palette.grey[900],
        opacity: 0,
      },
    },

    '& .BClinkStyle': {
      height: '100%',
      color: 'initial',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },

    '& .BCfontsmall': {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
    },
    '& .BCLocation': {
      paddingRight: theme.spacing(2),
      // maxWidth: 'calc(100% - 130px)',
      '& svg': {
        paddingRight: theme.spacing(1),
        verticalAlign: 'middle',
        height: theme.spacing(2),
        minWidth: theme.spacing(3),
      },
      '& .BCOwnerName': {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(18),
      },
      '& .MuiTypography-root': {
        color: theme.palette.secondary.light,
      },
    },
    '& .BCHeaderItem': {
      padding: theme.spacing(0.25, 1),
    },
    '& .BCvalueDeal': {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.common.white,
      zIndex: 1,
    },
    '& .BCOnlyLeft': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      zIndex: 1,
    },
    '& .noMotorcycleLicense': {
      backgroundColor: theme.palette.grey[50],
      padding: theme.spacing(0.5, 1),
      color: theme.palette.grey[400],
      zIndex: 1,
      '& .MuiTypography-root': {
        lineHeight: theme.typography.pxToRem(16),
      },
    },
    '& .BCsoldout': {
      color: theme.palette.grey[900],
      backgroundColor: theme.palette.grey[100],
      fontSize: theme.typography.pxToRem(14),
      zIndex: 1,
      textAlign: 'center',
      '& svg': {
        fontSize: theme.typography.pxToRem(18),
        marginRight: theme.spacing(0.5),
        verticalAlign: 'middle',
      },
    },
    '& .BCcontent': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      // height: 'calc(100% - 200px)', // 200px is image height
      // paddingRight: credit ? theme.spacing(2) : theme.spacing(0),
      padding: theme.spacing(0.5, 2, 1.5),
      '& .BCMakeModel': {
        color: theme.palette.secondary.dark,
      },
      '& .BCmodal': {
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.typography.pxToRem(28),
      },
    },
    '& .BCAccordion': {
      boxShadow: 'none',
      zIndex: 1,
      position: 'absolute',
      right: 0,
      left: 0,
      margin: '0 auto',
      '&.BCAccordionWithValue': {
        top: theme.spacing(2.5),
      },
      '&:before': {
        content: 'none',
      },
      // '&.Mui-expanded': {
      //   minHeight: theme.spacing(2.5),
      //   backgroundColor: theme.palette.warning.main,
      //   left: 0,
      //   margin: theme.spacing(0, 1),
      // },
      '&.Mui-expanded': {
        zIndex: 2,
      },
      '& .BCAccordionSummary': {
        color: theme.palette.grey[900],
        backgroundColor: theme.palette.warning.main,
        width: 'fit-content',
        padding: theme.spacing(0, 1),
        minHeight: 'auto',
        '& .MuiAccordionSummary-content': {
          margin: 0,
        },
        // '& .MuiAccordionSummary-expandIconWrapper': {
        //   marginLeft: theme.spacing(1),
        // },
        // '& .BCExpandIcon': {
        //   color: theme.palette.grey[900],
        //   fontSize: theme.typography.pxToRem(18),
        // },
        '&.Mui-expanded': {
          width: '100%',
          zIndex: 2,
        },
      },
      '& .BCAccordionDetails': {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.grey[900],
        position: 'absolute',
        width: '100%',
        padding: theme.spacing(0, 1, 1, 1),
        '& .MuiDivider-root': {
          backgroundColor: theme.palette.warning.dark,
        },
        // borderTop: `1px solid ${theme.palette.warning.dark}`,
        // padding: 0,
      },
    },
    '& .BCContentCont': {
      height: '100%',
    },
    '& .BCContent': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    '& .BCBYtext': {
      fontWeight: 300,
      fontSize: theme.spacing(1.5),
      color: theme.palette.secondary.dark,
    },
    '& .BCDisplayName': {
      fontWeight: 400,
      color: theme.palette.secondary.dark,
    },
  },
  '& .BCCardActions': {
    padding: theme.spacing(0.7, 2, 1.2, 1.5),
    '& .BCnDailyPriceText': {
      letterSpacing: 1.5,
      fontSize: theme.typography.pxToRem(10),
      lineHeight: theme.typography.pxToRem(10),
    },
    '& .BCMembershipPrice': {
      textDecoration: !isMember && 'underline',
      whiteSpace: 'nowrap',
    },
    '& .BCCardActionsDivider': {
      margin: theme.spacing(0, 2),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0, 1),
      },
    },
    '& .BCnDailyPrice': {
      color: theme.palette.secondary.main,
      width: '100%',
    },
    '& .BCJoinLink': {
      textDecoration: 'underline',
    },
  },
  '& .BCSmallLineHeight': {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(14),
  },
  '& .BCPrice': {
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(36),
  },
  '& .BCCreditPrice': {
    fontSize: theme.typography.pxToRem(isMember ? 20 : 24),
    lineHeight: theme.typography.pxToRem(36),
    letterSpacing: 0,
  },
  '& .IBimgbox': {
    '& img': {
      padding: `${theme.spacing(0, 2)} !important`,
    },
  },
}));

export default StyledCard;
