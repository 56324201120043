import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .MFTitle': {
    color: theme.palette.secondary.dark,
    fontSize: theme.spacing(2.5),
    marginBottom: theme.spacing(2),
  },
  '& .MFaccordion': {
    backgroundColor: 'transparent',
    boxShadow: 'none',

    '&::before': {
      display: 'none',
    },
  },
  '& .MFaccSummary': {
    minHeight: `${theme.typography.pxToRem(48)} !important`,
    padding: '0',
    margin: '0 !important',
    '& svg': {
      color: theme.palette.grey['900'],
    },
  },
  '& .MFaccDetails': {
    padding: '0',
  },
  '& .MFaccDes': {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey['300'],
    lineHeight: theme.typography.pxToRem(18),
    '& a': {
      fontWeight: 500,
      fontSize: theme.spacing(1.75),
      color: theme.palette.primary.main,
    },
  },
  '& .MFloadMore': {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.grey['400'],
    '& svg': {
      marginLeft: theme.spacing(1),
    },
  },
  '&.MFAQDark': {
    '& .MFTitle': {
      display: 'none',
    },
    '& .MFaccordion': {
      borderBottom: `1px solid ${theme.palette.grey[500]}`,
      '& .MFaccSummary': {
        color: theme.palette.common.white,
        minHeight: 'unset !important',
        '& svg': {
          color: theme.palette.common.white,
        },
        '& .MFaccSummaryText': {
          fontFamily: 'Roboto Condensed',
        },
        '& .Mui-expanded': {
          margin: 0,
        },
      },
      '& .MFaccDes': {
        color: theme.palette.grey[100],
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(24),
        fontFamily: 'Roboto Condensed',
      },
    },
    '& .MFloadMore': {
      color: theme.palette.common.white,
      marginTop: theme.spacing(3),
    },
  },
}));

export default StyledBox;
