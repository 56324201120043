import React, { useState } from 'react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Typography500 from 'views/common/components/UI/Typography500';
import StyledBox from 'views/common/components/UI/MotorcycleRentalCardHorizontalVariant/NoMotorcycleLicenseLable/NoMotorcycleLicenseLable.styles';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';

const NoMotorcycleLicenseLable = () => {
  const { t } = useTranslation('fe_er_common_rental_card_horizontal_variant');
  const [isShowLocalLow, setIsShowLocalLow] = useState(false);
  const handleLocalLowClick = () => {
    setIsShowLocalLow((prev) => !prev);
  };

  return (
    <StyledBox onClick={handleLocalLowClick}>
      <Typography500
        component="p"
        variant="subtitle"
        className="NMLLNoLicenseText">
        {t('fe_er_common_rental_card_horizontal_variant:no_license')}
        {isShowLocalLow ? (
          <CloseIcon className="NMLLNoLicenseIcon" fontSize="small" />
        ) : (
          <InfoIcon className="NMLLNoLicenseIcon" fontSize="small" />
        )}
      </Typography500>
      {isShowLocalLow && (
        <Box className="MRCHLocalLawsBox">
          <Typography component="p" variant="subtitle">
            {t('fe_er_common_rental_card_horizontal_variant:local_laws')}
          </Typography>
        </Box>
      )}
    </StyledBox>
  );
};

export { NoMotorcycleLicenseLable };
