import React from 'react';
import { Grid, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';

const StyledBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[100]}`,
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(0.5),
  '& .MRCHVSDays': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  '& .MRCHVSImageAndDetilas': {
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column-reverse',
    },
  },
}));

const MotorcycleRentalCardHorizontalVariantSkeleton = () => (
  <StyledBox>
    <Grid container spacing={2}>
      <Grid item lg={8} md={12} xs={12}>
        <Grid container spacing={2} className="MRCHVSImageAndDetilas">
          <Grid item lg={6} md={12} xs={12}>
            <Skeleton variant="rectangular" width="100%" height={180} />
          </Grid>
          <Grid item lg={6} md={12} xs={12}>
            <Skeleton variant="text" width="100%" height={30} />
            <Skeleton variant="text" width="40%" height={30} />
            <Skeleton variant="text" width="40%" height={30} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={4} md={12} xs={12}>
        <Skeleton
          variant="text"
          width="100%"
          height={30}
          sx={{ marginTop: { lg: '30px', md: 0 } }}
        />
        <Box className="MRCHVSDays">
          <Skeleton variant="text" width="30%" height={30} />
          <Skeleton variant="text" width="30%" height={30} />
          <Skeleton variant="text" width="30%" height={30} />
        </Box>
        <Skeleton variant="text" width="100%" height={30} />
      </Grid>
    </Grid>
  </StyledBox>
);
export { MotorcycleRentalCardHorizontalVariantSkeleton };
