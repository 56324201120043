import React, { useMemo, useState } from 'react';
import StyledBox from 'views/common/components/Logical/MembershipOverlaySteps/MembershipOverlayAside/MembershipOverlayAside.styles';
import { useTranslation } from 'next-i18next';
import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  useMediaQuery,
  useTheme,
  Box,
} from '@mui/material';
import Typography500 from 'views/common/components/UI/Typography500';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ImgBox, Typography700 } from 'views/common/components';
import moneyFormat from 'lib/utils/moneyFormat';
import Dinero from 'lib/utils/Dinero';

const MembershipOverlayAside = (props) => {
  const { overlayState, membershipOverlayData } = props;

  const { t } = useTranslation('fe_er_common_membership_overlay');
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const [expanded, setExpanded] = useState(false);
  const handleExpandedChange = () => {
    setExpanded((prev) => !prev);
  };

  const membershipAsideData = useMemo(() => {
    const list = [];
    if (membershipOverlayData.user?.is_club_upgrade_eligible) {
      list.push(
        {
          title: t('fe_er_common_membership_overlay:extra_credits', {
            credits: 2,
          }),
          desc: t('fe_er_common_membership_overlay:extra_credit_desc', {
            credits: 2,
          }),
          rewardBenefit: true,
        },
        {
          title: t('fe_er_common_membership_overlay:initiation_fee', {
            initiation_fee: moneyFormat(
              Dinero(
                membershipOverlayData.promoted_membership_info.initiation_price,
              ),
            ),
          }),
          desc: t('fe_er_common_membership_overlay:tooltip_initiation_fee'),
          rewardBenefit: true,
          waved: true,
        },
      );
    }

    list.push({
      title: t('fe_er_common_membership_overlay:x_rental_credits_per_month', {
        months_count: '12',
        credits_count: overlayState.selectedMembership.rental_credits_per_month,
      }),
      desc: t('fe_er_common_membership_overlay:tooltip_x_rental_credit', {
        credits_count: overlayState.selectedMembership.rental_credits_per_month,
      }),
    });

    if (!membershipOverlayData.user?.is_club_upgrade_eligible) {
      list.push({
        title: t('fe_er_common_membership_overlay:initiation_fee', {
          initiation_fee: moneyFormat(
            Dinero(
              membershipOverlayData.promoted_membership_info.initiation_price,
            ),
          ),
        }),
        desc: t('fe_er_common_membership_overlay:tooltip_initiation_fee'),
      });
    }

    list.push(
      {
        title: t('fe_er_common_membership_overlay:rental_discount', {
          pl_rental_discount: '20%',
        }),
        desc: t('fe_er_common_membership_overlay:tooltip_rental_discount', {
          pl_rental_discount: '20%',
        }),
      },
      {
        title: t('fe_er_common_membership_overlay:credit_rollover'),
        desc: t(
          'fe_er_common_membership_overlay:tooltip_x_months_credit_rollover',
          {
            months_count: '18',
          },
        ),
      },
    );

    return list;
  }, []);
  const renderMembershipAsideData = () => membershipAsideData.map((item) => (
    <Grid
      container
      key={item.title}
      flexWrap="nowrap"
      pb={3}
      columnSpacing={1}>
      <Grid item className="MOACheckicon">
        <CheckIcon color="success" />
      </Grid>
      <Grid item>
        <Grid container>
          <Grid item lg={item.rewardBenefit ? 7 : 12} sm={12}>
            <Typography500
              variant="h4"
              component="p"
              className={`MOSOSecDark ${item.waved && 'MOSAStrikeThrough'}`}
              dangerouslySetInnerHTML={{
                __html: item.title,
              }}
              />
          </Grid>
          {item.rewardBenefit && (
          <Grid item lg={5} sm={12} className="MOARewardsLogo">
            <Box className="MOARewardsLogoContainer">
              <ImgBox
                image={{
                  src: '/imgs/rewards/RewardProgramLogo.svg',
                  alt: t(
                    'fe_er_common_membership_overlay:eaglerider_rewards',
                  ),
                }}
                imgWidth="100%"
                imgHeight="100%"
                  />
            </Box>
          </Grid>
          )}
          <Grid sm={12} item>
            <Typography
              variant="body2"
              component="p"
              className="MOADesc"
              dangerouslySetInnerHTML={{
                __html: item.desc,
              }}
              />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ));

  return (
    <StyledBox>
      <Typography500
        variant="h3"
        component="h4"
        className="MOSOUppercase MOSOSecMain showDESKTOP"
        pb={3}
        pt={3}>
        {overlayState.selectedMembership.name}
      </Typography500>
      <Typography500
        variant="h6"
        component="h6"
        className="MOSOSecMain showTABLET">
        {t('fe_er_common_membership_overlay:selected_plan', {
          plan_name: overlayState.selectedMembership.name,
        })}
      </Typography500>
      <Typography500
        variant="h2"
        component="p"
        className="MOAOrange"
        pb={3}
        dangerouslySetInnerHTML={{
          __html: t('fe_er_common_membership_overlay:plan_price', {
            price: moneyFormat(
              Dinero(
                overlayState.selectedMembership.monthly_subscription_price,
              ),
            ),
          }),
        }}
      />
      <Divider className="MOADivider" />
      {isTablet ? (
        <Accordion
          expanded={expanded}
          onChange={handleExpandedChange}
          className="MOAbenefitAccordin">
          <AccordionSummary
            className="MOAHiddenLg"
            expandIcon={<ExpandMoreIcon />}>
            <Typography700 variant="h6" component="p" className="MOSOUppercase">
              {t('fe_er_common_membership_overlay:show_benefits')}
            </Typography700>
          </AccordionSummary>
          <AccordionDetails>{renderMembershipAsideData()}</AccordionDetails>
        </Accordion>
      ) : (
        renderMembershipAsideData()
      )}
    </StyledBox>
  );
};

export { MembershipOverlayAside };
