import React from 'react';
import dayjs from 'dayjs';
import StyledDatePicker from 'views/common/components/UI/TourPackagesWidget/TourPackagesWidgetDatePicker/TourPackagesWidgetDatePicker.styles';

const TourPackagesWidgetDatePicker = (props) => {
  const {
    calendarOptions, handleDateChange, disableDates, pickupDate, label,
  } = props;

  return (
    <StyledDatePicker
      required
      fullWidth
      color="secondary"
      format="MM/DD/YYYY"
      minDate={dayjs(calendarOptions.min_date)}
      maxDate={dayjs(calendarOptions.max_date)}
      id="tour_start_date"
      label={label}
      placeholder="mm/dd/yyyy"
      value={dayjs(pickupDate || calendarOptions.min_date)}
      onChange={(date) => {
        handleDateChange(date);
      }}
      slotProps={{
        popper: {
          'data-testid': 'tour_start_date',
        },
        dialog: {
          'data-testid': 'tour_start_date',
        },
      }}
      shouldDisableDate={disableDates}
      views={['day']}
      disableCloseOnSelect={false}
    />
  );
};
export { TourPackagesWidgetDatePicker };
