import React from 'react';
import { Box } from '@mui/material';
import StyledBox, {
  StyledCarousel,
  StyledImgBox,
} from 'views/common/components/UI/MotorcycleRentals/MakeCarousel/MakeCarousel.styles';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1300 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 1300, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 0 },
    items: 2,
  },
};
const MakeCarousel = (props) => {
  const {
    selectedVehicleMakeID,
    vehicleMakes = [],
    handleVehicleMakeChange = () => {},
  } = props;
  const { t } = useTranslation('fe_er_common_make_carousel');
  const { isCrawler, deviceType } = useSelector((state) => state.globalData);

  const handleActiveMakeModelSelect = (id) => {
    handleVehicleMakeChange(id);
  };

  return (
    <StyledBox className="MCContainer">
      <StyledCarousel
        swipeable
        draggable={false}
        ssr={isCrawler}
        deviceType={deviceType}
        keyBoardControl
        autoPlay={false}
        shouldResetAutoplay={false}
        containerClass="carousel-container"
        customTransition="transform 300ms ease-in"
        showDots={false}
        responsive={responsive}>
        {vehicleMakes.map((item) => (
          <Box
            className={`MTSMakeCarouselItem ${
              item.id === selectedVehicleMakeID ? 'active' : ''
            }`}
            onClick={() => handleActiveMakeModelSelect(item.id)}
            key={item.id}>
            <StyledImgBox
              imgWidth="80px"
              imgHeight="80px"
              image={{
                src: item.image.medium_url,
                alt: t('fe_er_common_make_carousel:vehicle_make_image_alt', {
                  vehicle_make: item.name,
                }),
              }}
              imgObjectFit="contain"
            />
          </Box>
        ))}
      </StyledCarousel>
    </StyledBox>
  );
};
export { MakeCarousel };
