import { Box } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import theme from 'lib/config/theme';

const dotFlashing = keyframes`
0% {
  background-color: theme.palette.primary.main;
}
50%, 100% {
  background-color: #D9D9D9; 
}
`;

const StyledBox = styled(Box)(({ dark, loading }) => ({
  display: 'flex',
  flexDirection: dark ? 'row-reverse' : 'row',

  '& .CDBAvatar': {
    width: theme.spacing(3),
    height: theme.spacing(3),
    padding: dark ? theme.spacing(0.1) : theme.spacing(0.25),
    // border: `1px solid ${theme.palette.grey['100']}`,
    boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.5)',
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 600,
    backgroundColor: dark
      ? theme.palette.common.white
      : theme.palette.common.white,
    '&.CDBAvatarText': {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(0.4),
    },
    '& img': {
      objectFit: 'contain',
      borderRadius: '50%',
    },
  },

  '& .CDBContent': {
    borderRadius: dark ? theme.spacing(1, 0, 1, 1) : theme.spacing(0, 1, 1),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    minHeight: theme.spacing(4),
    color: dark ? theme.palette.common.white : theme.palette.common.black,
    backgroundColor: dark
      ? theme.palette.secondary.main
      : theme.palette.common.white,
    boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.25)',
    minWidth: loading ? theme.spacing(6.5) : theme.spacing(31.25),
    maxWidth: theme.spacing(100),
    wordBreak: 'break-word',
    '& .CDText': {
      fontWeight: dark ? 500 : 400,
    },
    '& a': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(12),
      textTransform: 'uppercase',
    },
    '& .CDLoading': {
      position: 'relative',
      width: theme.spacing(0.5),
      height: theme.spacing(0.5),
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      animation: `${dotFlashing} 1s infinite linear alternate`,
      animationDelay: '0.5s',
      margin: theme.spacing(1),
      transform: 'translateY(-50%)',
      '&:before, &:after': {
        position: 'absolute',
        content: '""',
        display: 'inline-block',
        top: 0,
      },
      '&:before': {
        left: theme.spacing(-1),
        width: theme.spacing(0.5),
        height: theme.spacing(0.5),
        borderRadius: 5,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        animation: `${dotFlashing} 1s infinite  alternate`,
        animationDelay: '0s',
      },
      '&:after': {
        left: theme.spacing(1),
        width: theme.spacing(0.5),
        height: theme.spacing(0.5),
        borderRadius: 5,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        animation: `${dotFlashing} 1s infinite  alternate`,
        animationDelay: '1s',
      },
    },
  },
}));

export default StyledBox;
