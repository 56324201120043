import React from 'react';
import { Tabs, Tab } from '@mui/material';
import StyledBox from 'views/common/components/UI/TabsBar/TabsBar.styles';

const TabsBar = (props) => {
  const {
    tabs,
    className,
    orientation = 'horizontal',
    selectedTab,
    handleTabClick,
  } = props;

  return (
    <StyledBox>
      <Tabs
        orientation={orientation}
        className={`TabBar ${className}`}
        value={selectedTab}
        variant="scrollable"
        scrollButtons={false}
        onChange={handleTabClick}>
        {tabs.map((tab) => (
          <Tab key={tab.value} {...tab} />
        ))}
      </Tabs>
    </StyledBox>
  );
};

export { TabsBar };
