import { DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(0),
  '&.MuiDialogContent-root': {
    overflow: 'initial',
  },
  '& .MOSOUppercase': {
    textTransform: 'uppercase',
  },
  '& .MOSOSecMain': {
    color: theme.palette.secondary.main,
  },
  '& .MOSOSecLight': {
    color: theme.palette.secondary.light,
  },
  '& .MOSOSecDark': {
    color: theme.palette.secondary.dark,
  },
  '& .MOSTAside': {
    backgroundColor: theme.palette.grey.A000,
    [theme.breakpoints.down('lg')]: {
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
  },
  '& .MOSTSecPadd': {
    padding: theme.spacing(3),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2),
      paddingBottom: '0',
    },
  },
}));
export default StyledDialogContent;
