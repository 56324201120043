import React from 'react';
import {
  List,
  ListItem,
  Menu,
  MenuItem,
  Button,
  IconButton,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';

const PREFIX = 'ExpandableDescription';

const classes = {
  listItem: `${PREFIX}-listItem`,
  icons: `${PREFIX}-icons`,
  outerList: `${PREFIX}-outerList`,
  skeleton: `${PREFIX}-skeleton`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.listItem}`]: {
    minWidth: 'unset',
    display: 'inline',
    padding: theme.spacing(0, 1, 0, 0),
  },
  [`& .${classes.icons}`]: {
    color: theme.palette.secondary.light,
    borderColor: theme.palette.grey[100],
    borderRadius: '5px',
    fontSize: theme.typography.pxToRem(24),
    minWidth: 'unset',
    padding: theme.spacing(0.5),
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    border: '1px solid',
  },
  [`& .${classes.outerList}`]: {
    padding: 0,
    width: 'fit-content',
    display: 'inline-flex',
  },
  [`& .${classes.skeleton}`]: {
    display: 'inline-block',
    margin: theme.spacing(0, 1),
    borderRadius: '5px',
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    '& .MuiMenuItem-root': {
      color: theme.palette.secondary.light,
      padding: theme.spacing(1),
      justifyContent: 'center',
      '& .MuiSvgIcon-root': {
        margin: theme.spacing(0.5),
      },
    },
  },
}));

const ListItemLink = (props) => <ListItem button component="a" {...props} />;
const IconsList = (props) => {
  const { children, menuBtn } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Root>
      <Paper elevation={0} sx={{ display: { xs: 'none', lg: 'block' } }}>
        {children ? (
          <>
            <List
              component="nav"
              aria-label="Social media link"
              className={classes.outerList}>
              {children.map((child) => (
                <ListItem className={classes.listItem} key={child.props.href}>
                  <IconButton
                    variant="outlined"
                    href={child.props.href}
                    className={classes.icons}
                    size="large">
                    {child}
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </>
        ) : (
          <>
            <Skeleton
              variant="circular"
              width={30}
              height={30}
              className={classes.skeleton}
            />
            <Skeleton
              variant="circular"
              width={30}
              height={30}
              className={classes.skeleton}
            />
            <Skeleton
              variant="circular"
              width={30}
              height={30}
              className={classes.skeleton}
            />
            <Skeleton
              variant="circular"
              width={30}
              height={30}
              className={classes.skeleton}
            />
          </>
        )}
      </Paper>
      <Paper elevation={0} sx={{ display: { xs: 'block', lg: 'none' } }}>
        {children ? (
          <>
            <Button
              variant="outlined"
              className={classes.icons}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}>
              {menuBtn}
            </Button>
            <StyledMenu
              className={classes.menu}
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              {children.map((child) => (
                <MenuItem key={child.props.href}>
                  <ListItemLink href={child.props.href}>
                    {child}
                    {child.props.name}
                  </ListItemLink>
                </MenuItem>
              ))}
            </StyledMenu>
          </>
        ) : (
          <>
            <Skeleton
              variant="circular"
              width={30}
              height={30}
              className={classes.skeleton}
            />
          </>
        )}
      </Paper>
    </Root>
  );
};
export { IconsList };
