import MuiDivider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

const Divider = styled(MuiDivider)(({ theme }) => ({
  '&.MuiDivider-root': {
    color: theme.palette.primary.main,
    height: theme.typography.pxToRem(2),
    margin: theme.spacing(4, 0),
    backgroundColor: '#efefef',
    border: 'none',
  },
}));

export { Divider };
