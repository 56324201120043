import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(({ theme }) => ({
  '&.TBtn': {
    width: theme.spacing(11.25),
    height: theme.spacing(7.5),
    margin: theme.spacing(0, 1),
    '& .TImgContainer': {
      height: '100%',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .TImg': {
      width: '100%',
      objectFit: 'cover',
    },
  },
}));

export default StyledButton;
