import React from 'react';
import { Grid, Skeleton, Box } from '@mui/material';

const MapWithGroupsSkeleton = () => (
  <Grid container spacing={2}>
    <Grid item lg={6} md={12} xs={12}>
      <Skeleton variant="text" height={50} width="60%" />
      <Skeleton variant="text" height={50} />
      {[...Array(3).keys()].map(() => (
        <Box mb={2}>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Skeleton variant="text" height={40} />
              <Skeleton variant="text" height={40} />
              <Skeleton variant="text" height={40} />
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="flex-end">
              <Skeleton variant="text" height={40} width={60} />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            {[...Array(4).keys()].map(() => (
              <Grid item xs={3}>
                <Skeleton variant="text" height={40} />
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </Grid>
    <Grid item lg={6} md={12} xs={12} minHeight="300px">
      <Skeleton variant="rectangular" height="100%" width="100%" />
    </Grid>
  </Grid>
);
export { MapWithGroupsSkeleton };
