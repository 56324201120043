import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .SESText': {
    marginBottom: theme.spacing(1.25),
    color: theme.palette.secondary.main,
    '& strong': {
      fontWeight: 500,
    },
  },
  '& .SESCollapseBtn': {
    fontWeight: 700,
    textDecoration: 'underline',
    padding: theme.spacing(0, 0),
    lineHeight: theme.spacing(3),
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
}));

export default StyledBox;
