import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .CDIcon': {
    width: theme.spacing(7.5),
    minWidth: theme.spacing(7.5),
    height: theme.spacing(7.5),
    borderRadius: theme.spacing(7.5),
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.A000,
    boxShadow: '-1px 1px 0px 0px #FE7B10',
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(2),
    },
    '& .MuiSvgIcon-root': {
      width: 'auto',
      height: 'auto',
      maxHeight: theme.spacing(7),
    },
  },
  '& .CDAlert': {
    margin: theme.spacing(0, -2, -2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0.5, 2),
    },
    '& .MuiAlert-icon': {
      display: 'none',
    },
  },
  '& .CDBtn': {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

export default StyledBox;
