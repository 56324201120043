import React from 'react';
import {
  Skeleton, Container, Grid, Box,
} from '@mui/material';

const EagleSharePromoSkeleton = () => (
  <Box my={3}>
    <Container maxWidth="xl">
      <Grid container>
        <Grid item lg={5} md={12} xs={12}>
          <Skeleton
            variant="text"
            width="250px"
            height={40}
            sx={{ marginBottom: '8px' }}
          />
          <Skeleton
            variant="text"
            width="200px"
            height={40}
            sx={{ display: { lg: 'block', xs: 'none' } }}
          />
        </Grid>
        <Grid item lg={7} md={12} xs={12}>
          <Grid container columnSpacing={2.5}>
            <Grid item lg={4} md={4} xs={6}>
              <Skeleton variant="rectangular" width="100%" height={90} />
              <Skeleton variant="text" width="50%" height={20} />
              <Skeleton variant="text" width="60%" height={20} />
            </Grid>
            <Grid item lg={4} md={4} xs={6}>
              <Skeleton variant="rectangular" width="100%" height={90} />
              <Skeleton variant="text" width="50%" height={20} />
              <Skeleton variant="text" width="60%" height={20} />
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              xs={6}
              sx={{ display: { lg: 'block', md: 'block', xs: 'none' } }}>
              <Skeleton variant="rectangular" width="100%" height={90} />
              <Skeleton variant="text" width="50%" height={20} />
              <Skeleton variant="text" width="60%" height={20} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  </Box>
);
export { EagleSharePromoSkeleton };
