import React from 'react';
import StyledBox, {
  StyledImgBox,
  StyledHorizontalStepperSmall,
} from 'views/common/components/Logical/MembershipOverlaySteps/MembershipOverlayHeader/MembershipOverlayHeader.styles';
import { useTranslation } from 'next-i18next';
import {
  IconButton, DialogTitle, Box, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Membership from 'public/imgs/membership_header_large.png';

import { MEMBERSHIP_OVERLAY_STEPS } from 'views/common/components/Logical/MembershipOverlaySteps/utils';

const MembershipOverlayHeader = (props) => {
  const { overlayState, handleMembershipOverlayClose } = props;
  const { t } = useTranslation('fe_er_common_membership_overlay');

  const getTitle = () => {
    let title;
    switch (overlayState.currentStep.identifier) {
      case MEMBERSHIP_OVERLAY_STEPS.selectPlan:
        title = t('fe_er_common_membership_overlay:select_plan');
        break;
      case MEMBERSHIP_OVERLAY_STEPS.authentication:
        title = t('fe_er_common_membership_overlay:create_acount');
        break;
      case MEMBERSHIP_OVERLAY_STEPS.payment:
        title = t('fe_er_common_membership_overlay:payment');
        break;
      case MEMBERSHIP_OVERLAY_STEPS.returnToReservation:
        title = t('fe_er_common_membership_overlay:return_to_reservation');
        break;
      default:
        title = '';
    }
    return `${overlayState.currentStep.number}- ${title}`;
  };

  return (
    <StyledBox>
      <DialogTitle className="MODialogTitle" id="responsive-dialog-title">
        <Box>
          <StyledImgBox
            classes="MOImgBox"
            imgObjectFit="cover"
            imgHeight="9rem"
            imgWidth="100%"
            image={{
              src: Membership,
              alt: t('fe_er_common_membership_overlay:membership'),
            }}
          />
        </Box>
        <Typography component="h3" className="MODialogText" variant="h1">
          {t('fe_er_common_membership_overlay:join_club_er')}
        </Typography>
        <IconButton
          onClick={handleMembershipOverlayClose}
          className="MOCloseIconButton">
          <CloseIcon className="MOCloseIcon" />
        </IconButton>
      </DialogTitle>
      <Box className="MOProgressBg">
        <Typography component="h4" variant="h4" className="MOStepTitle">
          {getTitle()}
        </Typography>

        <StyledHorizontalStepperSmall
          currentStep={overlayState.currentStep.number}
          steps={[...Array(overlayState.numberOfSteps).keys()].map((i) => ({
            icon: i + 1,
          }))}
          stepStyle="MOHorizontalStepper"
          activeStepNo={overlayState.currentStep.number}
        />
      </Box>
    </StyledBox>
  );
};

export { MembershipOverlayHeader };
