import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';

const loading = keyframes`
  0% { box-shadow: 0 1.5rem 0 0.7rem;}
  40% { box-shadow: 0 1.5rem 0 0;}
  80% { box-shadow: 0 1.5rem 0 0.7rem;}
  100% { box-shadow: 0 1.5rem 0 0.7rem;}
`;

const StyledBox = styled(Box)(({ theme }) => ({
  '&.observerContainer': {
    overflow: 'hidden',
  },
  '& .sectionLoading': {
    color: '#4c4f50',
    fontSize: theme.typography.pxToRem(10),
    margin: `${theme.spacing(10)} auto`,
    position: 'relative',
    textIndent: '-9999em',
    transform: 'translateZ(0)',
    animationDelay: '-0.16s',
  },
  '& .sectionLoading, & .sectionLoading:before, & .sectionLoading:after': {
    borderRadius: '50%',
    width: theme.spacing(0.5),
    height: theme.spacing(0.5),
    padding: theme.spacing(0.75),
    animationFillMode: 'both',
    animation: `${loading} 1.8s infinite ease-in-out`,
  },
  '& .sectionLoading:before, & .sectionLoading:after': {
    content: '""',
    position: 'absolute',
    top: '0',
  },
  '& .sectionLoading:before': {
    left: theme.spacing(-5.5),
    animationDelay: '-0.32s',
  },
  '& .sectionLoading:after': {
    left: theme.spacing(5.5),
  },
}));

const LazyLoadWithDataCounts = (props) => {
  const moreRef = useRef(null);
  const {
    dataCount, dataLength, defaultDataCount, setDataCount,
  } = props;

  const scrollCallBack = (entries) => {
    if (entries[0].intersectionRatio > 0 && dataLength > dataCount) {
      // the timeout if for showing the spinner so the user dose not feel something werid happning

      setTimeout(() => {
        setDataCount(dataCount + defaultDataCount);
      }, 700);
    }
  };
  useEffect(() => {
    const scroll = new IntersectionObserver(scrollCallBack, {
      rootMargin: '0px',
      threshold: 0.8,
    });
    moreRef.current && scroll.observe(moreRef.current);
    return () => {
      moreRef.current && scroll.unobserve(moreRef.current);
    };
  }, [dataCount, dataLength]);
  if (dataCount < dataLength) {
    return (
      <StyledBox ref={moreRef} className="observerContainer">
        <div className="sectionLoading">loading...</div>
      </StyledBox>
    );
  }
  return null;
};
export { LazyLoadWithDataCounts };
