import React from 'react';
import StyledBox from 'views/common/components/UI/TourPackagesWidget/TourPackageSelectorItem/TourPackageSelectorItem.styles';
import { ButtonBase, Box, Typography } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import KingBedIcon from '@mui/icons-material/KingBed';
import { useTranslation } from 'next-i18next';
import IconBike from 'views/common/components/UI/Icons/IconBike';
import Typography500 from 'views/common/components/UI/Typography500/Typography500';

const TourPackageSelectorItem = (props) => {
  const {
    index,
    id,
    active,
    pricePerPerson,
    adultsNumber,
    numberOfMoto,
    numberOfBed,
    onClick,
  } = props;

  const { t } = useTranslation('fe_er_common_tour_packages_widget');

  return (
    <StyledBox>
      <ButtonBase
        component="label"
        className={`TPSICard ${active && 'TPSIActive'}`}
        onClick={() => onClick(id)}
        id={id}>
        <Box className="TPSIPackageTitleContainers">
          <Box className="TPSIPackageTitle">
            <Box className={`TPSIIcon ${active && 'active'}`}>
              {active ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
            </Box>
            <Typography500 variant="h6" component="span">
              {t('fe_er_common_tour_packages_widget:package', {
                number: index + 1,
              })}
            </Typography500>
            <Typography
              variant="caption"
              component="span"
              className="TPSIPackagPerson">
              {t('fe_er_common_tour_packages_widget:per_person', {
                price: pricePerPerson,
              })}
            </Typography>
          </Box>
        </Box>
        <Box
          className={`TPSIOptions
         ${active && 'active'}`}>
          <Box
            className={`TPSIOptionItems ${
              (!numberOfMoto || numberOfMoto == 0) && 'TPSIOptionsNoMoto'
            }`}>
            <Box>
              <Typography component="span" className="TPSIOptionItem">
                {adultsNumber > 1 ? (
                  <>
                    <PeopleIcon className="TPSIOptionIcon" />
                    {t('fe_er_common_tour_packages_widget:adults.many', {
                      number: adultsNumber,
                    })}
                  </>
                ) : (
                  <>
                    <PersonIcon className="TPSIOptionIcon TPSIPersonIcon" />
                    {t('fe_er_common_tour_packages_widget:adults.one', {
                      number: adultsNumber,
                    })}
                  </>
                )}
              </Typography>
            </Box>
            {numberOfMoto > 0 && (
              <Box>
                <Typography component="span" className="TPSIOptionItem">
                  <IconBike className="TPSIOptionIcon" />
                  {t('fe_er_common_tour_packages_widget:motorcycles', {
                    motor_number: numberOfMoto,
                  })}
                </Typography>
              </Box>
            )}
            {numberOfBed > 0 && (
              <Box>
                <Typography component="span" className="TPSIOptionItem">
                  <KingBedIcon className="TPSIOptionIcon" />
                  {t('fe_er_common_tour_packages_widget:room', {
                    bed_number: numberOfBed,
                  })}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </ButtonBase>
    </StyledBox>
  );
};

export { TourPackageSelectorItem };
