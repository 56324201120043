import React from 'react';
import { Typography } from '@mui/material';
import StyledBox from 'views/common/components/UI/RibbonChip/RibbonChip.styles';

const RibbonChip = (props) => {
  const { label } = props;

  return (
    <StyledBox>
      <Typography variant="p">{label}</Typography>
    </StyledBox>
  );
};

export { RibbonChip };
