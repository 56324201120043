import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const OrientationVideo = (props) => (
  <SvgIcon
    {...props}
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 59.4">
    <defs>
      <mask
        id="mask"
        x="37.19"
        y="32.17"
        width="14.11"
        height="13.33"
        maskUnits="userSpaceOnUse">
        <path
          fillRule="evenodd"
          d="m44.79,39.35h-1.09v-3.08h1.09v3.08Zm0,2.05h-1.09v-1.03h1.09v1.03Zm-.55-7.7c-3.02,0-5.46,2.3-5.46,5.14s2.45,5.14,5.46,5.14,5.46-2.3,5.46-5.14-2.45-5.14-5.46-5.14Z"
        />
      </mask>
    </defs>
    <rect fill="none" width="60" height="59.4" />
    <g>
      <path
        fill="#3a3a3a"
        fillRule="evenodd"
        d="m10.01,15.16h37.27v16.48h-1.08v-15.4H11.09v25.93h25.39v1.08H10.01V15.16Z"
      />
      <path fill="#3a3a3a" d="m36.18,38.98h-18.01v1.08h18.01v-1.08Z" />
      <rect fill="#3a3a3a" x="14.61" y="37.58" width="1.08" height="3.24" />
      <rect fill="#3a3a3a" x="16.22" y="37.58" width="1.08" height="3.24" />
      <path fill="#3a3a3a" d="m25.95,21.92l8.64,5.02-8.64,5.25v-10.26Z" />
      <path
        fill="#d95700"
        fillRule="evenodd"
        d="m44.79,39.35h-1.09v-3.08h1.09v3.08Zm0,2.05h-1.09v-1.03h1.09v1.03Zm-.55-7.7c-7.09-.09-7.09,10.37,0,10.27,7.09.09,7.09-10.37,0-10.27Z"
      />
      <g mask="url(#mask)">
        <rect
          stroke="#d88282"
          strokeMiterlimit={10}
          fill="none"
          x="37.69"
          y="32.67"
          width="13.11"
          height="12.33"
        />
      </g>
    </g>
  </SvgIcon>
);

export default OrientationVideo;
