import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import useAppNavigator from 'views/common/hooks/useAppNavigator';
import { localeDateFormatMap, localeDateMaskMap } from 'lib/config/date-picker';
import { mapNamedLocaleToLocale } from 'lib/data/localesMap';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs as DateAdapter } from '@mui/x-date-pickers/AdapterDayjs';
import deLocale from 'dayjs/locale/de';
import arLocale from 'dayjs/locale/ar';
import ruLocale from 'dayjs/locale/ru';
import koLocale from 'dayjs/locale/ko';
import svLocale from 'dayjs/locale/sv';
import frLocale from 'dayjs/locale/fr';
import esLocale from 'dayjs/locale/es';
import itLocale from 'dayjs/locale/it';
import ptLocale from 'dayjs/locale/pt';
import nlLocale from 'dayjs/locale/nl';
import nbLocale from 'dayjs/locale/nb';
import daLocale from 'dayjs/locale/da';
import jaLocale from 'dayjs/locale/ja';
import zhLocale from 'dayjs/locale/zh';

import enAuLocale from 'dayjs/locale/en-au';
import enCaLocale from 'dayjs/locale/en-ca';
import enGbLocale from 'dayjs/locale/en-gb';

const mapNamedLocaleToDayJsLocale = {
  'motorrad-mieten': deLocale,
  'location-moto': frLocale,
  'alquiler-de-motos': esLocale,
  'noleggio-moto': itLocale,
  'aluguel-de-motos': ptLocale,
  motorverhuur: nlLocale,
  motorsykkelleie: nbLocale,
  motorcykeludlejninger: daLocale,
  ru: ruLocale,
  ar: arLocale,
  ko: koLocale,
  sv: svLocale,
  ja: jaLocale,
  'en-AU': enAuLocale,
  'en-CA': enCaLocale,
  'en-GB': enGbLocale,
  zh: zhLocale,
};

const LocalizedDatePicker = (props) => {
  const appNavigator = useAppNavigator();
  useEffect(() => {
    dayjs.locale(mapNamedLocaleToLocale[appNavigator.locale].toLowerCase());
    return () => {
      dayjs.locale(mapNamedLocaleToLocale.en.toLowerCase());
    };
  }, []);

  return (
    <LocalizationProvider
      dateAdapter={DateAdapter}
      adapterLocale={mapNamedLocaleToDayJsLocale[appNavigator.locale]}>
      <DatePicker
        format={localeDateFormatMap[appNavigator.locale]}
        mask={localeDateMaskMap[appNavigator.locale]}
        {...props}
      />
    </LocalizationProvider>
  );
};

export { LocalizedDatePicker };
