import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  margin: 0,
  fontSize: theme.typography.pxToRem(12),
  lineHeight: 'normal',
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.common.white,
  padding: theme.spacing(0.25, 0, 0),
  position: 'absolute',
  top: 0,
  right: 0,
  transform: 'translateX(30%) translateY(0%) rotate(45deg)',
  transformOrigin: 'top left',
  display: 'block',
  zIndex: 1,
  '&:before, :after': {
    backgroundColor: theme.palette.primary.dark,
    content: '""',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    marginRight: theme.typography.pxToRem(-1),
    marginLeft: theme.typography.pxToRem(-1),
  },
  '&:before': {
    right: '100%',
  },
  '&:after': {
    left: '100%',
  },
}));

export default StyledBox;
