import React, { useState } from 'react';
import StyledBox from 'views/common/components/UI/SEOExpandedSection/SEOExpandedSection.styles';
import { useTranslation } from 'next-i18next';
import Typography500 from 'views/common/components/UI/Typography500';
import { Button, Typography, Collapse } from '@mui/material';

const SEOExpandedSection = (props) => {
  const { title, desc, descItems } = props;
  const { t } = useTranslation('fe_er_common_seo_expanded_section');
  const [isExpanded, setIsExpanded] = useState(false);
  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <StyledBox>
      <Typography500 variant="h3" component="h3" className="SESText">
        {title}
      </Typography500>
      <Typography variant="body2" component="p" className="SESText">
        {desc}
      </Typography>
      <Collapse in={isExpanded}>
        {descItems.map((item) => (
          <Typography
            component="p"
            variant="body2"
            key={item}
            className="SESText"
            dangerouslySetInnerHTML={{
              __html: item,
            }}
          />
        ))}
      </Collapse>
      <Button
        disableRipple
        variant="text"
        color="secondary"
        className="SESCollapseBtn"
        onClick={handleExpand}>
        {isExpanded
          ? t('fe_er_common_seo_expanded_section:show_less')
          : t('fe_er_common_seo_expanded_section:show_more')}
      </Button>
    </StyledBox>
  );
};

export { SEOExpandedSection };
