import React from 'react';
import StyledDialogContent from 'views/common/components/Logical/MembershipOverlaySteps/MembershipOverlayStepFour/MembershipOverlayStepFour.styles';
import { useTranslation } from 'next-i18next';
import { Button, DialogContentText, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const MembershipOverlayStepFour = (props) => {
  const { handleOverlayStepChange, labels } = props;
  const { t } = useTranslation('fe_er_common_membership_overlay');

  return (
    <StyledDialogContent className="MODialogContent">
      <CheckCircleIcon className="MOCheckIcon" />
      <DialogContentText className="MODialogContentText">
        <Typography variant="h3" component="h6">
          {labels.stepFourMessage
            || t('fe_er_common_membership_overlay:thank_you_for_joining_er_club')}
        </Typography>
      </DialogContentText>
      <Button
        className="MODialogAction"
        variant="contained"
        color="primary"
        onClick={handleOverlayStepChange}>
        {labels.stepFourBtn}
      </Button>
    </StyledDialogContent>
  );
};

export { MembershipOverlayStepFour };
