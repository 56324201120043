import React from 'react';
import { Skeleton, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.grey[200]}`,
}));

const ReferralCodeSkeleton = () => (
  <StyledBox>
    <Skeleton variant="rectangular" width="100%" height={40} />
  </StyledBox>
);

export { ReferralCodeSkeleton };
