import React from 'react';

const { envVars } = require('next/config').default().publicRuntimeConfig;

const { GTM_CODE } = envVars;

const GTMNoScript = () => (
  <>
    <noscript>
      <iframe
        title="GTM"
        src={`https://www.googletagmanager.com/ns.html?id=${GTM_CODE}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
  </>
);

export { GTMNoScript };
