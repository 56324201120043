import React from 'react';
import {
  Box, Typography, IconButton, Container,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CloseIcon from '@mui/icons-material/Close';

import { useTranslation } from 'next-i18next';

const PREFIX = 'CommisionBanner';

const classes = {
  commisionRoot: `${PREFIX}-commisionRoot`,
  contentInner: `${PREFIX}-contentInner`,
  title: `${PREFIX}-title`,
  titleInner: `${PREFIX}-titleInner`,
  titleCaps: `${PREFIX}-titleCaps`,
  closeBtn: `${PREFIX}-closeBtn`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.commisionRoot}`]: {
    backgroundColor: theme.palette.info.main,
    padding: theme.spacing(1.25, 0),
  },
  [`& .${classes.contentInner}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    color: theme.palette.common.white,
    position: 'relative',
    paddingRight: theme.typography.pxToRem(60),

    '& > svg': {
      marginTop: theme.spacing(0.75),

      [theme.breakpoints.up('lg')]: {
        width: theme.spacing(4),
        height: 'auto',
      },
    },
  },
  [`& .${classes.title}`]: {
    fontWeight: '500',
    margin: theme.spacing(0, 1.25),
    fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  [`& .${classes.titleInner}`]: {
    marginRight: theme.spacing(0.75),
    fontWeight: '500',
    fontSize: theme.typography.pxToRem(20),
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.pxToRem(28),
    },
  },
  [`& .${classes.titleCaps}`]: {
    textTransform: 'uppercase',
    fontWeight: '500',
  },
  [`& .${classes.closeBtn}`]: {
    position: 'absolute',
    top: '0',
    right: '-12px',
    color: theme.palette.common.white,
  },
}));

const CommissionBanner = ({ onClose }) => {
  const { t } = useTranslation('fe_er_commissionBanner');

  return (
    <Root>
      <Box className={classes.commisionRoot}>
        <Container>
          <Box className={classes.contentInner}>
            <NotificationsActiveIcon />

            <Typography variant="h3" component="div" className={classes.title}>
              {t('fe_er_commissionBanner:title1')}
              <Typography component="span" className={classes.titleCaps}>
                {t('fe_er_commissionBanner:title2')}
              </Typography>
            </Typography>

            <IconButton
              classes={{ root: classes.closeBtn }}
              aria-label="delete"
              onClick={() => {
                onClose(false);
              }}
              size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        </Container>
      </Box>
    </Root>
  );
};

export { CommissionBanner };
