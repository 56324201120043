import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .TDAPWTooltip': {
    fontSize: theme.typography.pxToRem(13),
  },
  '& .TDAPWBringOwnContainer': {
    position: 'relative',
  },
  '& .TDAPWAlertIcon': {
    color: theme.palette.info.main,
    '& svg': {
      fontSize: theme.typography.pxToRem(22),
    },
  },
  '& .TDABWNumberOfTours': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    color: theme.palette.common.black,
  },
  '& .TDAPWNotCharged': {
    display: 'block',
    textAlign: 'center',
    color: theme.palette.grey[900],
  },
  '&.TDAPWStrechedWided': {
    '& .TDABWNumberOfTours': {
      justifyContent: 'flex-start',
      gap: theme.spacing(3.75),
    },
    '& .TDAPWNotCharged': {
      textAlign: 'start',
    },
  },
  '& .TDAPWTourPackageTitle': {
    color: theme.palette.grey[700],
  },
}));

export default StyledBox;
