import React from 'react';
import StyledBox from 'views/common/components/UI/ExperienceRating/ExperienceRating.styles';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Rating from '@mui/material/Rating';
import { Typography700 } from 'views/common/components';

const ExperienceRating = (props) => {
  const { value } = props;

  return (
    <StyledBox>
      {value && (
        <Typography700 className="ERNum" variant="h2" component="p">
          {value}
        </Typography700>
      )}

      <Rating
        readOnly
        value={value}
        size="small"
        precision={0.5}
        emptyIcon={<StarBorderIcon fontSize="inherit" />}
        className="ERRatingStars"
      />
    </StyledBox>
  );
};

export { ExperienceRating };
