import React from 'react';
import { Container, Box, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const InfoPageTitleSkeleton = () => (
  <Container maxWidth="xl">
    <Box my={3}>
      <Skeleton variant="text" width="290px" height={30} />
      <Grid container spacing={2}>
        <Grid item lg={8} md={12} xs={12}>
          <Skeleton variant="text" width="250px" height={40} />
        </Grid>
        <Grid
          item
          lg={4}
          xs={12}
          display="flex"
          sx={{ display: { lg: 'flex', md: 'none', xs: 'none' } }}
          justifyContent="flex-end">
          <Skeleton variant="text" width="70%" height={40} />
        </Grid>
      </Grid>
    </Box>
  </Container>
);
export { InfoPageTitleSkeleton };
