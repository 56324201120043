import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const DriverLicense = (props) => (
  <SvgIcon
    {...props}
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 59.4">
    <defs>
      <mask
        id="mask"
        x="37.39"
        y="32.35"
        width="14.11"
        height="13.33"
        maskUnits="userSpaceOnUse">
        <path
          fillRule="evenodd"
          d="m44.99,39.52h-1.09v-3.08h1.09v3.08Zm0,2.05h-1.09v-1.03h1.09v1.03Zm-.55-7.7c-3.02,0-5.46,2.3-5.46,5.14s2.45,5.14,5.46,5.14,5.46-2.3,5.46-5.14-2.45-5.14-5.46-5.14Z"
        />
      </mask>
    </defs>
    <g>
      <polygon
        fill="#3a3a3a"
        fillRule="evenodd"
        points="11.64 16.42 47.08 16.42 47.08 32.08 46.05 32.08 46.05 17.45 12.67 17.45 12.67 42.1 36.81 42.1 36.81 43.12 11.64 43.12 11.64 16.42"
      />
      <path
        fill="#3a3a3a"
        d="m19.35,29.69c-1.64.03-3.91.75-3.42,2.57h6.85c.48-1.82-1.78-2.54-3.42-2.57Zm0-.86c2.22.03,2.22-3.46,0-3.42-2.22-.03-2.22,3.46,0,3.42Z"
      />
      <path fill="#3a3a3a" d="m42.46,26.69h-16.26v1.08h16.26v-1.08Z" />
      <path fill="#3a3a3a" d="m42.46,22.41h-16.26v1.08h16.26v-1.08Z" />
      <path fill="#3a3a3a" d="m42.46,30.97h-16.26v1.08h16.26v-1.08Z" />
      <path ill="#3a3a3a" d="m33.66,34.28h-7.46v.99h7.46v-.99Z" />
      <path
        fill="#d95700"
        fillRule="evenodd"
        d="m44.99,39.52h-1.09v-3.08h1.09v3.08Zm0,2.05h-1.09v-1.03h1.09v1.03Zm-.55-7.7c-7.09-.09-7.09,10.37,0,10.27,7.09.09,7.09-10.37,0-10.27Z"
      />
      <g mask="url(#mask)">
        <rect
          stroke="#d88282"
          strokeMiterlimit={10}
          fill="none"
          x="37.89"
          y="32.85"
          width="13.11"
          height="12.33"
        />
      </g>
      <rect
        strokeMiterlimit={10}
        stroke="#3a3a3a"
        fill="none"
        x="14.32"
        y="22.45"
        width="10.2"
        height="12.51"
      />
    </g>
    <rect fill="none" width="60" height="59.4" />
  </SvgIcon>
);

export default DriverLicense;
