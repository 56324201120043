import React from 'react';
import { Grid, Container } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const DropdownFilterListSkeleton = () => (
  <Container maxWidth="xl">
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={8}>
        <Grid container spacing={2}>
          <Grid item xs={4} md={4} lg={2}>
            <Skeleton variant="text" width="100%" height={70} />
          </Grid>
          <Grid item xs={4} md={4} lg={2}>
            <Skeleton variant="text" width="100%" height={70} />
          </Grid>
          <Grid item xs={4} md={4} lg={2}>
            <Skeleton variant="text" width="100%" height={70} />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={4}
        align="right"
        sx={{ textAlign: { lg: 'right', md: 'start', sm: 'start' } }}>
        <Skeleton variant="text" width="100%" height={70} />
      </Grid>
    </Grid>
  </Container>
);
export { DropdownFilterListSkeleton };
