import React from 'react';
import {
  Typography, Grid, Box, Link,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.floatinglocationContent': {
    '& .icon': {
      marginRight: theme.spacing(1.1),
    },

    '& .contentfont': {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.spacing(2.5),
    },

    '& .ContentText': {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(1.5),
      },
      '&.MuiGrid-root': {
        flexWrap: 'nowrap',
      },
    },

    '& .phone': {
      fontWeight: 500,
    },

    '& svg': {
      fontSize: theme.spacing(1.7),
      verticalAlign: 'text-bottom',
    },
  },
}));

const FloatingLocationInfoContent = (props) => {
  const { address, operationHours, phones } = props;
  return (
    <StyledRoot className="floatinglocationContent">
      {address && (
        <Grid container className="ContentText">
          <Grid item className="icon">
            <LocationOnIcon />
          </Grid>
          <Grid item>
            <Typography varient="h6" component="p" className="contentfont">
              {address}
            </Typography>
          </Grid>
        </Grid>
      )}
      {phones && (
        <Grid container className="ContentText">
          <Grid item className="icon">
            <CallIcon />
          </Grid>
          <Grid item>
            {phones
              && phones.map((phone) => (
                <>
                  <Box>
                    <Link href={`tel:${phone}`} className="phone">
                      {phone}
                    </Link>
                  </Box>
                </>
              ))}
          </Grid>
        </Grid>
      )}
      {operationHours && (
        <Grid container className="ContentText">
          <Grid item className="icon">
            <AccessTimeIcon />
          </Grid>
          <Grid item>
            {operationHours
              && operationHours.map((operationHour) => (
                <Typography varient="h6" component="p" className="contentfont">
                  {operationHour}
                </Typography>
              ))}
          </Grid>
        </Grid>
      )}
    </StyledRoot>
  );
};
export { FloatingLocationInfoContent };
