import getConfig from 'next/config';

const {
  publicRuntimeConfig: { envVars },
} = getConfig();
const getGoogleAnalyticsTrackingId = (locale) => {
  const GA_TRACKING_ID =
    locale === 'zh'
      ? envVars.CHINESE_GOOGLE_ANALYTICS_CODE
      : envVars.GOOGLE_ANALYTICS_CODE;
  return GA_TRACKING_ID;
};
export default getGoogleAnalyticsTrackingId;
