import { Box, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  '& .RPRatingProgress': {
    position: 'relative',
    display: 'inline-flex',
    '& .RPOutline': {
      borderRadius: '50%',
      overflow: 'hidden',
      boxShadow: `0px 0px 0px 3px ${alpha(theme.palette.info.main, 0.5)}`,
      width: 'calc(100% - 8px )',
      height: 'calc(100% - 8px )',
      margin: theme.spacing(0.5),
      position: 'absolute',
    },
    '& .MuiCircularProgress-root': {
      width: `${theme.spacing(8.75)} !important`,
      height: `${theme.spacing(8.75)} !important`,
      color: theme.palette.info.dark,
      [theme.breakpoints.down('md')]: {
        width: `${theme.spacing(6.25)} !important`,
        height: `${theme.spacing(6.25)} !important`,
      },
    },

    '& .RPLabel': {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  '& .RProgressLabel': {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.secondary.main,
    paddingTop: theme.spacing(1),
    maxWidth: theme.spacing(12.5),
  },
}));
export default StyledBox;
