import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '&.DWdialog': {
    '.MuiDialog-paper': {
      borderRadius: 4,
      [theme.breakpoints.up('lg')]: {
        borderRadius: 15,
      },
    },

    '.MuiDialogContent-root': {
      padding: 0,
    },

    '& .DWcloseBtn': {
      float: 'right',
      minWidth: theme.typography.pxToRem(40),
      textAlign: 'right',
      [theme.breakpoints.up('lg')]: {
        minWidth: theme.typography.pxToRem(50),
      },
    },

    '& .DWcontentContainer': {
      padding: theme.spacing(2, 3),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3, 4.5),
      },
    },

    '& .DWimage': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
}));

export default StyledDialog;
