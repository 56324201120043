import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& .ERNum': {
    paddingRight: theme.spacing(0.75),
  },
  '& .ERRatingStars': {
    color: theme.palette.warning.main,
  },
}));

export default StyledBox;
