import {
  Box,
  IconButton,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import StyledBox, {
  StyledChatBotWrapper,
} from 'views/common/components/Logical/ChatBotFBDialog/ChatBotFBDialog.styles';
import AIChatIcon from 'views/common/components/UI/Icons/AIChatIcon';
import EagleRiderClub from 'views/common/components/UI/Icons/EagleRiderClub';
import CloseIcon from '@mui/icons-material/Close';
import useCookies from 'views/common/hooks/useCookies';
import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import { useSelector } from 'react-redux';
import Typography500 from 'views/common/components/UI/Typography500/Typography500';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import PATHS from 'lib/navigation/paths';
import { ChatBotMobilDialog } from 'views/common/components';
import {
  CHAT_ALERT_CLOSED,
  CHAT_OPENED,
} from 'views/common/components/Logical/ChatBotFBDialog/utils/CONSTANTS';

const ChatBotFBDialog = () => {
  const [openChat, setOpenChat] = useState();
  const [cookies, setCookie] = useCookies();
  const [openAlert, setOpenAlert] = useState(true);
  const [floatingWidgetPage, setFloatingWidgetPage] = useState(false);
  const { t } = useTranslation('fe_er_chatbot_page');
  const appState = useSelector((state) => state.globalData.appState);
  const appNavigator = useAppNavigator();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const handleOpenDialog = () => {
    if (!appState.current_user) {
      setOpenAlert(false);
      setTimeout(() => {
        setOpenAlert(true);
      }, 0);
      return;
    }

    if (!cookies[CHAT_ALERT_CLOSED]) {
      setOpenAlert(false);
    }

    setOpenChat((prev) => !prev);
  };
  const expiryTime = new Date();
  expiryTime.setHours(expiryTime.getHours() + 48);
  const handleAlertClose = () => {
    setCookie(CHAT_ALERT_CLOSED, true, {
      expires: expiryTime,
    });
    setOpenAlert(false);
  };
  useEffect(() => {
    const observerOptions = {
      childList: true,
      subtree: true,
    };
    const observer = new MutationObserver(() => {
      if (document.getElementById('floatingButton')) {
        setFloatingWidgetPage(true);
      } else {
        setFloatingWidgetPage(false);
      }
    });
    observer.observe(document.body, observerOptions);
  }, []);
  useEffect(() => {
    setOpenAlert(!cookies[CHAT_ALERT_CLOSED]);
  }, []);

  useEffect(() => {
    if (openChat && sessionStorage.getItem([CHAT_OPENED]) !== 'true') {
      sessionStorage.setItem(CHAT_OPENED, true);
      window.gtag('event', 'opened_chat');
    }
  }, [openChat]);

  return (
    <StyledBox
      className="CVFDContainer"
      openChat={openChat}
      widgetPage={floatingWidgetPage}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
      {isMobile ? (
        <ChatBotMobilDialog open={openChat} onClose={handleOpenDialog} />
      ) : (
        <Slide className="CVFDSlide" direction="up" in={openChat}>
          <Box mb={2} height={{ xs: '100%', md: 'unset' }}>
            <StyledChatBotWrapper />
          </Box>
        </Slide>
      )}
      {!!openAlert && (
        <Box className="CVFDAlertContainer">
          {appState.current_user && (
            <Box className="CVFDAlertIconContainer">
              <EagleRiderClub />
            </Box>
          )}
          <Box textAlign="end">
            <IconButton onClick={handleAlertClose}>
              <CloseIcon color="secondary" />
            </IconButton>
          </Box>
          <Box>
            {appState.current_user ? (
              <Typography variant="body1">
                {t('fe_er_chatbot_page:welcome_alert')}
              </Typography>
            ) : (
              <Box>
                <Typography500>
                  {t('fe_er_chatbot_page:planning_adventure')}
                </Typography500>
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: t('fe_er_chatbot_page:explore', {
                      sign_in_path: appNavigator.appendQuery(
                        appNavigator.getUrl(
                          undefined,
                          PATHS.users.signIn().url,
                          true,
                        ),
                        { return_url: appNavigator.asPath },
                      ),
                      sign_up_path: appNavigator.appendQuery(
                        appNavigator.getUrl(
                          undefined,
                          PATHS.users.signUp().url,
                          true,
                        ),
                        { return_url: appNavigator.asPath },
                      ),
                    }),
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
      <Box className="CBFDIconBtnContainer" textAlign="end">
        <IconButton
          disableFocusRipple
          disableRipple
          className={`CBFDIconBtn ${
            openChat ? 'CBFDOpenIconBtn' : 'CBFDCloseIconBtn'
          }`}
          onClick={handleOpenDialog}>
          {openChat ? (
            <CloseIcon />
          ) : (
            <>
              <AIChatIcon className="CBDChatIcon" />
              <Typography500 variant="h5" pl={0.5}>
                {t('fe_er_chatbot_page:chat')}
              </Typography500>
            </>
          )}
        </IconButton>
      </Box>
    </StyledBox>
  );
};

export { ChatBotFBDialog };
