import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '&.CEcontainer': {
    '.MuiDialog-paper': {
      borderRadius: 4,
      [theme.breakpoints.up('lg')]: {
        borderRadius: 15,
        maxWidth: theme.typography.pxToRem(819),
      },
    },
    '.MuiDialogContent-root': {
      padding: 0,
      [theme.breakpoints.up('lg')]: {
        overflowY: 'hidden',
      },
    },
    '& .CEBoldTitle': {
      fontWeight: 500,
    },
    '& .CEcloseBtn': {
      float: 'right',
      minWidth: theme.typography.pxToRem(40),
      [theme.breakpoints.up('lg')]: {
        minWidth: theme.typography.pxToRem(50),
      },
    },
    '& .CEcontentContainer': {
      padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(
        24,
      )}`,
      [theme.breakpoints.up('md')]: {
        padding: `${theme.typography.pxToRem(42)} ${theme.typography.pxToRem(
          36,
        )}`,
      },
    },
    '& .CEcontent': {
      padding: '0',
    },
    '& .CEemailInput': {
      '.MuiInputBase-input': {
        padding: theme.typography.pxToRem(12),
      },
      '.MuiOutlinedInput-root': {
        borderRadius: '0',
      },
    },
    '& .grecaptcha-badge': {
      visibility: 'hidden',
    },
    '& .CEimage': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
}));

export default StyledDialog;
