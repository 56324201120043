import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const AIChatIcon = (props) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none">
    <path
      d="M15.7692 7.2189C15.5809 7.2189 15.4204 7.15258 15.2877 7.01994C15.1551 6.8873 15.0888 6.7268 15.0888 6.53844C15.0888 6.35008 15.1551 6.18958 15.2877 6.05694C15.4204 5.9243 15.5809 5.85798 15.7692 5.85798C15.9576 5.85798 16.1181 5.9243 16.2507 6.05694C16.3834 6.18958 16.4497 6.35008 16.4497 6.53844C16.4497 6.7268 16.3834 6.8873 16.2507 7.01994C16.1181 7.15258 15.9576 7.2189 15.7692 7.2189ZM12.6923 7.2189C12.5039 7.2189 12.3434 7.15258 12.2108 7.01994C12.0782 6.8873 12.0118 6.7268 12.0118 6.53844C12.0118 6.35008 12.0782 6.18958 12.2108 6.05694C12.3434 5.9243 12.5039 5.85798 12.6923 5.85798C12.8807 5.85798 13.0412 5.9243 13.1738 6.05694C13.3064 6.18958 13.3728 6.35008 13.3728 6.53844C13.3728 6.7268 13.3064 6.8873 13.1738 7.01994C13.0412 7.15258 12.8807 7.2189 12.6923 7.2189ZM9.61537 7.2189C9.42701 7.2189 9.26651 7.15258 9.13387 7.01994C9.00123 6.8873 8.93491 6.7268 8.93491 6.53844C8.93491 6.35008 9.00123 6.18958 9.13387 6.05694C9.26651 5.9243 9.42701 5.85798 9.61537 5.85798C9.80373 5.85798 9.96423 5.9243 10.0969 6.05694C10.2295 6.18958 10.2958 6.35008 10.2958 6.53844C10.2958 6.7268 10.2295 6.8873 10.0969 7.01994C9.96423 7.15258 9.80373 7.2189 9.61537 7.2189ZM20 15.0295V2.15977C20 1.7712 19.8654 1.4423 19.5961 1.17307C19.3269 0.903841 18.998 0.769226 18.6094 0.769226H6.77516C6.3866 0.769226 6.0577 0.903841 5.78847 1.17307C5.51924 1.4423 5.38462 1.7712 5.38462 2.15977V10.9171C5.38462 11.3057 5.51924 11.6346 5.78847 11.9038C6.0577 12.173 6.3866 12.3076 6.77516 12.3076H17.2781L20 15.0295Z"
      fill="#FE7B10"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 13.8461V14.3787C15 14.7672 14.8653 15.0961 14.5961 15.3654C14.3269 15.6346 13.998 15.7692 13.6094 15.7692H3.1065L0.384613 18.4911V5.62131C0.384613 5.23275 0.519228 4.90385 0.788459 4.63462C1.05769 4.36539 1.38659 4.23077 1.77515 4.23077H3.84614V5.3846H1.77515C1.71597 5.3846 1.66172 5.40926 1.6124 5.45856C1.56309 5.50788 1.53844 5.56213 1.53844 5.62131V15.6805L2.61538 14.6154H13.6094C13.6686 14.6154 13.7229 14.5907 13.7722 14.5414C13.8215 14.4921 13.8461 14.4378 13.8461 14.3787V13.8461H15ZM5.3846 4.23077H13.6094C13.998 4.23077 14.3269 4.36539 14.5961 4.63462C14.8653 4.90385 15 5.23275 15 5.62131V12.3077H13.8461V5.62131C13.8461 5.56213 13.8215 5.50788 13.7722 5.45856C13.7229 5.40926 13.6686 5.3846 13.6094 5.3846H5.3846V4.23077Z"
      fill="#FE7B10"
    />
  </SvgIcon>
);

export default AIChatIcon;
