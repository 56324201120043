import React from 'react';
import { Box, Skeleton } from '@mui/material';

const RewardsItemsListSkeleton = () => (
  <Box>
    <Skeleton variant="rectangular" width={250} height={20} sx={{ mb: 3 }} />
    {[...Array(8).keys()].map((index) => (
      <Box mb={2} key={index}>
        <Skeleton variant="text" width="80%" height={20} />
      </Box>
    ))}
  </Box>
);

export { RewardsItemsListSkeleton };
