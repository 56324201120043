import React from 'react';
import {
  Avatar, Box, Typography, Button, Stack,
} from '@mui/material';
import StyledBox from 'views/common/components/UI/Chatbot/ChatDialogBox/ChatDialogBox.styles';
import Typography500 from 'views/common/components/UI/Typography500';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import { useTranslation } from 'next-i18next';

const ChatDialogBox = (props) => {
  const { t } = useTranslation('fe_er_chatbot_page');

  const {
    chatDialogTitle,
    chatDialogText,
    chatDialogLink,
    chatDialogAction,
    loading,
    dark,
    avatar,
    avatarText,
  } = props;
  return (
    <StyledBox mb={2} dark={dark} loading={loading}>
      {avatar ? (
        <Avatar
          className="CDBAvatar"
          alt={t('fe_er_chatbot_page:eagle_talk')}
          src={avatar}
        />
      ) : (
        <Avatar className="CDBAvatar CDBAvatarText">{avatarText}</Avatar>
      )}
      <Box className="CDBContent" mx={0.5}>
        {chatDialogTitle && (
          <Typography500 variant="h6" pb={0.5}>
            {chatDialogTitle}
          </Typography500>
        )}
        {chatDialogText && (
          <Typography
            variant="body2"
            className="CDText"
            dangerouslySetInnerHTML={{
              __html: chatDialogText,
            }}
          />
        )}
        {chatDialogLink && (
          <NavigatorLink
            className="CDBLink"
            underline="always"
            href="#"
            color="primary">
            {chatDialogLink}
          </NavigatorLink>
        )}

        {chatDialogAction && (
          <Stack spacing={1} direction="row" mt={1}>
            <Button size="small" variant="contained">
              {chatDialogAction}
            </Button>
            <Button size="small" variant="outlined">
              {chatDialogAction}
            </Button>
          </Stack>
        )}
        {loading && <Box className="CDLoading" />}
      </Box>
    </StyledBox>
  );
};

export { ChatDialogBox };
