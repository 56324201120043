import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .TabBar': {
    '& .MuiTab-root': {
      fontWeight: 400,
      letterSpacing: theme.typography.pxToRem(0.12),
      color: theme.palette.grey[300],
      borderBottom: `1px solid ${theme.palette.grey[200]}`,

      '&.Mui-selected': {
        color: theme.palette.primary.main,
        fontWeight: '600',
      },
    },
    '& .MuiTabs-indicator': {
      height: theme.typography.pxToRem(1),
    },
  },
}));

export default StyledBox;
