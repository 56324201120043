import React from 'react';
import StyledBox from 'views/common/components/UI/RewardsPromotionBanner/RewardsPromotionBanner.styles';
import { useTranslation } from 'next-i18next';
import { ImgBox, Typography700 } from 'views/common/components';
import { Button, Box } from '@mui/material';
import PATHS from 'lib/navigation/paths';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';

const RewardsPromotionBanner = () => {
  const { t } = useTranslation();

  return (
    <StyledBox>
      <Typography700 variant="subtitle1" component="h6" className="RPBText">
        {t('fe_er_common_rewards_promotion_banner:signUp_to_your_account')}
      </Typography700>
      <ImgBox
        className="RSHeaderTitleLogos"
        image={{
          src: '/imgs/rewards/RewardProgramLogo.svg',
          alt: t('fe_er_common_rewards_promotion_banner:eaglerider_rewards'),
        }}
        imgWidth="13.5rem"
        imgHeight="1.125rem"
        imgObjectFit="contain"
      />
      <Box display="flex" alignItems="center" className="RPBActions">
        <Box mr={2}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            component={NavigatorLink}
            href={PATHS.users.signUp()}>
            {t('fe_er_common_rewards_promotion_banner:Sign_Up')}
          </Button>
        </Box>

        <Button
          variant="outlined"
          color="primary"
          mr={2}
          size="small"
          component={NavigatorLink}
          href={PATHS.rewards()}>
          {t('fe_er_common_rewards_promotion_banner:view_benefits')}
        </Button>
      </Box>
    </StyledBox>
  );
};

export { RewardsPromotionBanner };
