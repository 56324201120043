import React from 'react';
import {
  Box, Button, Typography, Grid,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import CloseIcon from '@mui/icons-material/Close';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import StyledBox from 'views/common/components/UI/MotorcycleRentalCardHorizontalVariant/BikeDetails/BikeDetails.styles';

const BikeDetails = (props) => {
  const {
    close,
    bikeInfo,
    name,
    bikePageurl,
    handleReserveNowBtn,
    modelAttributes = [],
    isShowDetails,
  } = props;
  const { t } = useTranslation('fe_er_common_rental_card_horizontal_variant');

  return (
    <StyledBox
      className="bd-bikeDetailsContainer"
      isShowDetails={isShowDetails}>
      <Box className="bd-headerBox showTABLET">
        <Typography variant="h4" component="p">
          {name}
        </Typography>
        <CloseIcon onClick={close} />
      </Box>
      <Box px={2} className="BDDeatlisContiner">
        <Grid container columnSpacing={3} className="BDGridContiner">
          {modelAttributes.length > 0
            && modelAttributes.map((attribute) => (
              <Grid
                item
                lg={6}
                md={12}
                xs={12}
                key={attribute.value}
                className="BDHColumn">
                <Grid container className="BDHTextContiner">
                  <Grid item xs={8}>
                    <Typography
                      component="span"
                      variant="body2"
                      className="BDHName">
                      {attribute.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} className="BDHValue">
                    <Typography component="span" variant="body2">
                      {attribute.value}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
        <Box textAlign="end" mt={3.25} mb={1}>
          <NavigatorLink href={bikePageurl} className="BDBikeUrl">
            {name}
          </NavigatorLink>
        </Box>
      </Box>
      <Box className="BDReserveBtnBox showTABLET">
        <Button
          fullWidth
          color="primary"
          className="bd-reserveBtn"
          onClick={() => {
            handleReserveNowBtn(bikeInfo);
          }}
          variant="contained">
          {t('fe_er_common_rental_card_horizontal_variant:reserve_now')}
        </Button>
      </Box>
    </StyledBox>
  );
};

export { BikeDetails };
