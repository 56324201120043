import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledBox = styled(Box)(({ theme, isShowDetails }) => ({
  '&.bd-bikeDetailsContainer': {
    height: '100%',
    width: '100%',
    borderRadius: '6px',
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      flexDirection: 'column',
    },
    [theme.breakpoints.up('lg')]: {
      borderRadius: 0,
      maxHeight: theme.spacing(24.25),
      overflowY: 'auto',
    },
  },
  '& .bd-headerBox': {
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
    },
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.dark,
    justifyContent: 'space-between',
    color: theme.palette.common.white,
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
    boxSizing: 'border-box',
    '& svg': {
      cursor: 'pointer',
    },
  },
  '& .BDDeatlisContiner': {
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('lg')]: {
      backgroundColor: theme.palette.secondary.main,
      maxHeight: theme.spacing(38.6),
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: theme.spacing(1),
      },
      '&::-webkit-scrollbar-track ': {
        background: theme.palette.grey[400],
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.primary.contrastText,
        borderRadius: theme.spacing(0.5),
      },
    },
    // try to fix ios scroll issue
    [theme.breakpoints.down('md')]: {
      overflowY: 'scroll',
      overflowX: 'hidden',
      height: theme.spacing(35),
    },
  },
  '& .BDGridContiner': {
    [theme.breakpoints.down('md')]: {
      display: !isShowDetails && 'none', // To fix flipping issue at
    },
  },
  '& .BDReserveBtnBox': {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
    bottom: 0,
    width: '100%',
    boxSizing: 'border-box',
    marginTop: 'auto',
  },
  '& .BDHColumn ': {
    paddingRight: theme.spacing(1),
  },
  '& .BDHTextContiner ': {
    borderBottom: ` 1px solid ${theme.palette.grey[50]}`,
    padding: theme.spacing(2, 0, 0.25, 0),
    height: '100%',
    [theme.breakpoints.down('lg')]: {
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
    },
  },
  '& .BDHName': {
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('lg')]: {
      color: theme.palette.grey[50],
    },
  },
  '& .BDHValue': {
    textAlign: 'end',
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('lg')]: {
      color: theme.palette.grey[50],
    },
  },
  '& .BDBikeUrl': {
    fontWeight: 500,
    textTransform: 'uppercase',
  },
}));
export default StyledBox;
