import React from 'react';
import { Grid, Box, Skeleton } from '@mui/material';

const CheckInDetailsSkeleton = () => (
  <>
    <Grid container rowSpacing={4}>
      {[...Array(3).keys()].map((item) => (
        <Grid item lg={4} md={6} sm={12} key={item}>
          <Box display="flex" alignItems="center">
            <Box mr={3}>
              <Skeleton variant="circular" width={60} height={60} />
            </Box>
            <Skeleton variant="text" width="50%" height={30} />
          </Box>
        </Grid>
      ))}
    </Grid>
    <Box mt={4}>
      <Skeleton
        variant="rectangular"
        sx={{ md: 140, sm: '100%' }}
        height={36}
      />
    </Box>
    <Box m={-2} mt={4}>
      <Skeleton variant="rectangular" width="100%" height={36} />
    </Box>
  </>
);

export { CheckInDetailsSkeleton };
