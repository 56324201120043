export const formatUrlToTag = (message, query = null) => {
  if (message) {
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s|\]|)]+)/g;

    return message.replace(urlRegex, (url) => {
      let hyperlink = url;
      if (!hyperlink.match('^https?://')) {
        hyperlink = `https://${hyperlink}`;
      }
      if (hyperlink.at(-1) === '.') {
        hyperlink = hyperlink.slice(0, -1);
      }
      if (query) {
        if (hyperlink.includes('?')) {
          hyperlink = `${hyperlink}&${query}`;
        } else {
          hyperlink = `${hyperlink}?${query}`;
        }
      }

      return `<a href=${hyperlink} target="_blank" rel="noopener noreferrer">${hyperlink}</a>`;
    });
  }
  return null;
};
