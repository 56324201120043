import React, { useState } from 'react';
import {
  Box, useTheme, Typography, Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Address } from 'views/common/components';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';

const Map = dynamic(() => import('views/common/components/UI/Map').then((mod) => mod.Map));

const StyledBox = styled(Box)(({ theme }) => ({
  '&.circleMap': {
    border: `${theme.spacing(0.125)} solid rgba(0, 0, 0, 0.1)`,
    borderRadius: theme.spacing(0.5),
    '& .circleMapTitle': {
      marginBottom: theme.spacing(1.25),
      padding: theme.spacing(0, 2),
    },
    '& .circleMapDec': {
      color: theme.palette.grey[300],
      fontSize: theme.spacing(1.5),
      lineHeight: theme.spacing(2),
      marginTop: theme.spacing(1.25),
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(2),
    },
    '& .bookingLocation': {
      padding: theme.spacing(0, 2),
    },
    '& .mapContiner': {
      height: theme.spacing(55),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        height: theme.spacing(46.5),
      },
      [theme.breakpoints.down('md')]: {
        height: theme.spacing(42.5),
      },
    },
  },
}));
const CircleMap = (props) => {
  const {
    defaultZoom,
    defaultCenter,
    mapCenter,
    location,
    addressPath,
    titlePath,
    description,
    renderMapMarkers,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation('fe_er_common_circal_map');
  const [isShown, setIsShown] = useState(false);
  const handleClick = () => {
    setIsShown((current) => !current);
  };
  return (
    <StyledBox className="circleMap">
      {location && (
        <Address
          location={location}
          addressPath={addressPath}
          titlePath={titlePath}
        />
      )}
      <Box className="mapContiner">
        {isShown ? (
          <Map
            defaultZoom={defaultZoom}
            center={mapCenter}
            defaultCenter={defaultCenter}
            onGoogleApiLoaded={({ map, maps }) => {
              // eslint-disable-next-line no-unused-vars
              const locationCircle = new maps.Circle({
                strokeColor: theme.palette.primary.dark,
                strokeOpacity: 1,
                strokeWeight: 1,
                fillColor: theme.palette.primary.main,
                fillOpacity: 0.1,
                map,
                center: mapCenter,
                radius: 5000,
              });
            }}
            renderMapMarkers={renderMapMarkers}
          />
        ) : (
          <Button onClick={handleClick} variant="outlined">
            {t('fe_er_common_circal_map:show_map')}
          </Button>
        )}
      </Box>
      {description && (
        <Typography
          component="p"
          className="circleMapDec"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
    </StyledBox>
  );
};
export { CircleMap };
