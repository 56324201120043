import React, { useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import StyledBox from 'views/common/components/UI/DamageWaiverTable/DamageWaiverTable.styles';
import { useTranslation } from 'next-i18next';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import PATHS from 'lib/navigation/paths';

const DamageWaiverTable = (props) => {
  const {
    className,
    vipZeroPlusTheftDamageWaiver,
    vipPlusTheftDamageWaiver,
    noDamageWaiverSelected,
  } = props;
  const appNavigator = useAppNavigator();
  const { t } = useTranslation('fe_er_common_damagewaiver_table');
  const createData = (desc, coverage, vip, vipZero) => ({
    desc,
    coverage,
    vip,
    vipZero,
  });
  const damageTableData = useMemo(() => {
    const rows = [
      createData(
        `${t(
          'fe_er_common_damagewaiver_table:damage_waiver',
        )} <a class="DWTLink" target='_blank' href=${appNavigator.getUrl(
          undefined,
          PATHS.rentals.rentalTerms().url,
          true,
        )}>${t('fe_er_common_damagewaiver_table:temrs_and_conditions')}</a>`,
        t('fe_er_common_damagewaiver_table:full_value_damages'),
        t('fe_er_common_damagewaiver_table:deductible_2000'),
        t('fe_er_common_damagewaiver_table:deductible_0'),
      ),
      createData(
        t('fe_er_common_damagewaiver_table:theft_coverage'),
        t('fe_er_common_damagewaiver_table:full_value_motorcycle'),
        t('fe_er_common_damagewaiver_table:deductible_5000'),
        t('fe_er_common_damagewaiver_table:deductible_1000'),
      ),
      createData(
        t(
          'fe_er_common_damagewaiver_table:vacation_interruption_protection_vip',
        ),
        t('fe_er_common_damagewaiver_table:refund_of_daily_rate_or_portion'),
        t('fe_er_common_damagewaiver_table:refund_of_daily_rate_and_35_dollar'),
        t(
          'fe_er_common_damagewaiver_table:refund_of_daily_rate_and_100_dollar',
        ),
      ),
      createData(
        t('fe_er_common_damagewaiver_table:personal_property_loss_protection'),
        t('fe_er_common_damagewaiver_table:not_included'),
        t('fe_er_common_damagewaiver_table:not_included'),
        t('fe_er_common_damagewaiver_table:renter_coverage'),
      ),
      createData(
        t('fe_er_common_damagewaiver_table:additional_driver'),
        t('fe_er_common_damagewaiver_table:not_included'),
        t('fe_er_common_damagewaiver_table:not_included'),
        t('fe_er_common_damagewaiver_table:additional_renter_permitted'),
      ),
      createData(
        t('fe_er_common_damagewaiver_table:flat_tire_coverage'),
        t('fe_er_common_damagewaiver_table:not_included'),
        t('fe_er_common_damagewaiver_table:not_included'),
        t('fe_er_common_damagewaiver_table:covers_tire_repair_100'),
      ),
      createData(
        t('fe_er_common_damagewaiver_table:security_deposit_strong'),
        t('fe_er_common_damagewaiver_table:deposit_5000'),
        t('fe_er_common_damagewaiver_table:deposit_750'),
        t('fe_er_common_damagewaiver_table:deposit_100'),
      ),
    ];
    if (
      vipZeroPlusTheftDamageWaiver
      || vipPlusTheftDamageWaiver
      || noDamageWaiverSelected
    ) {
      rows.push(
        createData(
          t('fe_er_common_damagewaiver_table:accept_theft_damage_waiver'),
          noDamageWaiverSelected
            ? t('fe_er_common_damagewaiver_table:accepted')
            : '',

          vipPlusTheftDamageWaiver
            ? t('fe_er_common_damagewaiver_table:accepted')
            : '',

          vipZeroPlusTheftDamageWaiver
            ? t('fe_er_common_damagewaiver_table:accepted')
            : '',
        ),
      );

      rows.push(
        createData(
          t('fe_er_common_damagewaiver_table:decline_theft_damage_waiver'),
          noDamageWaiverSelected
            ? ''
            : t('fe_er_common_damagewaiver_table:declined'),
          vipPlusTheftDamageWaiver
            ? ''
            : t('fe_er_common_damagewaiver_table:declined'),

          vipZeroPlusTheftDamageWaiver
            ? ''
            : t('fe_er_common_damagewaiver_table:declined'),
        ),
      );
    }

    return rows;
  }, [
    vipZeroPlusTheftDamageWaiver,
    vipPlusTheftDamageWaiver,
    noDamageWaiverSelected,
  ]);
  return (
    <StyledBox className={className}>
      <TableContainer>
        <Table className="RPTable">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                <Typography
                  variant="body2"
                  component="p"
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'fe_er_common_damagewaiver_table:no_coverage_purchase',
                    ),
                  }}
                />
              </TableCell>
              <TableCell>
                <Typography
                  variant="body2"
                  component="p"
                  dangerouslySetInnerHTML={{
                    __html: t('fe_er_common_damagewaiver_table:vip_vacation'),
                  }}
                />
              </TableCell>
              <TableCell>
                <Typography
                  variant="body2"
                  component="p"
                  dangerouslySetInnerHTML={{
                    __html: t('fe_er_common_damagewaiver_table:vip_0_vacation'),
                  }}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {damageTableData.map((row) => (
              <TableRow key={row.desc}>
                <TableCell className="RPTRowTh" component="th" scope="row">
                  <Typography
                    variant="body2"
                    component="p"
                    dangerouslySetInnerHTML={{ __html: row.desc }}
                  />
                </TableCell>

                <TableCell>
                  <Typography
                    variant="body2"
                    component="p"
                    dangerouslySetInnerHTML={{ __html: row.coverage }}
                  />
                </TableCell>

                <TableCell>
                  <Typography
                    variant="body2"
                    component="p"
                    dangerouslySetInnerHTML={{ __html: row.vip }}
                  />
                </TableCell>

                <TableCell>
                  <Typography
                    variant="body2"
                    component="p"
                    dangerouslySetInnerHTML={{ __html: row.vipZero }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledBox>
  );
};

export { DamageWaiverTable };
