import React from 'react';
import {
  Grid, Typography, InputBase, Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography500 from 'views/common/components/UI/Typography500/Typography500';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'next-i18next';
import { LocationList } from 'views/common/components/UI/MapGroups/LocationList';
import { Map } from 'views/common/components';

const StyledGrid = styled(Grid)(({ theme }) => ({
  '&.mapGroups': {
    backgroundColor: 'transparent',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column-reverse',
    },
    '& .MGmap': {
      height: theme.spacing(76.5),
      [theme.breakpoints.down('lg')]: {
        height: theme.spacing(32.75),
      },
      [theme.breakpoints.down('md')]: {
        height: theme.spacing(21.24),
      },
    },
    '& .LocationsCardListSide': {
      position: 'relative',
      [theme.breakpoints.down('lg')]: {
        marginTop: theme.spacing(2),
      },
      '& .MGTitleSection': {
        [theme.breakpoints.up('md')]: {
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'baseline',
          paddingTop: theme.spacing(2),
        },
      },
    },
    '& .LocationsCardListSide:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: 50,
      background:
        'linear-gradient(rgba(255, 255, 255, 0.001), rgba(255, 255, 255, 0.9))',
    },
    '& .mainTitle': {
      color: theme.palette.secondary.main,
      paddingRight: theme.spacing(1.25),
    },
    '& .locationsNumber': {
      marginLeft: theme.spacing(1.25),
      [theme.breakpoints.down('md')]: {
        display: 'block',
        marginLeft: theme.spacing(0),
      },
    },
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.grey.A000,
  margin: theme.spacing(1, 1, 1, 0),
  width: '100%',
  borderRadius: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    '& .searchIconWrapper': {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.primary.main,
    },
  },
  '& .inputBase': {
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1rem + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20rem',
      },
    },
  },
}));

const MapGroups = (props) => {
  const { t } = useTranslation('fe_er_common_map_groups');
  const {
    mapGroupsTitle,
    locationCount,
    locationsList,
    defaultZoom,
    mapCenter,
    mapLocations,
    mapIcon,
    mapIconAlt,
    handleTextFieldSearch,
    handleEmailButtonClick,
    handleMapLogoClick,
    prepareMapUrl,
    handleMapOnChange,
    handleBookNowButtonCLick,
    renderMapMarkers,
    handleLocationCardButtonClick,
    handleLocationCardHover,
    target,
    selectedLocationId,
    mapGroupsTitleComponent = 'h3',
  } = props;
  return (
    <StyledGrid container columnSpacing={{ xs: 5 }} className="mapGroups">
      <Grid item lg={6} md={12} className="LocationsCardListSide">
        <Box className="MGTitleSection">
          <Typography500
            variant="h3"
            component={mapGroupsTitleComponent}
            className="mainTitle">
            {mapGroupsTitle}
          </Typography500>
          <Typography
            variant="subtitle2"
            component="span"
            className="locationsNumber">
            <Typography component="span" className="Weight300" mr={1}>
              {locationCount}
            </Typography>
            <Typography component="span" className="Weight300">
              {t('fe_er_common_map_groups:locations')}
            </Typography>
          </Typography>
        </Box>
        {handleTextFieldSearch && (
          <Search>
            <div className="searchIconWrapper">
              <SearchIcon />
            </div>
            <InputBase
              className="inputBase"
              placeholder={t('fe_er_common_map_groups:search')}
              onChange={handleTextFieldSearch}
            />
          </Search>
        )}
        <LocationList
          locationsList={locationsList}
          handleEmailButtonClick={handleEmailButtonClick}
          prepareMapUrl={prepareMapUrl}
          handleBookNowButtonCLick={handleBookNowButtonCLick}
          handleLocationCardButtonClick={handleLocationCardButtonClick}
          handleLocationCardHover={handleLocationCardHover}
          selectedLocationId={selectedLocationId}
          target={target}
        />
      </Grid>
      <Grid item lg={6} md={12}>
        <Box className="MGmap">
          <Map
            mapCenter={mapCenter}
            mapLocations={mapLocations}
            mapIcon={mapIcon}
            mapIconAlt={mapIconAlt}
            handleMarkerClick={handleMapLogoClick}
            mapZoom={defaultZoom}
            handleMapOnChange={handleMapOnChange}
            renderMapMarkers={renderMapMarkers}
          />
        </Box>
      </Grid>
    </StyledGrid>
  );
};

export { MapGroups };
