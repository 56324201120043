import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  '& .MOADesc': {
    color: theme.palette.grey[300],
  },
  '& .MOACheckicon': {
    '& svg': {
      verticalAlign: 'text-top',
    },
  },
  '& .MOAHiddenLg': {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  '& .MOAOrange': {
    color: theme.palette.primary.dark,
  },
  '& .MOAbenefitAccordin.MuiPaper-root.MuiAccordion-root': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    transition: 'none',
    borderRadius: '0',
    margin: '0',
    '& .MuiAccordionSummary-root': {
      padding: '0',
    },
    '& .MuiAccordionDetails-root': {
      paddingLeft: '0',
      paddingRight: '0',
    },
  },

  '& .MOADivider.MuiDivider-root': {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  '& .MOARewardsLogo': {
    display: 'flex',
    flexDirection: 'column',
    order: 3,
    [theme.breakpoints.up('lg')]: {
      alignItems: 'flex-end',
      order: 'unset',
    },
  },
  '& .MOARewardsLogoContainer': {
    height: theme.typography.pxToRem(11),
    width: theme.typography.pxToRem(141),
    marginTop: theme.spacing(1),
  },
  '& .MOSAStrikeThrough': {
    textDecoration: 'line-through',
  },
}));
export default StyledBox;
