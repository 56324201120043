import React, { useState, useRef } from 'react';
import {
  Select,
  MenuItem,
  OutlinedInput,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  StyledMenuItem,
  StyledMenuTitle,
  StyledFormControl,
} from 'views/common/components/UI/DropdownFilter/DropdownFilter.styles';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 250,
    },
  },
  MenuListProps: {
    style: {
      paddingTop: 0,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
};

const DropdownFilter = (props) => {
  const { filterList, handleChange, className } = props;
  const [openDropDownList, setOpenDropDownList] = useState(false);
  const menuItemsRef = useRef(null);
  const handleOpen = () => {
    setOpenDropDownList(true);
  };
  const handleClose = () => {
    setOpenDropDownList(false);
  };

  return (
    <StyledFormControl className={`DFContainer ${className}`}>
      <Select
        className="selectInput"
        open={openDropDownList}
        onOpen={() => {
          handleOpen();
        }}
        onClose={(e) => {
          if (!menuItemsRef.current.contains(e.target)) {
            handleClose();
          }
        }}
        displayEmpty
        value={filterList.selectedValue || null}
        placeholder={filterList.title}
        onChange={(e) => {
          if (e.target.value) {
            handleChange(e, filterList.type);
          }
        }}
        disabled={filterList.disabled}
        input={<OutlinedInput />}
        MenuProps={MenuProps}>
        <MenuItem disabled value={null} sx={{ display: 'none' }}>
          {filterList.title}
        </MenuItem>
        <StyledMenuTitle className="menuTitle" ref={menuItemsRef}>
          <Typography component="span" variant="span">
            {filterList.menuTitle}
          </Typography>
          <IconButton
            aria-label="delete"
            size="small"
            className="iconbtn"
            onClick={handleClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </StyledMenuTitle>
        {/* addition menu item to avoid hidden first item at list */}
        <StyledMenuItem className="hiddenMenuItem">
          {filterList.menuTitle}
        </StyledMenuItem>
        {filterList.list.map((listItem) => (
          <StyledMenuItem
            key={listItem.id}
            value={
              filterList.valueKey
                ? listItem[filterList.valueKey]
                : listItem.name
            }>
            <Typography component="p" variant="body2">
              {listItem.name}
            </Typography>
          </StyledMenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};

export { DropdownFilter };
