import React from 'react';
import { styled } from '@mui/material/styles';
import {
  ButtonBase, Typography, Box, Grid,
} from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const PREFIX = 'RadioButtonCardOld';

const classes = {
  card: `${PREFIX}-card`,
  active: `${PREFIX}-active`,
  icon: `${PREFIX}-icon`,
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
  cardBox: `${PREFIX}-cardBox`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledBox = styled(Box)(({ theme, size, radioBtnPostion }) => ({
  [`&.${classes.card}`]: {
    backgroundColor: theme.palette.grey.A000,
    borderRadius: 7,
    padding: size == 'small' ? theme.spacing(1.25) : theme.spacing(2, 1.5),
    border: `1px solid ${theme.palette.grey['200']}`,
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
    alignItems: 'flex-start',
    position: 'relative',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1.5, 1.25),
    },
  },

  [`&.${classes.active}`]: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.A000,
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.05)',
  },

  [`& .${classes.icon}`]: {
    position: 'absolute',
    top: size == 'small' ? theme.spacing(1.5) : theme.spacing(2),
    right: radioBtnPostion == 'left' ? 'auto' : theme.spacing(1),
    left: radioBtnPostion == 'left' ? theme.spacing(1) : 'auto',
    cursor: 'pointer',
    '&.active': {
      color: theme.palette.primary.main,
    },

    [theme.breakpoints.down('md')]: {
      top: theme.spacing(1.5),
    },
  },

  [`& .${classes.title}`]: {
    color: theme.palette.secondary.main,
    fontWeight: '500',
    fontSize:
      size == 'small'
        ? theme.typography.pxToRem(14)
        : theme.typography.pxToRem(16),
  },

  [`& .${classes.description}`]: {
    color: theme.palette.grey['300'],
    marginTop: size == 'small' ? theme.spacing(1) : theme.spacing(2),
  },

  [`& .${classes.cardBox}`]: {
    width: `calc(100% - ${theme.spacing(3.75)})`,
    marginLeft: radioBtnPostion == 'left' ? theme.spacing(3.75) : 0,
    marginRight: radioBtnPostion == 'right' ? theme.spacing(3.75) : 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
}));

const RadioButtonCardOld = (props) => {
  const {
    title,
    description,
    children,
    rightSideChildren,
    active,
    onClick,
    size = 'large',
    radioBtnPostion = 'right',
  } = props;

  return (
    <StyledBox
      size={size}
      radioBtnPostion={radioBtnPostion}
      className={`${classes.card} ${active ? classes.active : ''}`}>
      <Box
        className={`${classes.icon} ${active ? 'active' : ''}`}
        onClick={onClick}>
        {active ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
      </Box>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        wrap="nowrap"
        spacing={2}>
        <Grid item xs>
          <ButtonBase
            component="div"
            onClick={onClick}
            className={classes.cardBox}>
            <Typography variant="h5" className={classes.title}>
              {title}
            </Typography>

            {description && (
              <Typography
                variant="caption"
                className={classes.description}
                component="div">
                {description}
              </Typography>
            )}
          </ButtonBase>
        </Grid>

        {rightSideChildren && radioBtnPostion == 'left' && (
          <Grid item>{rightSideChildren}</Grid>
        )}
      </Grid>

      {children && (
        <Box mt={1} className={classes.cardBox}>
          {children}
        </Box>
      )}
    </StyledBox>
  );
};

export default RadioButtonCardOld;
