import React from 'react';
import { Typography, Box } from '@mui/material';
import StyledBox, {
  StyledImgBox,
} from 'views/CheckInView/AppAdLinks/AppAdLinks.styles';
import { useTranslation } from 'next-i18next';
import Typography500 from 'views/common/components/UI/Typography500';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink/NavigatorLink';
import PATHS from 'lib/navigation/paths';

const AppAdLinks = () => {
  const { t } = useTranslation('fe_er_common_app_ad_links');
  const appleStore = {
    src: '/imgs/appAD/AppStore.png',
    alt: 'Download on the App Store',
  };
  const googlePlay = {
    src: '/imgs/appAD/GooglePlay.png',
    alt: 'Get it on Google Play',
  };

  return (
    <StyledBox textAlign="center">
      <Typography500 component="h4" variant="h4" pb={1}>
        {t('fe_er_common_app_ad_links:check_in_on_go')}
      </Typography500>
      <Typography component="h6" variant="h6" pb={3}>
        {t('fe_er_common_app_ad_links:download_app')}
      </Typography>
      <Box className="AAdownloadBtns">
        <NavigatorLink href={PATHS.app.ios}>
          <StyledImgBox
            imgHeight="2.7rem"
            imgWidth="9.3rem"
            image={appleStore}
            objectFit="contain"
          />
        </NavigatorLink>

        <NavigatorLink pl={1.25} href={PATHS.app.playStore}>
          <StyledImgBox
            imgHeight="2.7rem"
            imgWidth="9.3rem"
            image={googlePlay}
            objectFit="contain"
          />
        </NavigatorLink>
      </Box>
    </StyledBox>
  );
};

export { AppAdLinks };
