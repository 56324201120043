import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const ResStateSkeleton = () => (
  <>
    <Skeleton
      variant="rectangular"
      width={75}
      height={18}
      sx={{ borderRadius: 0.5 }}
    />
  </>
);

export { ResStateSkeleton };
