import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const SectionTitleWithDescSkeleton = () => (
  <>
    <Skeleton variant="text" width="70%" height={40} />
    <Skeleton variant="text" width="30%" height={40} />
  </>
);

export { SectionTitleWithDescSkeleton };
