import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import getConfig from 'next/config';
import {
  Box,
  Grid,
  Button,
  Divider,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import StyledBox, {
  StyledPaymentsOptions,
} from 'views/common/components/Logical/MembershipOverlaySteps/MembershipOverlayCheckoutStep/MembershipOverlayCheckoutStep.styles';
import { useSelector, useDispatch } from 'react-redux';

import axios, { API } from 'lib/config/axios';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';
import { setLoading, setSnackbar } from 'redux/features/ui-slice';
import { setAppData } from 'redux/features/globalData-slice';
import useCookies from 'views/common/hooks/useCookies';

import MoreAboutYou from 'views/common/components/UI/Payment/MoreAboutYou';
import {
  ReferralCode,
  MembershipOverlayAside,
} from 'views/common/components/Logical/MembershipOverlaySteps';
import { prepareMembershipCheckoutBody } from 'views/common/components/Logical/MembershipOverlaySteps/utils';

const {
  publicRuntimeConfig: { envVars },
} = getConfig();

const MembershipOverlayCheckoutStep = (props) => {
  const {
    overlayState,
    membershipOverlayData,
    membershipPaymentData,
    handleOverlayStepChange,
    membershipCheckoutCallback,
    referralCode,
    handleMembershipReferralCodeChange,
  } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const handleErrorResponse = useErrorsHandler();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const checkoutStepRef = useRef();
  const appState = useSelector((state) => state.globalData.appState);
  const [, setCookie] = useCookies();
  const [creditCardsList, setCreditCardsList] = useState(
    membershipPaymentData.credit_cards,
  );
  const [selectedCardId, setSelectedCardId] = useState('');
  const [creditCardErrors, setCreditCardErrors] = useState({});
  const addNewCreditCard = (newCreditCard) => {
    setCreditCardsList([...creditCardsList, newCreditCard]);
  };
  const [creditCardFormData, setCreditCardFormData] = useState({
    billing_address: {
      country: {},
      region: {},
    },
  });
  const [creditCardFormErrors, setCreditCardFormErrors] = useState({});
  const recaptchaRef = useRef();
  const [moreAboutYouData, setMoreAboutYouData] = useState({});
  const [errors, setErrors] = useState();
  const handleMoreAboutYouDataInputChange = (key, value) => {
    setMoreAboutYouData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const resetRecaptcha = () => {
    recaptchaRef.current.reset();
    handleMoreAboutYouDataInputChange('g-recaptcha-response', null);
  };

  const postCheckout = async () => {
    try {
      const checkoutResponse = await axios.post(
        API.memberships.checkoutPost.replace(
          '{id}',
          overlayState.selectedMembership.id,
        ),
        prepareMembershipCheckoutBody(
          appState,
          membershipPaymentData,
          membershipOverlayData,
          overlayState,
          {
            selectedCardId,
            creditCardFormData,
            moreAboutYouData,
          },
          referralCode,
        ),
      );
      dispatch(
        setAppData({
          ...appState,
          current_user: {
            ...appState.current_user,
            is_member: true,
            credits: checkoutResponse.data.credits,
          },
          user_membership: checkoutResponse.data.user_membership,
        }),
      );
    } catch (error) {
      resetRecaptcha();
      switch (error.response.status) {
        case 406:
          switch (error.response.data.error_code) {
            case 'ER0003':
            case 'ER0007':
              dispatch(
                setSnackbar({
                  open: true,
                  severity: 'error',
                  message: t(
                    `fe_er_common_membership_overlay:${error.response.data.error_code}`,
                  ),
                }),
              );
              break;
            case 'ER0001':
              dispatch(
                setSnackbar({
                  open: true,
                  severity: 'error',
                  message: t(
                    'fe_er_common_membership_overlay:fill_out_required_fields',
                  ),
                }),
              );
              setErrors(error.response.data);
              setCreditCardFormErrors({
                ...error.response.data.errors?.credit_card,
                base: error.response.data.errors?.base,
              });
              checkoutStepRef.current?.scrollIntoView({ behavior: 'smooth' });
              break;
            default:
              handleErrorResponse(error);
          }
          break;
        default:
          handleErrorResponse(error);
      }
      throw new Error();
    }
  };
  const handleSubscribeBtnClick = async () => {
    try {
      dispatch(setLoading(true));
      await postCheckout();
      await membershipCheckoutCallback();
      handleOverlayStepChange();
    } catch (error) {
      if (error.message) {
        handleErrorResponse(error);
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleApplyReferralCode = () => {
    dispatch(setLoading(true));
    axios
      .get(API.memberships.referralCode, {
        params: {
          code: referralCode,
          user_id: appState.current_user.id,
          membership_id: overlayState.selectedMembership.id,
        },
      })
      .then(() => {
        dispatch(
          setSnackbar({
            open: true,
            severity: 'success',
            message: t(
              'fe_er_common_membership_overlay:referral_code_activated',
            ),
          }),
        );
        setCookie('er_mr', membershipPaymentData.referral_code, {
          path: '/',
        });
      })
      .catch((error) => {
        handleErrorResponse(error);
      })
      .then(() => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    window.gtag('event', 'membership_signup', {
      event_category: 'Membership',
      event_action: 'Pageview',
      event_label: 'Checkout',
      eventSource: window.location.pathname.includes('/extras')
        ? 'Extras Page'
        : 'Search Rentals Page',
      membershipPlan: overlayState.selectedMembership.name,
    });
  }, []);

  return (
    <StyledBox ref={checkoutStepRef}>
      <Grid container>
        <Grid item lg={5} md={12} className="MOSTAside MOSTSecPadd" p={3}>
          <MembershipOverlayAside
            overlayState={overlayState}
            membershipOverlayData={membershipOverlayData}
          />
        </Grid>

        <Grid item lg={7} md={12}>
          <Box p={3} pb={5}>
            <StyledPaymentsOptions
              className="styledPaymentsOptions"
              paymentOptions={{
                creditCard: {
                  creditCardsList,
                  addNewCreditCard,
                  selectedCardId,
                  setSelectedCardId,
                  creditCardErrors,
                  setCreditCardErrors,
                  showSaveBtn: false,
                  isBillingAddressNeeded: true,
                  creditCardFormInfo: {
                    creditCardFormData: {
                      ...creditCardFormData,
                      countries: membershipPaymentData.allowed_countries,
                    },
                    setCreditCardFormData,
                    creditCardFormErrors,
                    setCreditCardFormErrors,
                  },
                },
                showPaymentOptions: false,
              }}
            />
            <Box mt={3} mb={6} className="MOReferralCode">
              <ReferralCode
                code={referralCode}
                handleApplyReferralCode={handleApplyReferralCode}
                handleMembershipReferralCodeChange={
                  handleMembershipReferralCodeChange
                }
                disabled={membershipOverlayData.membership_referral_code?.code}
              />
            </Box>
            <Divider className="MODivider" />
            <Box mt={3}>
              <MoreAboutYou
                data={moreAboutYouData}
                isGift={false}
                userErrors={errors && { errors: errors.errors.user }}
                handleUserInputChange={handleMoreAboutYouDataInputChange}
              />
            </Box>

            {envVars.RECAPTCHA_SITE_KEY && (
              <Box mt={isMobile ? 3.5 : 3}>
                <ReCAPTCHA
                  sitekey={envVars.RECAPTCHA_SITE_KEY}
                  onChange={(value) => handleMoreAboutYouDataInputChange(
                    'g-recaptcha-response',
                    value,
                  )}
                  ref={recaptchaRef}
                />
              </Box>
            )}
            <Box mt={4} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                disableElevation
                onClick={handleSubscribeBtnClick}
                disabled={
                  !moreAboutYouData.agreedToTerms
                  || !moreAboutYouData['g-recaptcha-response']
                }>
                {t('fe_er_common_membership_overlay:subscribe_now')}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export { MembershipOverlayCheckoutStep };
