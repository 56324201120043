import React from 'react';
import { Grid, Skeleton } from '@mui/material';

const StickyTabsBarSkeleton = () => (
  <Grid container spacing={3} py={2}>
    {[...Array(7)].map(() => (
      <Grid item lg={1} md={2} xs={4}>
        <Skeleton variant="rectangular" height={30} />
      </Grid>
    ))}
  </Grid>
);
export { StickyTabsBarSkeleton };
