/* eslint-disable no-promise-executor-return */
import React, { useState, useEffect, useRef } from 'react';
import Dinero from 'lib/utils/Dinero';
import { useSelector } from 'react-redux';

const ConvertCurrency = (amount, rate) => {
  const ConvertComponent = () => {
    const isMounted = useRef(true);
    const appState = useSelector((state) => state.globalData.appState);

    const [convertedPrice, setConvertedPrice] = useState(null);
    useEffect(() => {
      const rates = {
        rates: {
          [appState.current_currency.code]: rate,
        },
      };

      Dinero({ amount })
        .convert(appState.current_currency.code, {
          endpoint: new Promise((resolve) => resolve(rates)),
        })
        .then((data) => {
          if (isMounted.current) {
            setConvertedPrice(data.toFormat('$0,0.00'));
          }
        });

      return () => {
        isMounted.current = false;
      };
    }, []);
    return convertedPrice;
  };

  return <ConvertComponent />;
};

export { ConvertCurrency };
