import { Box } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';

const dotFlashing = keyframes`
0% {
    background-color: #FE7B10;
  }
  50%,
  100% {
    background-color: #DADADA;
  }
`;

const StyledBox = styled(Box)(({ theme }) => ({
  '&.dot-flashing,&.dot-flashing::before,&.dot-flashing::after': {
    width: theme.typography.pxToRem(10),
    height: theme.typography.pxToRem(10),
    borderRadius: theme.typography.pxToRem(5),
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[100],
    animation: `${dotFlashing} 1s infinite linear alternate`,
  },
  '&.dot-flashing': {
    position: 'relative',
    animationDelay: '.5s',
  },
  '&.dot-flashing::before,&.dot-flashing::after': {
    content: "''",
    display: 'inline-block',
    position: 'absolute',
    top: '0',
  },

  '&.dot-flashing::before': {
    left: theme.typography.pxToRem(-15),
    animationDelay: '0s',
  },

  '&.dot-flashing::after': {
    left: theme.typography.pxToRem(15),
    animationDelay: '1s',
  },
}));

export default StyledBox;
