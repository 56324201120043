import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, useMediaQuery, useTheme } from '@mui/material';

import axios, { API } from 'lib/config/axios';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';
import { setLoading } from 'redux/features/ui-slice';

import { MEMBERSHIP_OVERLAY_STEPS } from 'views/common/components/Logical/MembershipOverlaySteps/utils';
import {
  MembershipOverlayStepOne,
  MembershipOverlayStepTwo,
  MembershipOverlayStepFour,
  MembershipOverlayCheckoutStep,
  MembershipOverlayHeader,
} from 'views/common/components/Logical/MembershipOverlaySteps';
import { setAppData } from 'redux/features/globalData-slice';

const MembershipOverlay = (props) => {
  const {
    membershipOverlayData,
    handleMembershipOverlayClose,
    membershipCheckoutCallback = () => {},
    joinedMembershipCallback,
    labels,
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();
  const handleErrorResponse = useErrorsHandler();

  const appState = useSelector((state) => state.globalData.appState);
  const [overlayState, setOverlayState] = useState({
    currentStep: {
      number: 1, // for displaying purpose only as there might be 3 or 4 steps
      identifier: MEMBERSHIP_OVERLAY_STEPS.selectPlan, // actual current step identifier
    },
    numberOfSteps: appState.current_user ? 3 : 4,
    selectedMembership: membershipOverlayData.memberships.find(
      (membership) => membership.promoted,
    ),
  });
  const [membershipPaymentData, setMembershipPaymentData] = useState();
  const [referralCode, setReferralCode] = useState(
    membershipOverlayData.membership_referral_code?.code,
  );

  const handleOverlayStateChange = (newState) => {
    setOverlayState((prev) => ({
      ...prev,
      ...newState,
    }));
  };
  const handleMembershipReferralCodeChange = (e) => {
    setReferralCode(e.target.value);
  };

  const getDataForPaymentStep = (membership) => {
    dispatch(setLoading(true));
    axios
      .get(API.memberships.checkoutGet.replace('{id}', membership.id), {
        params: {
          referring_page: 'id: 3; Extras Page',
        },
      })
      .then((res) => {
        setMembershipPaymentData(res.data);
        handleOverlayStateChange({
          currentStep: {
            number: overlayState.currentStep.number + 1,
            identifier: MEMBERSHIP_OVERLAY_STEPS.payment,
          },
        });
      })
      .catch((error) => {
        handleErrorResponse(error);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const prepareDataForPaymentStep = (membership) => {
    axios
      .get(API.layout)
      .then((res) => {
        dispatch(
          setAppData({
            ...res.data,
          }),
        );
        if (res.data.authenticity_token) {
          localStorage.setItem(
            'authenticity_token',
            res.data.authenticity_token,
          );
        }
      })
      .then(() => {
        getDataForPaymentStep(membership);
      })
      .catch((err) => {
        handleErrorResponse(err);
      });
  };

  const handleOverlayStepChange = (membership, isSignup) => {
    switch (overlayState.currentStep.identifier) {
      case MEMBERSHIP_OVERLAY_STEPS.selectPlan:
        handleOverlayStateChange({
          selectedMembership: membership,
        });
        if (appState.current_user) {
          prepareDataForPaymentStep(membership);
        } else {
          handleOverlayStateChange({
            currentStep: {
              number: 2,
              identifier: MEMBERSHIP_OVERLAY_STEPS.authentication,
            },
          });
        }
        break;
      case MEMBERSHIP_OVERLAY_STEPS.authentication:
        window.gtag('event', 'membership_signup', {
          event_category: 'Membership',
          event_action: 'Pageview',
          event_label: isSignup ? 'Sign Up' : 'Sign In',
          eventSource: window.location.pathname.includes('/extras')
            ? 'Extras Page'
            : 'Search Rentals Page',
          membershipPlan: overlayState.selectedMembership.name,
        });
        prepareDataForPaymentStep(overlayState.selectedMembership);
        break;
      case MEMBERSHIP_OVERLAY_STEPS.payment:
        handleOverlayStateChange({
          currentStep: {
            number: overlayState.currentStep.number + 1,
            identifier: MEMBERSHIP_OVERLAY_STEPS.returnToReservation,
          },
        });

        window.gtag('event', 'membership_signup', {
          event_category: 'Membership',
          event_action: 'Transaction',
          event_label: 'Confirmation',
          eventSource: window.location.pathname.includes('/extras')
            ? 'Extras Page'
            : 'Search Rentals Page',
          membershipPlan: overlayState.selectedMembership.name,
        });
        break;
      case MEMBERSHIP_OVERLAY_STEPS.returnToReservation:
        joinedMembershipCallback();
        break;
      default:
    }
  };

  const renderCurrentOverlaytStep = () => {
    switch (overlayState.currentStep.identifier) {
      case MEMBERSHIP_OVERLAY_STEPS.selectPlan:
        return (
          <MembershipOverlayStepOne
            membershipOverlayData={membershipOverlayData}
            overlayState={overlayState}
            handleOverlayStateChange={handleOverlayStateChange}
            handleOverlayStepChange={handleOverlayStepChange}
          />
        );
      case MEMBERSHIP_OVERLAY_STEPS.authentication:
        return (
          <MembershipOverlayStepTwo
            overlayState={overlayState}
            membershipOverlayData={membershipOverlayData}
            handleOverlayStepChange={handleOverlayStepChange}
          />
        );
      case MEMBERSHIP_OVERLAY_STEPS.payment:
        return (
          <MembershipOverlayCheckoutStep
            membershipOverlayData={membershipOverlayData}
            membershipPaymentData={membershipPaymentData}
            overlayState={overlayState}
            handleOverlayStepChange={handleOverlayStepChange}
            membershipCheckoutCallback={membershipCheckoutCallback}
            referralCode={referralCode}
            handleMembershipReferralCodeChange={
              handleMembershipReferralCodeChange
            }
          />
        );
      case MEMBERSHIP_OVERLAY_STEPS.returnToReservation:
        return (
          <MembershipOverlayStepFour
            handleOverlayStepChange={handleOverlayStepChange}
            labels={labels}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={membershipOverlayData.open}
      maxWidth="xl"
      fullWidth
      onClose={handleMembershipOverlayClose}>
      <MembershipOverlayHeader
        overlayState={overlayState}
        handleMembershipOverlayClose={handleMembershipOverlayClose}
      />
      {renderCurrentOverlaytStep()}
    </Dialog>
  );
};

export { MembershipOverlay };
