import React from 'react';
import { Box } from '@mui/material';
import blur from 'public/imgs/1x1.webp';
import Image from 'next/image';
import StyledButton from 'views/common/components/UI/Thumbnails/Thumbnails.styles';

const Thumbnails = (props) => {
  const { slidesArray, onClick, index } = props;
  return (
    <StyledButton className="TBtn" onClick={onClick}>
      <div className="TImgContainer">
        {slidesArray[index].youtube_id ? (
          <Box>
            <Image
              className="TImg"
              src={`https://img.youtube.com/vi/${slidesArray[index].youtube_id}/0.jpg`}
              alt="event gallery"
              placeholder="blur"
              blurDataURL={blur.src}
              layout="fill"
              objectFit="responsive"
              unoptimized
              objectPosition="50% 50%"
            />
          </Box>
        ) : (
          <Image
            className="TImg"
            src={slidesArray[index].urls.large}
            alt="event gallery"
            placeholder="blur"
            blurDataURL={blur.src}
            layout="fill"
            objectFit="responsive"
            unoptimized
            objectPosition="50% 50%"
          />
        )}
      </div>
    </StyledButton>
  );
};
export { Thumbnails };
