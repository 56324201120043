import { styled } from '@mui/material/styles';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ImgBox } from 'views/common/components/UI/ImgBox';
import { Box } from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },
  '& .MRGridRightMd': {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    '& .MRLocationAutocompleteWrapper': {
      position: 'relative',
      '& .MRLocationAutocomplete': {
        width: '100%',
        [theme.breakpoints.up('md')]: {
          width: theme.spacing(25),
        },
        [theme.breakpoints.up('lg')]: {
          width: theme.spacing(36.875),
        },
        '& .MuiInputBase-input.MuiOutlinedInput-input': {
          paddingLeft: theme.spacing(4),
        },

        '& .MuiFormLabel-root.MuiInputLabel-root': {
          '&:not(.MuiInputLabel-shrink)': {
            paddingLeft: theme.spacing(4),
          },
        },
      },
      '& .MRLocationIcon': {
        position: 'absolute',
        color: theme.palette.grey[500],
        left: theme.spacing(1.5),
        top: '50%',
        transform: 'translateY(-55%)',
      },
    },
  },
}));

export const StyledCarousel = styled(Carousel)(({ theme }) => ({
  '& .MRCarouselTextWrapper': {
    color: theme.palette.grey[600],
    display: 'flex',
    height: 'calc(100% - 11.25rem)',
    flexDirection: 'column',
    textAlign: 'center',
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      maxWidth: theme.spacing(33),
      margin: '0 auto',
    },
    '& .MRRoadText': {
      marginBottom: theme.spacing(2),
    },
    '& .MRDisText': {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 300,
    },
    '& .MRPriceBox': {
      display: 'flex',
      gap: theme.spacing(2),
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 'auto',
      marginBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
      '& .MRDisText': {
        lineHeight: 'normal',
        textDecoration: 'line-through',
      },
    },
  },

  '& .react-multiple-carousel__arrow': {
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(6),
    height: theme.spacing(6),
    top: theme.spacing(12),
    [theme.breakpoints.down('md')]: {
      top: theme.spacing(5.5),
    },
    '&:before': {
      fontWeight: 'bold',
    },
    '&.react-multiple-carousel__arrow--right': {
      right: 0,
    },
    '&.react-multiple-carousel__arrow--left': {
      left: 0,
    },
  },
  '& .react-multi-carousel-dot-list ': {
    display: 'none',
  },
}));

export const StyledImgBox = styled(ImgBox)(({ theme }) => ({
  '&.IBimgbox': {
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(18),
    },
  },
}));

export default StyledBox;
