import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .DDFLGuaranteeSide': {
    textAlign: 'end',
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
      textAlign: 'start',
    },
  },
}));
export default StyledBox;
