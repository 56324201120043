import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .CVHeader': {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1.35, 2),
    width: '100%',
    position: 'fixed',
    top: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    '& .CDBHeaderAvatarContainer': {
      position: 'relative',
      '&::after': {
        content: '""',
        width: 6,
        height: 6,
        backgroundColor: theme.palette.success.main,
        position: 'absolute',
        right: 9,
        bottom: 6,
        borderRadius: '50%',
      },
    },
    '& .CDBHeaderAvatar': {
      padding: theme.spacing(0.85),
      border: '1px solid',
      borderColor: theme.palette.grey[100],
      borderRadius: '50%',
      marginRight: theme.spacing(1),
    },
    '& .CVHeaderText': {
      lineHeight: 'normal',
      color: theme.palette.secondary.main,
    },
    '& .CVHeaderBetaText': {
      border: '1px solid',
      borderColor: theme.palette.grey[300],
      color: theme.palette.grey[300],
      borderRadius: 20,
      padding: theme.spacing(0.25, 1),
      marginLeft: theme.spacing(1),
      lineHeight: 1,
    },
  },
  '& .CVBody': {
    backgroundColor: theme.palette.grey.A000,
    whiteSpace: 'pre-wrap',
    padding: theme.spacing(2),
    position: 'fixed',
    width: '100%',
    top: theme.spacing(5.5),
    bottom: theme.spacing(7.5),
    overflow: 'auto',
    '& .CDBLink': {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(12),
    },
    '&::-webkit-scrollbar': {
      width: theme.spacing(1),
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey[100],
      borderRadius: theme.spacing(10),
    },
  },

  '& .CVFooter': {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1, 2),
    borderTop: `1px solid ${theme.palette.grey['50']}`,
    position: 'fixed',
    bottom: 0,
    width: '100%',
    '& .MuiInputBase-root': {
      fontSize: theme.typography.pxToRem(12),
      backgroundColor: theme.palette.grey.A000,
      lineHeight: 'normal',
      '& .MuiInputBase-input': {
        height: 'auto',
        padding: theme.spacing(1.75),
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.grey[100],
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.primary.main,
        },
        '& .CVFooterSend': {
          color: theme.palette.primary.main,
          '&.Mui-disabled': {
            color: theme.palette.grey[200],
          },
        },
      },
      '& .CVFooterSend': {
        color: theme.palette.grey[200],
        padding: 0,
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '& .MuiSvgIcon-root': {
          fontSize: theme.typography.pxToRem(20),
        },
      },
    },
    '&.CVExceedLimet': {
      '& .MuiInputBase-root': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.error.main,
        },
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.error.main,
          },
        },
      },
    },
  },
  '& .CBTextLimate': {
    fontSize: theme.spacing(1.5),
    lineHeight: theme.spacing(2),
    color: theme.palette.grey[200],
    textAlign: 'end',
    transition: 'all .5s',
    '&.CBTextLimitExceeded': {
      color: theme.palette.error.main,
    },
  },
}));

export default StyledBox;
