/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Grid, Skeleton, Box } from '@mui/material';

const TourPackagesWidgetSkeleton = () => (
  <Box>
    <Skeleton variant="rectangular" width="100%" height={50} />
    <Skeleton variant="text" width="20%" height={50} />
    <Skeleton variant="rectangular" width="100%" height={50} />
    <Skeleton variant="text" width="20%" height={50} />
    <Grid container spacing={2} py={3}>
      <Grid item lg={4} md={12} xs={12}>
        <Skeleton variant="rectangular" width="100%" height={100} />
      </Grid>
      <Grid item lg={4} md={12} xs={12}>
        <Skeleton variant="rectangular" width="100%" height={100} />
      </Grid>
      <Grid item lg={4} md={12} xs={12}>
        <Skeleton variant="rectangular" width="100%" height={100} />
      </Grid>
    </Grid>
    <Grid container spacing={10}>
      <Grid item xs={6}>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Grid>
    </Grid>
    <Skeleton variant="text" width="500%" height={50} />
  </Box>
);

export { TourPackagesWidgetSkeleton };
