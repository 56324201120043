import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';

const StyledBox = styled(Box)(({ theme }) => ({
  '&.PCSContainer': {
    border: `${theme.spacing(0.125)} solid ${theme.palette.grey[100]}`,
    borderRadius: theme.spacing(2),
    '& .MuiSkeleton-root ': {
      margin: 'auto',
    },
  },
}));
const PlanCardSkeleton = () => (
  <StyledBox className="PCSContainer">
    <Skeleton variant="text" width="90%" height={70} />
    <Skeleton variant="text" width="80%" height={60} />
    <Skeleton variant="text" width="85%" height={60} />
    <Skeleton variant="text" width="90%" height={70} />
  </StyledBox>
);
export { PlanCardSkeleton };
