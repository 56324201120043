import React from 'react';
import { Grid } from '@mui/material';
import { BestPriceGuarantee, DropdownFilter } from 'views/common/components';
import StyledBox from 'views/common/components/UI/DropdownFilterList/DropdownFilterList.styles';

const DropdownFilterList = (props) => {
  const {
    flitersList, handleChange, className, withOutGuarantee,
  } = props;
  return (
    <StyledBox className={`DDFLContiner ${className}`}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12} md={12} lg={!withOutGuarantee ? 8 : 12}>
          {flitersList.map((filterList) => (
            <DropdownFilter
              key={filterList.type}
              filterList={filterList}
              handleChange={handleChange}
            />
          ))}
        </Grid>
        {!withOutGuarantee && (
          <Grid item xs={12} md={12} lg={4} className="DDFLGuaranteeSide">
            <BestPriceGuarantee />
          </Grid>
        )}
      </Grid>
    </StyledBox>
  );
};

export { DropdownFilterList };
