import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey.A000,
  marginLeft: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[100]}`,
  padding: theme.spacing(0.5, 2),
  marginBottom: theme.spacing(2),
  width: theme.spacing(35.25),
  position: 'relative',
  zIndex: 2,
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    marginLeft: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  '& .MRCHLocalLawsBox': {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    marginTop: theme.spacing(1.125),
    paddingTop: theme.spacing(1.125),
  },
  '& .NMLLNoLicenseText': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.grey[400],
  },
  '& .NMLLNoLicenseIcon': {
    color: theme.palette.grey[300],
  },
  '& .NMLLLowText': {
    color: theme.palette.grey[400],
  },
}));
export default StyledBox;
