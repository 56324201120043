import React from 'react';
import Script from 'next/script';

const { envVars } = require('next/config').default().publicRuntimeConfig;

const { GTM_CODE } = envVars;

const GTMScript = () => (
  <>
    {typeof window !== 'undefined' && (
      <Script
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTM_CODE}')`,
        }}
      />
    )}
  </>
);

export { GTMScript };
