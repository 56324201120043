import React from 'react';

const Marker = (props) => {
  const {
    mapIcon, mapIconAlt, markerData, overlay,
  } = props;

  return (
    <>
      <img src={mapIcon} alt={mapIconAlt} />
      <div>{overlay(markerData)}</div>
    </>
  );
};

export { Marker };
