import React from 'react';
import StyledDialog, {
  StyledChatBotWrapper,
} from 'views/common/components/Logical/ChatBotMobilDialog/ChatBotMobilDialog.styles';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ChatBotMobilDialog = (props) => {
  const { open, onClose } = props;
  return (
    <StyledDialog open={open} onClose={onClose} fullScreen keepMounted>
      <IconButton color="secondary" className="CBMDCloseBtn" onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <StyledChatBotWrapper />
    </StyledDialog>
  );
};

export { ChatBotMobilDialog };
