import React from 'react';
import { Box, Grid, styled } from '@mui/material';
import { MainWidgetSkeleton } from 'views/common/components';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .MWSSWidgetWrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4, 0),
    minHeight: theme.spacing(50),
  },
}));
const MainWidgetContainerSectionSkeleton = () => (
  <StyledBox>
    <Grid container className="MWSSWidgetWrapper">
      <Grid item xs={12} md={8} lg={6}>
        <MainWidgetSkeleton vertical={false} />
      </Grid>
    </Grid>
  </StyledBox>
);
export { MainWidgetContainerSectionSkeleton };
