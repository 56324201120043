import React, { Fragment, useState } from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslation } from 'next-i18next';
import StyledBox from 'views/common/components/UI/MembershipFAQ/MembershipFAQ.styles';
import Typography500 from 'views/common/components/UI/Typography500';

const MembershipFAQ = (props) => {
  const { faqs = [], darkMode } = props;

  const { t } = useTranslation('fe_er_common_membership_faq');
  const [expanded, setExpanded] = useState(false);
  const [count, setCount] = useState(faqs.length > 3 ? 3 : faqs.length);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleOpenAllFAQBtnCLick = () => {
    setCount(faqs.length);
  };
  const handleCloseAllFAQBtnCLick = () => {
    setCount(faqs.length > 3 ? 3 : faqs.length);
  };
  return (
    <StyledBox className={darkMode ? 'MFAQDark' : ''}>
      <Typography500 variant="h3" component="h2" className="MFTitle">
        {t('fe_er_common_membership_faq:faqs_title')}
      </Typography500>
      {faqs.slice(0, count).map((q, index) => (
        <Fragment key={q.question}>
          <Accordion
            className="MFaccordion"
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            defaultExpanded={index === 0}>
            <AccordionSummary
              className="MFaccSummary"
              expandIcon={<ExpandMoreIcon />}>
              <Typography500
                variant="h5"
                component="p"
                className="MFaccSummaryText">
                {q.question}
              </Typography500>
            </AccordionSummary>
            <AccordionDetails className="MFaccDetails">
              <Typography
                variant="body2"
                component="p"
                className="MFaccDes"
                dangerouslySetInnerHTML={{
                  __html: q.answer,
                }}
              />
            </AccordionDetails>
          </Accordion>
        </Fragment>
      ))}
      {count !== faqs.length && (
        <Box textAlign="center">
          <Button onClick={handleOpenAllFAQBtnCLick} className="MFloadMore">
            {t('fe_er_common_membership_faq:open_all_faq')}
            {faqs.length}
            <ExpandMoreIcon />
          </Button>
        </Box>
      )}

      {count === faqs.length && count > 3 && (
        <Box textAlign="center">
          <Button onClick={handleCloseAllFAQBtnCLick} className="MFloadMore">
            {t('fe_er_common_membership_faq:close_all_faq')}
            {faqs.length}
            <ExpandLessIcon />
          </Button>
        </Box>
      )}
    </StyledBox>
  );
};

export { MembershipFAQ };
