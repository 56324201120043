import React from 'react';
import {
  Grid, Skeleton, useTheme, useMediaQuery,
} from '@mui/material';

const MakeCarouselSkeleton = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const getnumber = () => {
    let n;
    if (isTablet && !isMobile) {
      n = 2;
    } else if (isTablet && isMobile) {
      n = 2;
    } else {
      n = 5;
    }
    return n;
  };
  return (
    <Grid container spacing={2}>
      {[...Array(getnumber())].map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid item xs={6} md={6} lg key={index}>
          <Skeleton
            variant="rectangular"
            height={100}
            sx={{ borderRadius: 1 }}
          />
        </Grid>
      ))}
    </Grid>
  );
};
export { MakeCarouselSkeleton };
