import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ConfirmInformation = (props) => (
  <SvgIcon
    {...props}
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 59.4">
    <defs>
      <mask
        id="mask"
        x="36.7"
        y="31.86"
        width="14.11"
        height="13.33"
        maskUnits="userSpaceOnUse">
        <path
          fillrule="evenodd"
          d="m44.3,39.03h-1.09v-3.08h1.09v3.08Zm0,2.05h-1.09v-1.03h1.09v1.03Zm-.55-7.7c-3.02,0-5.46,2.3-5.46,5.14s2.45,5.14,5.46,5.14,5.46-2.3,5.46-5.14-2.45-5.14-5.46-5.14Z"
        />
      </mask>
    </defs>
    <rect fill="none" width="60" height="59.4" />
    <g>
      <path
        fill="#3a3a3a"
        d="m15.65,22.35v.71s.05.09.15.12c.1.03.23.05.37.05s.27-.02.37-.05c.1-.03.15-.07.15-.12v-.71s-.05-.09-.15-.12c-.1-.03-.23-.05-.37-.05s-.27.02-.37.05c-.1.03-.15.07-.15.12Z"
      />
      <path fill="#3a3a3a" d="m35.65,22.18h-17.39v1.04h17.39v-1.04Z" />
      <path
        fill="#3a3a3a"
        d="m15.65,26v.71s.05.09.15.12c.1.03.23.05.37.05s.27-.02.37-.05c.1-.03.15-.07.15-.12v-.71s-.05-.09-.15-.12c-.1-.03-.23-.05-.37-.05s-.27.02-.37.05c-.1.03-.15.07-.15.12Z"
      />
      <path fill="#3a3a3a" d="m35.65,25.83h-17.39v1.04h17.39v-1.04Z" />
      <path
        fill="#3a3a3a"
        d="m15.65,29.65v.71s.05.09.15.12c.1.03.23.05.37.05s.27-.02.37-.05c.1-.03.15-.07.15-.12v-.71s-.05-.09-.15-.12c-.1-.03-.23-.05-.37-.05s-.27.02-.37.05c-.1.03-.15.07-.15.12Z"
      />
      <path fill="#3a3a3a" d="m35.65,29.49h-17.39v1.04h17.39v-1.04Z" />
      <path
        fill="#3a3a3a"
        d="m15.65,33.3v.71s.05.09.15.12c.1.03.23.05.37.05s.27-.02.37-.05c.1-.03.15-.07.15-.12v-.71s-.05-.09-.15-.12c-.1-.03-.23-.05-.37-.05s-.27.02-.37.05c-.1.03-.15.07-.15.12Z"
      />
      <path fill="#3a3a3a" d="m35.65,33.14h-17.39v1.04h17.39v-1.04Z" />
      <polygon
        fill="#3a3a3a"
        fillrule="evenodd"
        points="10.7 15.66 46.69 15.66 46.69 31.57 45.65 31.57 45.65 16.71 11.74 16.71 11.74 41.75 36.26 41.75 36.26 42.79 10.7 42.79 10.7 15.66"
      />
      <path
        fill="#d95700"
        fillRule="evenodd"
        d="m44.3,39.03h-1.09v-3.08h1.09v3.08Zm0,2.05h-1.09v-1.03h1.09v1.03Zm-.55-7.7c-7.09-.09-7.09,10.37,0,10.27,7.09.09,7.09-10.37,0-10.27Z"
      />
      <g mask="url(#mask)">
        <rect
          fill="none"
          stroke="#d88282"
          strokeMiterlimit={10}
          x="37.2"
          y="32.36"
          width="13.11"
          height="12.33"
        />
      </g>
    </g>
  </SvgIcon>
);

export default ConfirmInformation;
