import { styled } from '@mui/material/styles';
import { DialogContent } from '@mui/material';

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  '&.MODialogContent': {
    textAlign: 'center',
    padding: theme.spacing(7),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5, 2),
    },
  },
  '& .MOCheckIcon': {
    color: theme.palette.success.main,
    marginBottom: theme.spacing(3.5),
    fontSize: theme.typography.pxToRem(30),
  },
  '& .MODialogContentText': {
    lineHeight: theme.spacing(5),
    color: theme.palette.secondary.dark,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(24),
    },
  },
  '& .MODialogAction': {
    justifyContent: 'center',
    marginTop: theme.spacing(6),
  },
}));
export default StyledDialogContent;
