/* eslint-disable no-sequences */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useTranslation } from 'next-i18next';

import { setSnackbar, setLoading } from 'redux/features/ui-slice';
import axios, { API } from 'lib/config/axios';
import PATHS from 'lib/navigation/paths';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';
import useCookies from 'views/common/hooks/useCookies';

import { TourPackagesWidget } from 'views/common/components';
import useHandleNavigationToRPPage from 'views/common/hooks/useHandleNavigationToRPPage';

const TourPackagesWidgetWrapper = (props) => {
  const {
    tourInfo,
    handleShowActionBar = () => {},
    getIncludeBikeStatus = () => {},
  } = props;

  const { t } = useTranslation('fe_er_common_tour_packages_widget');

  const appState = useSelector((state) => state.globalData.appState);
  const [includeBike, setIncludeBike] = useState();
  const [bookingDetails, setBookingDetails] = useState(null);
  const dispatch = useDispatch();
  const handleErrorResponse = useErrorsHandler();
  const appNavigator = useAppNavigator();
  const { navigateToNextPage } = useHandleNavigationToRPPage();

  const [, setCookie, removeCookie] = useCookies();

  const [bookingError, setBookingError] = useState();

  const getFilteredStartingPrice = () => {
    if (tourInfo.starting_price) {
      if (includeBike) {
        return tourInfo.starting_price.with_motorcycle;
      }
      return tourInfo.starting_price.without_motorcycle;
    }
    return null;
  };

  const disableDays = (date) => {
    const blackoutIntervals = bookingDetails.calendar_options.blackout_intervals;
    const disabledWeekDays = bookingDetails.calendar_options.disabled_week_days;

    let found;
    if (blackoutIntervals) {
      found = blackoutIntervals.findIndex(
        (interval) => date >= dayjs(interval.start) && date <= dayjs(interval.end),
      );
      if (found != -1) return true;
    }

    if (disabledWeekDays) {
      found = disabledWeekDays.findIndex(
        (interval) => interval.days.findIndex((day) => day == dayjs(date).get('d')) != -1
          && date >= dayjs(interval.start)
          && date <= dayjs(interval.end),
      );
      if (found != -1) return true;
    }

    return false;
  };

  const getBookingDetails = (tourID, params, firstReq, reservationType) => {
    dispatch(setLoading(true));
    let withMotorcycle = params.with_motorcycle;
    if (firstReq) {
      if (reservationType === 'without_motorcycle_reservations_only') {
        withMotorcycle = false;
      } else withMotorcycle = true;
    }

    axios
      .get(API.tours.bookingDetails.replace('{tour_id}', tourID), {
        params: {
          with_motorcycle: withMotorcycle,
          occurrence_id: params.occurrence_id && +params.occurrence_id,
          package_id: params.package_id && +params.package_id,
          packages_count: params.packages_count && +params.packages_count,
          pickup_date: params.pickup_date,
          reservation_identifier: params.reservation_identifier,
        },
      })
      .then((res) => {
        setBookingDetails({
          ...res.data,
          reservation_identifier: tourInfo.reservation_identifier,
        });
        dispatch(setLoading(false));
      })
      .catch((error) => {
        dispatch(setLoading(false));
        if (error.response.status == 403) {
          if (bookingDetails) {
            setBookingDetails((prev) => ({
              ...prev,
              pickup_date: params.pickup_date,
              reservation_identifier: tourInfo.reservation_identifier,
              packages: [],
            }));

            dispatch(
              setSnackbar({
                open: true,
                severity: 'error',
                message: t(
                  'fe_er_common_tour_packages_widget:no_available_packages',
                ),
              }),
            );
          } else if (includeBike === undefined) setIncludeBike(true);
        } else {
          handleErrorResponse(error, false);
        }
      });
  };

  const handleGetBookingDetails = (widgetData) => {
    getBookingDetails(tourInfo.id, widgetData);
  };

  const bookTour = (widgetData) => {
    axios
      .post(API.tours.bookTour.replace('{tour_id}', tourInfo.id), {
        authenticity_token: appState.authenticity_token,
        ...widgetData,
      })
      .then((res) => {
        const result = res.data;
        removeCookie('reservation');
        setCookie('reservation', result.reservation.identifier, {
          path: '/',
        });
        if (includeBike) {
          navigateToNextPage(res.data.reservation);
        } else {
          appNavigator.push(
            PATHS.personalBikeReservation.checkout(
              result.reservation.identifier,
            ),
          );
        }
      })
      .catch((error) => {
        switch (error.response.status) {
          case 406:
            setBookingError(error.response.data);
            break;
          case 403:
            dispatch(
              setSnackbar({
                open: true,
                severity: 'error',
                message: error.response.data.message,
              }),
            );
            break;
          default:
            handleErrorResponse(error, false);
        }
      });
  };

  const getInitialIncludeBikeState = () => {
    // this to check if the acceptable_reservations_type support any type of reservation than we check if we selected package and then select has motorcycles than we select include bike option otherwise bring my own
    if (
      tourInfo.acceptable_reservations_type
      === 'with_motorcycle_reservations_only'
    ) {
      return true;
    }

    if (tourInfo.acceptable_reservations_type === 'any_reservations') {
      if (bookingDetails?.selected_package_id) {
        const idx = bookingDetails.packages.findIndex(
          (pack) => pack.id === bookingDetails.selected_package_id,
        );
        if (bookingDetails.packages[idx].motorcycles_count) {
          return true;
        }
        return false;
      }
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (tourInfo && !bookingDetails) {
      const params = {
        package_id: tourInfo.tour_package?.id,
        occurrence_id: tourInfo.tour_occurrence?.id,
        pickup_date: tourInfo.start_time,
        packages_count: tourInfo.selected_packages_count,
        with_motorcycle: tourInfo.with_motorcycle,
        reservation_identifier: tourInfo.reservation_identifier,
      };
      getBookingDetails(
        tourInfo.id,
        params,
        true,
        tourInfo.acceptable_reservations_type,
      );
    }
  }, []);

  useEffect(() => {
    if (bookingDetails && typeof includeBike === 'undefined') {
      setIncludeBike(getInitialIncludeBikeState());
    }
  }, [bookingDetails]);

  useEffect(() => {
    if (includeBike) {
      getIncludeBikeStatus(includeBike);
    }
  }, [includeBike]);
  return (
    <>
      {includeBike !== undefined
        && (bookingDetails ? (
          <TourPackagesWidget
            bookingDetails={bookingDetails}
            includeBike={includeBike}
            startingPrice={getFilteredStartingPrice()}
            onIncludeBikeChange={setIncludeBike}
            onBookTour={bookTour}
            bookingError={bookingError}
            disableDates={disableDays}
            getBookingDetails={handleGetBookingDetails}
            acceptableReservationsType={tourInfo.acceptable_reservations_type}
            handleShowActionBar={handleShowActionBar}
            {...props}
          />
        ) : (
          <TourPackagesWidget
            includeBike={includeBike}
            onIncludeBikeChange={setIncludeBike}
            onBookTour={bookTour}
            acceptableReservationsType={tourInfo.acceptable_reservations_type}
            getBookingDetails={handleGetBookingDetails}
            handleShowActionBar={handleShowActionBar}
            {...props}
          />
        ))}
    </>
  );
};

export { TourPackagesWidgetWrapper };
