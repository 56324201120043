import React from 'react';
import {
  FormControl, Chip, RadioGroup, Box,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useTranslation } from 'next-i18next';
import Typography500 from 'views/common/components/UI/Typography500';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  '& .chipItem': {
    '&.MuiChip-root': {
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.secondary.dark,
      borderRadius: 15,
      border: `1px solid${theme.palette.grey[100]}`,
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },

    '&.Selection': {
      backgroundColor: theme.palette.error.A000,
      border: `1px solid${theme.palette.primary.main}`,
      boxShadow: `0px 0px 3px ${theme.palette.primary.main}`,
      '& svg': {
        fill: theme.palette.primary.main,
      },
    },
    '& svg': {
      fill: theme.palette.grey[200],
    },
    '& .RGFOverlayTitles': {
      color: theme.palette.grey[200],
    },
  },

  '&.RadioGroupFilter': {
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
    '& .MuiFormControl-root': {
      paddingRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(2),
        display: 'block',
      },
    },
  },
}));

const RadioGroupFilter = (props) => {
  const { filter, handleClick } = props;
  const { t } = useTranslation('fe_er_common_radio_filter');
  const showMembershipPromotion = useSelector(
    (state) => state.globalData.showMembership,
  );

  return (
    <StyledRadioGroup row className="RadioGroupFilter">
      <Box mt={4} sx={{ display: { md: 'none', xs: 'block' } }}>
        <Typography500 className="RGFOverlayTitles" variant="h4" component="p">
          {t('fe_er_locations:locations')}
        </Typography500>
      </Box>
      <FormControl variant="outlined">
        <Chip
          className={`chipItem ${
            filter === 'all_locations' ? 'Selection' : ''
          }`}
          icon={
            filter === 'all_locations' ? (
              <CheckCircleIcon fontSize="small" />
            ) : (
              <RadioButtonUncheckedIcon fontSize="small" />
            )
          }
          label={t('fe_er_common_radio_filter:all_locations')}
          name="all_locations"
          variant="outlined"
          fullWidth
          onClick={() => {
            handleClick('all_locations');
          }}
        />
      </FormControl>
      <FormControl variant="outlined">
        <Chip
          fullWidth
          icon={
            filter === 'us' ? (
              <CheckCircleIcon fontSize="small" />
            ) : (
              <RadioButtonUncheckedIcon fontSize="small" />
            )
          }
          label={t('fe_er_common_radio_filter:us')}
          className={`chipItem ${filter === 'us' ? 'Selection' : ''}`}
          name="us"
          variant="outlined"
          onClick={() => {
            handleClick('us');
          }}
        />
      </FormControl>
      <FormControl variant="outlined">
        <Chip
          icon={
            filter === 'international' ? (
              <CheckCircleIcon fontSize="small" />
            ) : (
              <RadioButtonUncheckedIcon fontSize="small" />
            )
          }
          fullWidth
          className={`chipItem ${
            filter === 'international' ? 'Selection' : ''
          }`}
          label={t('fe_er_common_radio_filter:international')}
          name="international"
          variant="outlined"
          onClick={() => {
            handleClick('international');
          }}
        />
      </FormControl>
      {showMembershipPromotion && (
        <>
          <Box mt={4} sx={{ display: { md: 'none', xs: 'block' } }}>
            <Typography500
              className="RGFOverlayTitles"
              variant="h4"
              component="p">
              {t('fe_er_locations:club_eaglerider')}
            </Typography500>
          </Box>
          <FormControl variant="outlined">
            <Chip
              icon={
                filter === 'club' ? (
                  <CheckCircleIcon fontSize="small" />
                ) : (
                  <RadioButtonUncheckedIcon fontSize="small" />
                )
              }
              fullWidth
              className={`chipItem ${filter === 'club' ? 'Selection' : ''}`}
              label={t('fe_er_locations:club_eaglerider_location')}
              name="club"
              variant="outlined"
              onClick={() => {
                handleClick('club');
              }}
            />
          </FormControl>
        </>
      )}
    </StyledRadioGroup>
  );
};

export { RadioGroupFilter };
