import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ImgBox } from 'views/common/components';

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0),
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing(2),
  },
  '& .MuiCard-root': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  '& .CECHeaderWrapper': {
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
    },
    '& .CECExperienceRating': {
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(2),
      },
    },
    '& .CECCardHeader': {
      padding: 0,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'start',
      },
      '& .CECAvatar': {
        textTransform: 'capitalize',
      },
      '& .MuiCardHeader-title': {
        color: theme.palette.secondary.main,
        fontSize: theme.typography.pxToRem(28),
        fontWeight: 500,
        lineHeight: theme.spacing(5),
        textTransform: 'capitalize',
      },
      '& .MuiCardHeader-subheader': {
        color: theme.palette.secondary.light,
        fontSize: theme.typography.pxToRem(12),
        letterSpacing: '0.4',
        lineHeight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
          paddingTop: theme.spacing(0.5),
        },
      },
      '& .MuiCardHeader-avatar': {
        padding: theme.spacing(0.25),
        borderRadius: '50%',
        border: `1px solid ${theme.palette.grey[100]}`,
        '& .MuiAvatar-root': {
          width: theme.spacing(7),
          height: theme.spacing(7),
          backgroundColor: theme.palette.secondary.main,
        },
      },
    },
  },
  '& .CECCardContent': {
    padding: 0,
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(10),
      paddingTop: theme.spacing(1.5),
    },
    '& .CECLinkIconWrapper': {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
      },
    },
    '& .CECLink': {
      color: theme.palette.secondary.main,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(3),
      },
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.spacing(2.5),
        width: 'fit-content',
      },
      '&.CECLinkLarge': {
        width: 'fit-content',
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 700,
        lineHeight: 'normal',
        [theme.breakpoints.up('md')]: {
          marginRight: 0,
        },
        '& .CECLinkIcon': {
          width: theme.spacing(2.75),
        },
        [theme.breakpoints.down('md')]: {
          fontSize: theme.typography.pxToRem(14),
        },
      },
      '& .CECLinkIcon': {
        paddingLeft: theme.spacing(0.5),
        width: theme.spacing(2.25),
      },
    },
  },
}));

export const StyledImgBox = styled(ImgBox)(({ theme }) => ({
  padding: theme.spacing(0.25),
  borderRadius: '50%',
  border: `1px solid ${theme.palette.grey[100]}`,
  '&.IBimgbox': {
    borderRadius: '50%',
    width: theme.spacing(7),
    minWidth: theme.spacing(7),
    minHeight: theme.spacing(7),
  },
}));

export default StyledBox;
