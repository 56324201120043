import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.A000,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1.25, 2),
  justifyContent: 'center',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    textAlign: 'center',
  },
  '& .RPBText': {
    textTransform: 'uppercase',
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(1.25),
    },
  },
  '& .RPBActions': {
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default StyledBox;
