import React from 'react';
import {
  Grid, IconButton, InputBase, Box, Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import { useTranslation } from 'next-i18next';
import Typography500 from 'views/common/components/UI/Typography500';
import { RadioGroupFilter } from 'views/common/components/UI/RadioGroupFilter';

const StyleBox = styled(Box)(({ theme }) => ({
  '& .RFSearchWrapper': {
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.09)',
    marginRight: theme.spacing(-2),
    marginLeft: theme.spacing(-2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    '& .searchInput': {
      backgroundColor: theme.palette.grey.A000,
      display: 'flex',
      borderRadius: 30,
      border: `1px solid ${theme.palette.grey[50]}`,
      height: theme.spacing(4.5),
      '& .MuiInputBase-input': {
        fontSize: theme.typography.pxToRem(14),
      },
    },

    '& .iconSearch': {
      padding: theme.spacing(1.125, 0.5, 1.125, 1.125),
      margin: theme.spacing(0, 0.5),
      color: theme.palette.primary.main,
    },
    '& .RFFilterButton': {
      border: `1px solid ${theme.palette.grey[50]}`,
      backgroundColor: theme.palette.grey.A000,
      color: theme.palette.grey[400],
      borderRadius: theme.spacing(3),
      width: theme.spacing(4.5),
      height: theme.spacing(4.5),
      textTransform: 'capitalize',
      '& .RFFilterButtonIcon': {
        fontSize: theme.typography.pxToRem(18),
      },
    },
  },
}));

const RadioFilter = (props) => {
  const { t } = useTranslation('fe_er_common_radio_filter');
  const {
    handlesearch,
    handleChange,
    searchPlacholder = t('fe_er_common_radio_filter:search_location_name'),
    className,
    filter,
    handleClick,
    handleFiltersOverlayOpen,
  } = props;

  return (
    <StyleBox>
      <Stack direction="row" spacing={1.25} p={2} className="RFSearchWrapper">
        <Box className="searchInput" flex={1}>
          <IconButton
            onClick={handlesearch}
            className="iconSearch"
            size="large">
            <SearchIcon fontSize="small" />
          </IconButton>
          <InputBase
            id="search"
            type="text"
            value={null}
            onChange={handleChange}
            placeholder={searchPlacholder}
            fullWidth
          />
        </Box>
        <IconButton
          variant="outlined"
          className="RFFilterButton"
          color="secondary"
          onClick={handleFiltersOverlayOpen}>
          <TuneIcon className="RFFilterButtonIcon" />
        </IconButton>
      </Stack>
      <Grid
        container
        spacing={2}
        pb={1.15}
        className={`${className} RadioFilter`}>
        <Grid item lg={1} sx={{ display: { md: 'block', xs: 'none' } }}>
          <Typography500 variant="h5" component="p">
            {t('fe_er_common_radio_filter:filter_by')}
          </Typography500>
        </Grid>
        <Grid
          item
          spacing={2}
          lg={11}
          sx={{ display: { md: 'block', xs: 'none' } }}>
          <RadioGroupFilter filter={filter} handleClick={handleClick} />
        </Grid>
      </Grid>
    </StyleBox>
  );
};

export { RadioFilter };
