import React, { useState } from 'react';
import {
  Typography,
  Button,
  IconButton,
  useTheme,
  Grid,
  Box,
  AccordionSummary,
} from '@mui/material';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import IconSpecs from 'views/common/components/UI/Icons/IconSpecs';
import { useTranslation } from 'next-i18next';
import { ImgBox } from 'views/common/components';
import {
  MoreDiscountRates,
  BikeDetails,
  NoMotorcycleLicenseLable,
} from 'views/common/components/UI/MotorcycleRentalCardHorizontalVariant';
import ReactCardFlip from 'react-card-flip';
import Typography500 from 'views/common/components/UI/Typography500';
import StyledBox from 'views/common/components/UI/MotorcycleRentalCardHorizontalVariant/MotorcycleRentalCardHorizontalVariant.styles';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import PATHS from 'lib/navigation/paths';
import CloseIcon from '@mui/icons-material/Close';

const MotorcycleRentalCardHorizontalVariant = (props) => {
  const {
    bikeInfo,
    name,
    vehicleClassName,
    price,
    image,
    discountsArr = [],
    modelAttributes,
    bikePageurl,
    deal,
    handleCardClick,
    handleReserveNowBtn,
    bikeDetailsUrl,
    className,
    showMembershipPromotion,
    requireNoLicense,
    modelNameComponent = 'p',
  } = props;

  const theme = useTheme();
  const { t } = useTranslation('fe_er_common_rental_card_horizontal_variant');
  const [isShowDetails, setIsShowDetails] = useState(false);
  const [isExpand, setIsExpand] = useState(false);

  return (
    <StyledBox
      className={`${className} ${showMembershipPromotion && 'MRCHVPromotion'}`}
      isExpand={isExpand}>
      <ReactCardFlip isFlipped={isShowDetails} flipDirection="horizontal">
        <Box className="MRCHVRentalCardRoot">
          <NavigatorLink
            href={bikePageurl}
            onClick={() => {
              handleCardClick(bikeInfo);
            }}
            className="MRCHVBikeLink"
          />

          <Grid container className="MRCHVFrontCardContiner">
            <Grid item lg={8} md={12} xs={12}>
              <Box>
                <Grid container className="MRCHImageAndDetialsContiner">
                  <Grid item lg={7} md={12} xs={12}>
                    <Box className="MRCHVMotorTitleHolder">
                      <Box className="MRCHVMotorTitle">
                        <Typography500
                          variant="h4"
                          className="MRCHVMotorModelName"
                          component={modelNameComponent}>
                          {name}
                        </Typography500>
                        <Typography
                          variant="h5"
                          component="p"
                          className="MRCHVGrey300">
                          {vehicleClassName}
                        </Typography>
                      </Box>
                      <IconButton
                        onClick={() => {
                          setIsShowDetails(true);
                        }}
                        className="MRCHVSpecsHolder showTABLET">
                        <IconSpecs />
                      </IconButton>
                    </Box>
                    {deal && (
                      <Box className="MRCHVDeal showDESKTOP">
                        <CardGiftcardIcon />
                        <Typography500
                          component="span"
                          className="MRCHVDealText">
                          {t(
                            'fe_er_common_rental_card_horizontal_variant:value_deal',
                          )}
                        </Typography500>
                      </Box>
                    )}
                    {requireNoLicense && (
                      <Box className="showDESKTOP">
                        <NoMotorcycleLicenseLable />
                      </Box>
                    )}
                    <AccordionSummary
                      onClick={() => setIsExpand((prev) => !prev)}>
                      {isExpand ? <CloseIcon /> : <IconSpecs />}
                      <Typography500
                        component="span"
                        pl={1}
                        className="MRCHVSpacsText">
                        {t('fe_er_common_rental_card_horizontal_variant:specs')}
                      </Typography500>
                    </AccordionSummary>
                  </Grid>
                  <Grid item lg={5} md={12} xs={12}>
                    <ImgBox
                      classes="MRCHVImgBox"
                      image={{ src: image.src, alt: image.alt }}
                      imgHeight={theme.spacing(22)}
                      imgObjectFit="contain"
                    />
                    {deal && (
                      <Box className="MRCHVDeal showTABLET">
                        <CardGiftcardIcon />
                        <Typography500
                          component="span"
                          className="MRCHVDealText">
                          {t(
                            'fe_er_common_rental_card_horizontal_variant:value_deal',
                          )}
                        </Typography500>
                      </Box>
                    )}
                    {requireNoLicense && (
                      <Box className="showTABLET">
                        <NoMotorcycleLicenseLable />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item lg={4} md={12} xs={12} className="MRCHVMoreInfoGrid">
              <Box className="MRCHVMotorInfoHolder">
                {/* MORE DISCOUNT RATES */}
                <MoreDiscountRates price={price} discountsArr={discountsArr} />
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    handleReserveNowBtn(bikeInfo);
                  }}>
                  {t('fe_er_common_rental_card_horizontal_variant:reserve_now')}
                </Button>
              </Box>
            </Grid>
          </Grid>
          {showMembershipPromotion && (
            <Typography500
              className="MRCHVSaveText showTABLET"
              component={NavigatorLink}
              href={PATHS.motorcycleClub.index()}>
              {t('fe_er_common_rental_card_horizontal_variant:save_to_join')}
            </Typography500>
          )}
        </Box>
        <BikeDetails
          bikeInfo={bikeInfo}
          close={() => setIsShowDetails(false)}
          name={name}
          modelAttributes={modelAttributes}
          handleReserveNowBtn={handleReserveNowBtn}
          bikePageurl={bikePageurl || bikeDetailsUrl}
          isShowDetails={isShowDetails}
        />
      </ReactCardFlip>
      {showMembershipPromotion && (
        <Typography500
          className="MRCHVSaveText showDESKTOP"
          component={NavigatorLink}
          href={PATHS.motorcycleClub.index()}>
          {t('fe_er_common_rental_card_horizontal_variant:save_to_join')}
        </Typography500>
      )}
    </StyledBox>
  );
};

export { MotorcycleRentalCardHorizontalVariant };
