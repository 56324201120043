import React from 'react';
import Script from 'next/script';
import { useSelector } from 'react-redux';
import getGoogleAnalyticsTrackingId from 'lib/config/google-analytics';
import { mapNamedLocaleToLocale } from 'lib/data/localesMap';
import useAppNavigator from 'views/common/hooks/useAppNavigator';

const GAScript = () => {
  const isCrawler = useSelector((state) => state.globalData.isCrawler);
  const appNavigator = useAppNavigator();
  if (isCrawler) {
    if (typeof window !== 'undefined') window.ga = () => {};
  }
  return (
    <>
      {!isCrawler && (
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

    ga('create', '${getGoogleAnalyticsTrackingId(
              mapNamedLocaleToLocale[appNavigator.locale],
            )}', 'auto');
    ga('send', 'pageview')`,
          }}
        />
      )}
    </>
  );
};

export { GAScript };
