import React from 'react';
import {
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Box,
  Stack,
  Divider,
  useTheme,
} from '@mui/material';
import StyledBox, {
  StyledImgBox,
} from 'views/common/components/UI/CustomerExperienceCard/CustomerExperienceCard.styles';
import { ExperienceRating } from 'views/common/components/UI/ExperienceRating/ExperienceRating';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import clsx from 'clsx';

const CustomerExperienceCard = (props) => {
  const {
    userName,
    avatarDisplayChars,
    reviewDate,
    bikeModel,
    pickupLocation,
    tourPage,
    userImage,
    value,
  } = props;

  const theme = useTheme();

  return (
    <StyledBox linkSize="small">
      <Card>
        <Stack className="CECHeaderWrapper">
          <CardHeader
            avatar={
              userImage.src ? (
                <StyledImgBox
                  imgObjectFit="contain"
                  imgHeight={theme.spacing(7)}
                  imgWidth={theme.spacing(7)}
                  image={userImage}
                />
              ) : (
                <Avatar
                  src={userImage.src}
                  size="large"
                  alt={userImage.alt}
                  className="CECAvatar">
                  {/* If we don't have image we need to show Letter avatar for user name */}
                  {avatarDisplayChars}
                </Avatar>
              )
            }
            title={userName}
            subheader={reviewDate}
            className="CECCardHeader"
          />
          <Box className="CECExperienceRating">
            <ExperienceRating value={value} />
          </Box>
        </Stack>
        <Box mt={2} className="showMOBILE">
          <Divider light />
        </Box>
        <CardContent className="CECCardContent">
          {bikeModel.name && (
            <Box mb={1.5}>
              <NavigatorLink
                target="_blank"
                href={bikeModel.url}
                className={clsx('CECLink', 'CECLinkLarge')}
                translate={bikeModel.translateUrl}>
                {bikeModel.name}
                <OpenInNewIcon className="CECLinkIcon" />
              </NavigatorLink>
            </Box>
          )}

          <Box className="CECLinkIconWrapper">
            {pickupLocation.name && (
              <NavigatorLink
                target="_blank"
                href={pickupLocation.url}
                className="CECLink">
                {pickupLocation.name}
                <OpenInNewIcon className="CECLinkIcon" />
              </NavigatorLink>
            )}
            {tourPage.name && (
              <NavigatorLink
                endIcon={<OpenInNewIcon />}
                target="_blank"
                href={tourPage.url}
                className="CECLink">
                {tourPage.name}
                <OpenInNewIcon className="CECLinkIcon" />
              </NavigatorLink>
            )}
          </Box>
        </CardContent>
      </Card>
    </StyledBox>
  );
};
export { CustomerExperienceCard };
