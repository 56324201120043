import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme, isExpand }) => ({
  height: '100%',
  '& .MRCHVRentalCardRoot': {
    backgroundColor: theme.palette.common.white,
    borderRadius: '2px',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderBottom: isExpand && '0px',
    boxShadow: isExpand
      ? '1px 0px 6px rgba(0, 0, 0, 0.12)'
      : '1px 3px 6px rgba(0, 0, 0, 0.12)',
    marginTop: '2px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
      cursor: 'pointer',
    },
    '& .la-accordionRoot': {
      position: 'relative',
      zIndex: 2,
    },
  },
  [theme.breakpoints.down('lg')]: {
    '& .react-card-flip, .react-card-back, .react-card-front': {
      height: '100%',
    },
    '& .react-card-back, & .react-card-front': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  '& .react-card-back': {
    [theme.breakpoints.up('lg')]: {
      backfaceVisibility: isExpand && 'visible !important',
      position: isExpand && 'relative !important',
      transform: isExpand && 'none !important',
      transition: 'none !important',
      transformStyle: isExpand && 'none !important',
      border: `1px solid ${theme.palette.grey[100]}`,
      borderTop: '0px',
      boxSizing: 'border-box',
      boxShadow: '1px 3px 6px rgba(0, 0, 0, 0.12)',
    },
    [theme.breakpoints.down('lg')]: {
      backgroundColor: theme.palette.secondary.main,
      position: 'relative',
    },
  },
  '& .MRCHVMotorTop': {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row-reverse',
      height: '100%',
      alignItems: 'center',
    },
  },
  '& .MRCHVMotorTitleHolder': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: theme.spacing(10.75),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(2),
      height: 'auto',
    },
    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
  },
  '& .MRCHVMotorModelName': {
    fontSize: theme.spacing(2.5),
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.spacing(2),
    },
  },
  '& .MRCHVImgBox': {
    margin: theme.spacing(2, 0),
  },
  '& .MRCHVSpecsHolder': {
    padding: 0,
    marginLeft: theme.spacing(2),
    position: 'relative',
    zIndex: 1,
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    borderRadius: '50%',
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.common.white,
    cursor: 'pointer',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
    '& svg': {
      color: 'inherit',
      height: theme.spacing(2),
    },
  },
  '& .MRCHVMoreInfoGrid': {
    [theme.breakpoints.down('lg')]: {
      marginTop: 'auto',
    },
  },
  '& .MRCHVMotorInfoHolder': {
    padding: theme.spacing(0, 1, 2),
    height: '100%',
  },
  '& .MRCHVGrey300': {
    color: theme.palette.grey['300'],
  },
  '& .MRCHVBikeLink': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  '& .MRCHImageAndDetialsContiner': {
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row-reverse',
    },
  },
  '& .MRCHVSaveText': {
    backgroundColor: theme.palette.grey.A000,
    zIndex: 1,
    textAlign: 'center',
    padding: theme.spacing(1, 3),
    color: theme.palette.primary.main,
    fontSize: theme.spacing(1.75),
    [theme.breakpoints.up('lg')]: {
      textAlign: 'right',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(1.75),
    },
  },
  '& .MuiAccordion-root': {
    boxShadow: 'none',
    borderRadius: 0,
  },
  '& .MuiAccordionDetails-root': {
    borderTop: `1px solid ${theme.palette.grey[100]}`,
  },
  '& .MuiAccordionSummary-root': {
    width: 'fit-content',
    minHeight: 'unset',
    position: 'relative',
    zIndex: 2,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      display: 'none',
      marginBottom: theme.spacing(0),
    },
    '&.Mui-expanded': {
      minHeight: 'unset',
    },
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
    '& .MuiSvgIcon-root ': {
      color: theme.palette.grey[400],
      width: theme.spacing(2),
    },
  },
  '& .MRCHVSpacsText': {
    fontSize: theme.spacing(2),
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
  },
  '& .MRCHVDeal': {
    margin: theme.spacing(3, 0, 2, 0),
    padding: theme.spacing(0.625, 2),
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    alignItems: 'center',
    display: 'inline-flex',
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(0, 0, 2, 0),
    },
    '& .MuiSvgIcon-root ': {
      width: theme.spacing(2),
      height: theme.spacing(2),
      marginRight: theme.spacing(0.625),
    },
    '&.showDESKTOP': {
      margin: theme.spacing(0, 0, 2, 2),
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },
    '&.showTABLET': {
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
  },
  '& .MRCHVDealText': {
    fontSize: theme.spacing(1.75),
  },
  '& .MRCHVFrontCardContiner': {
    padding: theme.spacing(2, 2, 1, 2),
    [theme.breakpoints.down('lg')]: {
      height: '100%',
    },
  },
  [theme.breakpoints.down('md')]: {
    '& .react-card-flip': {
      perspective: 'unset !important',
    },
    '& .react-card-flipper': {
      position: 'unset !important',
    },
  },
  [theme.breakpoints.up('lg')]: {
    '&.MRCHVPromotion': {
      '& .MRCHVRentalCardRoot': {
        borderBottom: '0px',
        boxShadow: '0px -4px 6px rgb(0 0 0 / 12%)',
      },
      '& .react-card-back': {
        boxShadow: '0px 0px 6px rgb(0 0 0 / 12%)',
        borderBottom: 0,
      },
      '& .MRCHVSaveText': {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey[100]}`,
        borderTop: 0,
        boxShadow: '0px 2px 6px rgb(0 0 0 / 12%)',
        '&.showDESKTOP': {
          display: 'block',
        },
      },
    },
  },
}));
export default StyledBox;
