/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import {
  DialogContent,
  Grid,
  useMediaQuery,
  useTheme,
  Typography,
  Button,
  IconButton,
  Container,
  TextField,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import MobileDetect from 'mobile-detect';

import {
  setLocalStorageKeyeWithTTL,
  checkTTLExpiration,
} from 'lib/utils/localStroage';
import axios, { API } from 'lib/config/axios';
import { setSnackbar } from 'redux/features/ui-slice';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';

import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import CaptureEmailOverlayStyledDialog from 'views/common/components/Logical/CaptureEmailOverlay/CaptureEmailOverlay.styles';

const { envVars } = require('next/config').default().publicRuntimeConfig;

const CaptureEmailOverlay = () => {
  const dispatch = useDispatch();
  const { appState, googleAnalytics } = useSelector(
    (state) => state.globalData,
  );
  const expirationTimeInHours = appState?.settings?.show_capture_email_overlay?.expiration_time || 24;
  const handleErrorResponse = useErrorsHandler();
  const { t } = useTranslation('fe_er_common_capture_email');

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  const [openOverlay, setOpenOverlay] = useState(false);
  const [email, setEmail] = useState('');

  const recaptchaRef = useRef();

  const handleEmailChange = (value) => {
    setEmail(value);
  };

  const handleCloseOverlay = () => {
    setOpenOverlay(false);
    setEmail('');
  };

  const handleSendMeDeals = (value) => {
    axios
      .post(API.newslettersSubscribe, {
        authenticity_token: appState.authenticity_token,
        'g-recaptcha-response': value,
        'iv-g-recaptcha': '1',
        newsletter_subscription: {
          email,
        },
      })
      .then(() => {
        dispatch(
          setSnackbar({
            open: true,
            severity: 'success',
            message: t('fe_er_common_capture_email:subscribed_text'),
          }),
        );

        window.gtag('event', 'newsletter_signup', {
          event_category: googleAnalytics.newsLetterEventCategory,
          event_label: 'Newsletter',
          event_action: 'Lead',
        });
        handleCloseOverlay();
      })
      .catch((error) => {
        recaptchaRef.current.reset();
        switch (error.response.status) {
          case 403:
            dispatch(
              setSnackbar({
                open: true,
                severity: 'error',
                message: error.response.data.message,
              }),
            );
            break;
          case 406:
            if (error.response.data.error_code === 'ERR001') {
              dispatch(
                setSnackbar({
                  open: true,
                  severity: 'success',
                  message: t('fe_er_common_capture_email:email_exits_text'),
                }),
              );
              handleCloseOverlay();
            } else {
              dispatch(
                setSnackbar({
                  open: true,
                  severity: 'error',
                  message: error.response.data.message,
                }),
              );
            }
            break;
          default:
            handleErrorResponse(error);
        }
      });
  };

  const handleRecaptchaButtonClick = (e) => {
    e.preventDefault();
    recaptchaRef.current.execute();
  };
  let emailOverlayTimeout;
  let emailOverlayTimeoutWork = false;

  const handleEmailOverlay = (outScreen) => {
    if (outScreen && !emailOverlayTimeoutWork) {
      emailOverlayTimeoutWork = true;
      emailOverlayTimeout = setTimeout(() => {
        setOpenOverlay(true);
        setLocalStorageKeyeWithTTL(
          'hide_capture_email_overlay',
          1,
          expirationTimeInHours,
        );
        emailOverlayTimeoutWork = false;
      }, 1000);
    } else if (emailOverlayTimeoutWork) {
      clearInterval(emailOverlayTimeout);
      emailOverlayTimeoutWork = false;
    }
  };

  const handleMouseLeave = (event) => {
    if (!checkTTLExpiration('hide_capture_email_overlay')) {
      const mouseX = event.clientX;
      const mouseY = event.clientY;
      if (
        mouseY >= window.innerHeight
        || mouseX >= window.innerWidth
        || mouseY < 0
        || mouseX < 0
      ) {
        handleEmailOverlay(true);
      } else {
        handleEmailOverlay(false);
      }
    }
  };

  const handleVisibilityChange = () => {
    if (!checkTTLExpiration('hide_capture_email_overlay')) {
      setOpenOverlay(true);
      setLocalStorageKeyeWithTTL(
        'hide_capture_email_overlay',
        1,
        expirationTimeInHours,
      );
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const md = new MobileDetect(window.navigator.userAgent);
      const isDesktop = !md.tablet() && !md.mobile();
      if (isDesktop) {
        document.addEventListener('mouseout', handleMouseLeave);
      } else {
        document.addEventListener('visibilitychange', handleVisibilityChange);
      }

      return () => {
        if (isDesktop) {
          document.removeEventListener('mouseout', handleMouseLeave);
        } else {
          document.removeEventListener('visibilitychange', handleMouseLeave);
        }
      };
    }
  }, []);

  return (
    <CaptureEmailOverlayStyledDialog
      open={openOverlay}
      onClose={handleCloseOverlay}
      className="CEcontainer"
      maxWidth="xl"
      fullScreen={isTablet}>
      <DialogContent>
        <Grid container>
          {!isTablet && (
            <Grid item lg={5}>
              <img
                className="CEimage"
                src="/imgs/captureEmail.jpg"
                alt="Capture Email Dialog"
              />
            </Grid>
          )}

          <Grid item lg={7} sm={12} className="CEcontentContainer">
            <Grid align="right" className="CEcloseBtn">
              <IconButton
                onClick={handleCloseOverlay}
                aria-label="Cancel"
                color="secondary"
                size="small">
                <CloseIcon />
              </IconButton>
            </Grid>

            <Container className="CEcontent">
              <form onSubmit={(e) => handleRecaptchaButtonClick(e)}>
                <Box>
                  <Typography variant="h4" component="p" mb={2}>
                    <span className="CEBoldTitle">
                      {`${t('fe_er_common_capture_email:wait')} `}
                    </span>
                    {t('fe_er_common_capture_email:title')}
                  </Typography>
                  <Typography variant="subtitle2" component="p" mb={2}>
                    {t('fe_er_common_capture_email:add_email')}
                  </Typography>

                  <TextField
                    type="email"
                    required
                    fullWidth
                    label={t('fe_er_common_capture_email:email')}
                    className="CEemailInput"
                    value={email || ''}
                    onChange={(e) => handleEmailChange(e.target.value)}
                  />
                  {envVars.INVISIBLE_RECAPTCHA_SITE_KEY && (
                    <Grid>
                      <ReCAPTCHA
                        className="grecaptcha-badge"
                        ref={recaptchaRef}
                        sitekey={envVars.INVISIBLE_RECAPTCHA_SITE_KEY}
                        size="invisible"
                        onChange={handleSendMeDeals}
                      />
                    </Grid>
                  )}
                </Box>

                <Box mt={2}>
                  <Typography variant="caption" color={theme.palette.grey[300]}>
                    <span>
                      {t('fe_er_common_capture_email:disclaimer_text')}
                    </span>
                    {' '}
                    <NavigatorLink
                      href="/rentals/rental-terms-and-conditions"
                      target="_blank">
                      <span>
                        {t('fe_er_common_capture_email:terms_conditions')}
                      </span>
                    </NavigatorLink>
                    {' '}
                    <span>{t('fe_er_common_capture_email:and')}</span>
                    {' '}
                    <NavigatorLink href="/privacy-policy" target="_blank">
                      <span>
                        {t('fe_er_common_capture_email:privacy_policy')}
                        {' '}
                      </span>
                    </NavigatorLink>
                  </Typography>
                </Box>

                <Box mt={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth>
                    {t('fe_er_common_capture_email:send_me_deals')}
                  </Button>
                </Box>
              </form>
            </Container>
          </Grid>
        </Grid>
      </DialogContent>
    </CaptureEmailOverlayStyledDialog>
  );
};

export { CaptureEmailOverlay };
