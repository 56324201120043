import React from 'react';
import { styled } from '@mui/material/styles';
// Icons
import DotIcon from '@mui/icons-material/FiberManualRecord';

const Root = styled('span')(({ theme }) => ({
  '& .dots': {
    "& [class*='MuiSvgIcon-root']": {
      fontSize: theme.typography.pxToRem(10),
    },
  },
}));

const VisaNumbers = ({ digits }) => {
  const Dots = (
    <span className="dots">
      {[1, 2, 3, 4].map((dot) => (
        <DotIcon key={dot} />
      ))}
    </span>
  );

  return (
    <Root>
      {Dots}
      {digits}
    </Root>
  );
};

export { VisaNumbers };
