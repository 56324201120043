import { Box, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ImgBox } from 'views/common/components';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .RIContent': {
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  '& .RITextInfo': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
    '& .RIBookedOn': {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(1),
      },
    },
  },
  '& .RITourNameLink': {
    color: 'inherit',
    textDecoration: 'underline',
    lineHeight: theme.spacing(4),
  },
  '& .RICopyLink': {
    color: 'inherit',
    fontSize: theme.typography.pxToRem(16),
  },
  '& .RICopyIcon': {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.grey[500],
  },
  '& .RIContinueButton': {
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(1),
    },
  },
  '& .RIRservNum': {
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  '& .RICancelButton': {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(1),
    },
  },
  '& .RIFullWidth': {
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  '& .RIErrorAlert': {
    backgroundColor: alpha(theme.palette.error.main, 0.15),
    '& .MuiAlert-action': {
      padding: theme.spacing(0.5, 1),
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        alignItems: 'end',
      },
    },
  },
  '& .RISecondaryColor': {
    color: theme.palette.secondary.main,
  },
}));
export const StyledImgBox = styled(ImgBox)(({ theme }) => ({
  '&.IBimgbox': {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      '& .IBimg': {
        objectFit: 'cover',
      },
    },
  },
}));
export default StyledBox;
