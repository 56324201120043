import React, { useEffect } from 'react';
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Button,
  TableBody,
  Tooltip,
  IconButton,
  Tabs,
  Tab,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import StyledBox from 'views/common/components/Logical/MembershipOverlaySteps/MembershipOverlayStepOne/MembershipOverlayStepOne.styles';
import Typography500 from 'views/common/components/UI/Typography500';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckIcon from '@mui/icons-material/Check';
import { ImgBox, Typography700 } from 'views/common/components';
import { useTranslation } from 'next-i18next';
import Dinero from 'lib/utils/Dinero';
import moneyFormat from 'lib/utils/moneyFormat';
import { MembershipTableCell } from 'views/common/components/Logical/MembershipOverlaySteps';

const MembershipOverlayStepOne = (props) => {
  const {
    membershipOverlayData,
    overlayState,
    handleOverlayStateChange,
    handleOverlayStepChange,
  } = props;
  const { t } = useTranslation('fe_er_common_membership_overlay');

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  const handleTabClick = (e, value) => {
    const selectedMembership = membershipOverlayData.memberships.find(
      (membership) => membership.id === value,
    );
    handleOverlayStateChange({ selectedMembership });
  };
  useEffect(() => {
    window.gtag('event', 'membership_signup', {
      event_category: 'Membership',
      event_action: 'Pageview',
      event_label: 'View Plans',
      eventSource: window.location.pathname.includes('/extras')
        ? 'Extras Page'
        : 'Search Rentals Page',
    });
  }, []);
  const renderLimitedTimeOffer = (membership, showDesktop) => (
    <>
      {membershipOverlayData.membership_referral_code && (
        <Typography500
          variant="h6"
          component="p"
          className={`${
            showDesktop ? 'MOSOLimitedTime showDESKTOP' : 'MOSOLimitedTime'
          }`}>
          {t('fe_er_common_membership_overlay:limited_time_offer')}
        </Typography500>
      )}
      <Typography
        component="p"
        className={`MOSOPrices ${showDesktop && 'showDESKTOP'}`}>
        <Typography700
          variant="h4"
          component="span"
          className="MOSONewPrice"
          color="error">
          {moneyFormat(Dinero(membership.discount_price))}
        </Typography700>
        <Typography variant="caption" component="span" className="MOSOOldPrice">
          {moneyFormat(Dinero(membershipOverlayData.quote.total))}
        </Typography>
      </Typography>
      {membershipOverlayData.membership_referral_code && (
        <>
          <Typography500
            variant="h6"
            component="p"
            color="error"
            className={`${showDesktop ? 'showDESKTOP' : ''}`}>
            {t('fe_er_common_membership_overlay:with_code')}
            {' '}
            <Typography700 variant="h5" component="span">
              {membershipOverlayData.membership_referral_code.code}
            </Typography700>
          </Typography500>
          <Typography
            variant="caption"
            component="p"
            className={`${
              showDesktop ? 'MOSOSecLight showDESKTOP' : 'MOSOSecLight'
            }`}>
            {t('fe_er_common_membership_overlay:limited_time_offer_note')}
          </Typography>
        </>
      )}
    </>
  );

  const renderInfoTablecell = (info, tooltip, opts = {}) => (
    <TableCell
      component="th"
      scope="row"
      className={
        isTablet && overlayState.selectedMembership.promoted && 'MOSOPromoted'
      }>
      <Box className="MOSOThFlex">
        <Typography
          className={`mosorowheader ${
            opts.strikeThrough && 'MOSOStrikeThrough'
          }`}
          variant="h5"
          component="p"
          dangerouslySetInnerHTML={{
            __html: info,
          }}
        />

        {tooltip && (
          <Tooltip title={tooltip} enterTouchDelay={0}>
            <IconButton className="MOSOHelpIcon">
              <HelpOutlineIcon color="primary" fontSize="small" />
            </IconButton>
          </Tooltip>
        )}

        {opts.icon}
      </Box>

      {opts.rewardBenefit && (
        <Box className="MOSORewardsLogoContainer">
          <ImgBox
            image={{
              src: '/imgs/rewards/RewardProgramLogo.svg',
              alt: t('fe_er_common_membership_overlay:eaglerider_rewards'),
            }}
            imgWidth="100%"
            imgHeight="100%"
          />
        </Box>
      )}
    </TableCell>
  );
  const renderCheckIconTableCell = (membership) => (
    <MembershipTableCell
      key={membership.id}
      membership={membership}
      overlayState={overlayState}>
      <CheckIcon fontSize="small" />
    </MembershipTableCell>
  );

  return (
    <StyledBox>
      <Box className="showTABLET">
        <Tabs
          centered
          value={overlayState.selectedMembership.id}
          onChange={handleTabClick}>
          {membershipOverlayData.memberships.map((membership) => (
            <Tab
              key={membership.id}
              label={membership.name}
              value={membership.id}
            />
          ))}
        </Tabs>
      </Box>

      <TableContainer>
        <Table className="MOSOTable">
          <TableHead>
            <TableRow>
              <TableCell className="CSHiddencell">
                <Typography500
                  variant="h3"
                  component="p"
                  className="MOSOSecMain MOSOUppercase MOSOComparePlan"
                  pt={2}
                  dangerouslySetInnerHTML={{
                    __html: t('fe_er_common_membership_overlay:compare_plans'),
                  }}
                />
              </TableCell>
              {membershipOverlayData.memberships.map((membership) => (
                <TableCell
                  key={membership.id}
                  colSpan={`${isTablet ? '2' : '1'}`}
                  className={`MOSOHeader
                  ${
                    overlayState.selectedMembership.id !== membership.id
                      ? 'CSHiddencell'
                      : ''
                  }
                  ${membership.promoted ? 'MOSOPromoted' : ''}`}>
                  {membership.promoted && (
                    <Typography500
                      variant="h6"
                      component="p"
                      className="MOSOUppercase MOSOBestValue">
                      {t('fe_er_common_membership_overlay:best_value')}
                    </Typography500>
                  )}
                  <Typography500
                    variant="h3"
                    component="h4"
                    className="MOSOUppercase MOSOSecMain showDESKTOP"
                    pb={3}
                    pt={3}>
                    {membership.name}
                  </Typography500>
                  <Typography500
                    variant="h3"
                    component="p"
                    color="primary"
                    className="MOSOPlanPrice"
                    pb={3}
                    dangerouslySetInnerHTML={{
                      __html: t('fe_er_common_membership_overlay:plan_price', {
                        price: moneyFormat(
                          Dinero(membership.monthly_subscription_price),
                        ),
                      }),
                    }}
                  />
                  <Button
                    variant="outlined"
                    color="primary"
                    className="MOSOJoinNow"
                    onClick={() => handleOverlayStepChange(membership)}>
                    {t('fe_er_common_membership_overlay:join_now')}
                  </Button>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              {renderInfoTablecell(
                t(
                  'fe_er_common_membership_overlay:x_rental_credits_per_month',
                  {
                    months_count:
                      membershipOverlayData.promoted_membership_info
                        .term_months,
                    credits_count: '',
                  },
                ),
                t('fe_er_common_membership_overlay:tooltip_x_rental_credit', {
                  credits_count: '',
                }),
              )}
              {membershipOverlayData.memberships.map((membership) => (
                <MembershipTableCell
                  key={membership.id}
                  membership={membership}
                  overlayState={overlayState}>
                  <Typography
                    variant="h5"
                    component="p"
                    dangerouslySetInnerHTML={{
                      __html: t(
                        'fe_er_common_membership_overlay:x_rental_credits',
                        {
                          credits_count: membership.rental_credits_per_month,
                        },
                      ),
                    }}
                  />
                </MembershipTableCell>
              ))}
            </TableRow>

            {membershipOverlayData.user?.is_club_upgrade_eligible && (
              <TableRow>
                {renderInfoTablecell(
                  t('fe_er_common_membership_overlay:extra_credits', {
                    credits: 2,
                  }),
                  null,
                  {
                    rewardBenefit: true,
                    icon: <CardGiftcardIcon color="primary" fontSize="small" />,
                  },
                )}
                {membershipOverlayData.memberships.map((membership) => renderCheckIconTableCell(membership))}
              </TableRow>
            )}

            <TableRow>
              {renderInfoTablecell(
                t('fe_er_common_membership_overlay:initiation_fee', {
                  initiation_fee: moneyFormat(
                    Dinero(
                      membershipOverlayData.promoted_membership_info
                        .initiation_price,
                    ),
                  ),
                }),
                membershipOverlayData.user?.is_club_upgrade_eligible
                  ? null
                  : t('fe_er_common_membership_overlay:tooltip_initiation_fee'),
                membershipOverlayData.user?.is_club_upgrade_eligible && {
                  rewardBenefit: true,
                  icon: <CardGiftcardIcon color="primary" fontSize="small" />,
                  strikeThrough: true,
                },
              )}
              {membershipOverlayData.memberships.map((membership) => renderCheckIconTableCell(membership))}
            </TableRow>

            <TableRow>
              {renderInfoTablecell(
                t('fe_er_common_membership_overlay:rental_discount', {
                  pl_rental_discount: '20%',
                }),
                t('fe_er_common_membership_overlay:tooltip_rental_discount', {
                  pl_rental_discount: '20%',
                }),
              )}
              {membershipOverlayData.memberships.map((membership) => renderCheckIconTableCell(membership))}
            </TableRow>

            <TableRow>
              {renderInfoTablecell(
                t('fe_er_common_membership_overlay:credit_rollover'),
                t(
                  'fe_er_common_membership_overlay:tooltip_x_months_credit_rollover',
                  {
                    months_count: '18',
                  },
                ),
              )}
              {membershipOverlayData.memberships.map((membership) => renderCheckIconTableCell(membership))}
            </TableRow>

            <TableRow>
              {renderInfoTablecell(
                t('fe_er_common_membership_overlay:reservation_price_checkout'),
              )}
              {membershipOverlayData.memberships.map((membership) => (
                <MembershipTableCell
                  key={membership.id}
                  membership={membership}
                  overlayState={overlayState}>
                  {isTablet && (
                    <Typography500 variant="h4" component="p" color="error">
                      {moneyFormat(Dinero(membership.discount_price))}
                    </Typography500>
                  )}

                  {renderLimitedTimeOffer(membership, true)}
                </MembershipTableCell>
              ))}
            </TableRow>

            {membershipOverlayData.membership_referral_code && (
              <TableRow className="MOSOshowTablet">
                {membershipOverlayData.memberships.map((membership) => (
                  <TableCell
                    key={membership.id}
                    colSPan={2}
                    className={`${
                      overlayState.selectedMembership.id !== membership.id
                      && 'CSHiddencell'
                    } ${membership.promoted && 'MOSOPromoted'}`}>
                    {renderLimitedTimeOffer(membership, false)}
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledBox>
  );
};

export { MembershipOverlayStepOne };
