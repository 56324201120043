import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const CustomerExperienceCardSkeleton = () => (
  <>
    <Skeleton variant="rectangular" width="100%" height={230} />
  </>
);

export { CustomerExperienceCardSkeleton };
