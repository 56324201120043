import React, { forwardRef } from 'react';
import {
  Box,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography,
  Avatar,
  Fade,
} from '@mui/material';
import StyledBox from 'views/common/components/UI/Chatbot/Chatbot.styles';
import { useTranslation } from 'next-i18next';
import { Typography700 } from 'views/common/components';
import SendIcon from '@mui/icons-material/Send';
import { useSelector } from 'react-redux';
import { ChatDialogBox } from 'views/common/components/UI/Chatbot/ChatDialogBox/ChatDialogBox';
import { ROLES } from 'views/common/components/Logical/ChatBotWrapper/constants';
import { formatUrlToTag } from 'lib/utils/formatUrlToTag';

const Chatbot = forwardRef((props, ref) => {
  const { t } = useTranslation('fe_er_chatbot_page');
  const {
    chatMessages,
    userInput,
    maxCharLength,
    handleUserInputChange,
    handleSendBtnCLick,
    handleEnterKeyPressed,
    className,
  } = props;
  const appState = useSelector((state) => state.globalData.appState);
  const getAvatarObj = (item) => {
    if (item.role === ROLES.user) {
      if (appState.current_user) {
        if (appState.current_user?.avatar_url) {
          return { avatar: appState.current_user.avatar_url };
        }
        // eslint-disable-next-line camelcase
        const { first_name, last_name } = appState.current_user;
        return {
          // eslint-disable-next-line camelcase
          avatarText: `${first_name[0].toUpperCase()}${last_name[0].toUpperCase()}`,
        };
      }
      return { avatar: '/imgs/default/placeholder_user2.webp' };
    }
    return { avatar: '/imgs/er_icon.svg' };
  };

  return (
    <StyledBox className={className}>
      <Box className="CVHeader">
        <Box className="CDBHeaderAvatarContainer">
          <Avatar
            className="CDBHeaderAvatar"
            alt={t('fe_er_chatbot_page:eagle_talk')}
            src="/imgs/er_icon.svg"
          />
        </Box>
        <Typography700 className="CVHeaderText">
          {t('fe_er_chatbot_page:eagle_talk')}
        </Typography700>
        <Typography className="CVHeaderBetaText" variant="caption">
          {t('fe_er_chatbot_page:beta')}
        </Typography>
      </Box>
      <Box className="CVBody" id="chatBot">
        {chatMessages.map((item, index) => (
          <div id={`message_${index}`} key={item.text}>
            <ChatDialogBox
              chatDialogTitle={item.title}
              chatDialogText={formatUrlToTag(item.text, 'ref=eaglechat')}
              chatDialogLink={item.chatDialogLink}
              chatDialogAction={item.chatDialogAction}
              loading={item.loading}
              dark={item.dark || item.role === ROLES.user}
              {...getAvatarObj(item)}
            />
          </div>
        ))}
      </Box>
      <Box
        className={`${
          (userInput?.length || 0) > maxCharLength && 'CVExceedLimet'
        } CVFooter`}>
        <OutlinedInput
          fullWidth
          inputRef={ref}
          placeholder={t('fe_er_chatbot_page:placeholder')}
          value={userInput}
          disabled={chatMessages[chatMessages.length - 1]?.loading}
          inputProps={{ maxLength: maxCharLength + 1 }}
          onChange={handleUserInputChange}
          onKeyPress={handleEnterKeyPressed}
          endAdornment={(
            <InputAdornment position="start">
              <IconButton
                className="CVFooterSend"
                // FE will handel this condition
                disabled={(userInput?.length || 0) > maxCharLength}>
                <SendIcon onClick={handleSendBtnCLick} />
              </IconButton>
            </InputAdornment>
          )}
        />

        <Fade in={userInput}>
          <Typography
            className={`CBTextLimate ${
              userInput?.length > maxCharLength && 'CBTextLimitExceeded'
            }`}
            component="p">
            {userInput?.length || 0}
            /
            {maxCharLength}
          </Typography>
        </Fade>
      </Box>
    </StyledBox>
  );
});

export { Chatbot };
