import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .MuiListItem-root': {
    paddingLeft: '0',
  },
  '& .BTDname': {
    color: theme.palette.grey[800],
  },
  '& .BTDvalue': {
    color: theme.palette.secondary.dark,
  },
}));
export default StyledBox;
