import React from 'react';
import { Box, Skeleton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledStack = styled(Stack)(({ theme }) => ({
  '& .RISImage': {
    width: theme.spacing(37.5),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  '& .RIAction': {
    width: theme.spacing(17.75),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  '& .RIActionWrapper': {
    [theme.breakpoints.down('md')]: {
      borderTop: `1px solid ${theme.palette.grey[50]}`,
      paddingTop: theme.spacing(2),
    },
  },
}));

const ReservationInformationSkeleton = () => (
  <StyledStack
    spacing={{ sm: 2, md: 3 }}
    direction={{ md: 'row', sm: 'column' }}>
    <Skeleton variant="rectangular" height={200} className="RISImage" />

    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      justifyContent="space-between">
      <Box>
        <Skeleton variant="h4" width={300} />
        <Skeleton variant="caption" width={150} sx={{ mt: 0.5 }} />
      </Box>

      <Box my={2}>
        <Skeleton variant="body1" width={250} sx={{ mb: 1.25 }} />
        <Skeleton variant="body1" width={250} />
      </Box>
      <Box display="flex" className="RIActionWrapper">
        <Skeleton
          variant="rectangular"
          height={36}
          className="RIAction"
          sx={{ mr: 1.25 }}
        />
        <Skeleton className="RIAction" variant="rectangular" height={36} />
      </Box>
    </Box>
  </StyledStack>
);

export { ReservationInformationSkeleton };
