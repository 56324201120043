import React from 'react';
import parseZone from 'lib/utils/parseZone';
import { useTranslation } from 'next-i18next';
import StyledBox from 'views/common/components/UI/ReservationTimeline/ReservationTimeline.styles';
import Typography500 from 'views/common/components/UI/Typography500';
import { Typography } from '@mui/material';
import { mapNamedLocaleToLocale } from 'lib/data/localesMap';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import { TOUR_TYPES } from 'lib/utils/CONSTANTS';

const ReservationTimeline = (props) => {
  const { reservationSummary, pickupComponent, dropoffComponent } = props;
  const { t } = useTranslation();
  const appNavigator = useAppNavigator();

  const renderMotorcyclePickup = (addLine) => (
    <>
      <li className="RTPickList">
        <span className="RTFromToLine" />
        <Typography500 variant="h5" component="p">
          {reservationSummary.tour?.tour_type?.identifier
          === TOUR_TYPES.GUIDED_OFFROAD
            ? t('fe_er_reservation_path_module:pickup')
            : t('fe_er_reservation_path_module:motor_pickup')}
        </Typography500>
        <Typography variant="body2" component="p" className="RTDate">
          {parseZone(reservationSummary.pickup_time)
            ?.locale(mapNamedLocaleToLocale[appNavigator.locale].toLowerCase())
            .format('MMM DD, YYYY @ hh:mm A')}
        </Typography>
        <Typography variant="body2" component="p" className="RTLocation">
          {reservationSummary.pickup_location.display_name}
        </Typography>
        {pickupComponent}
      </li>
      <li className="RTPickList">
        {addLine && reservationSummary.tour.end_time && (
          <span className="RTFromToLine" />
        )}
        <Typography500 variant="h5" component="p">
          {reservationSummary.tour?.tour_type?.identifier
          === TOUR_TYPES.GUIDED_OFFROAD
            ? t('fe_er_reservation_path_module:dropoff')
            : t('fe_er_reservation_path_module:motor_dropoff')}
        </Typography500>
        <Typography variant="body2" component="p" className="RTDate">
          {parseZone(reservationSummary.dropoff_time)
            ?.locale(mapNamedLocaleToLocale[appNavigator.locale].toLowerCase())
            .format('MMM DD, YYYY @ hh:mm A')}
        </Typography>
        <Typography variant="body2" component="p" className="RTLocation">
          {reservationSummary.dropoff_location.display_name}
        </Typography>
        {dropoffComponent}
      </li>
    </>
  );
  const renderTourPickup = () => (
    <>
      {reservationSummary.tour.start_time && (
        <li className="RTPickList RTTourPickList">
          <span className="RTFromToLine" />
          <Typography500 variant="h5" component="p">
            {t('fe_er_reservation_path_module:tour_start_only')}
          </Typography500>
          <Typography variant="body2" component="p">
            {parseZone(reservationSummary.tour.start_time)
              ?.locale(
                mapNamedLocaleToLocale[appNavigator.locale].toLowerCase(),
              )
              .format('MMM DD, YYYY @ hh:mm A')}
          </Typography>
        </li>
      )}
      {renderMotorcyclePickup(true)}
      {reservationSummary.tour.end_time && (
        <li className="RTPickList RTTourPickList">
          <Typography500 variant="h6">
            {t('fe_er_reservation_path_module:tour_end_only')}
          </Typography500>
          <Typography variant="body2" component="p">
            {parseZone(reservationSummary.tour.end_time)
              ?.locale(
                mapNamedLocaleToLocale[appNavigator.locale].toLowerCase(),
              )
              .format('MMM DD, YYYY @ hh:mm A')}
          </Typography>
        </li>
      )}
    </>
  );
  const renderFromTo = () => {
    if (reservationSummary.tour) {
      if (
        reservationSummary.tour.start_time === reservationSummary.pickup_time
        && reservationSummary.tour.end_time === reservationSummary.dropoff_time
      ) {
        return renderMotorcyclePickup();
      }
      return renderTourPickup();
    }
    return renderMotorcyclePickup();
  };
  return (
    <StyledBox>
      <ul className="RTTimeline">{renderFromTo()}</ul>
    </StyledBox>
  );
};

export { ReservationTimeline };
