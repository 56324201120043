import React from 'react';
import { Chip } from '@mui/material';
import StyledBox from 'views/common/components/UI/ResState/ResState.styles';
import { useTranslation } from 'next-i18next';

const ResState = (props) => {
  const { t } = useTranslation('fe_er_common_res_state');
  const { status, className } = props;

  return (
    <StyledBox>
      <Chip
        className={`${className} RSChip`}
        label={t(`fe_er_common_res_state:${status}`)}
        size="small"
        variant="outlined"
      />
    </StyledBox>
  );
};

export { ResState };
