import React from 'react';
import { styled } from '@mui/material/styles';
import {
  IconButton, Grid, Box, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'next-i18next';
import { ImgBox } from 'views/common/components';
import Typography500 from 'views/common/components/UI/Typography500/Typography500';

const StyledBox = styled(Box)(({ theme, bikeName }) => ({
  color: theme.palette.grey[900],
  padding: theme.spacing(1, 1.5, 0, 3),
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    textAlign: 'end',
  },
  '& .dialogTitle': {
    lineHeight: theme.spacing(5),
    borderBottom: '1px solid rgba(0,0,0,0.18)',
    marginBottom: theme.spacing(0),
    fontSize: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(2.5),
    },
  },
  '& .dialogTitleContainer': {
    [theme.breakpoints.down('md')]: {
      textAlign: 'start',
      justifyContent: 'center',
    },
  },
  '& .MWDTIcon': {
    position: 'absolute',
    right: theme.typography.pxToRem(22),
    top: theme.typography.pxToRem(18),
    color: theme.palette.grey[400],
    zIndex: 1,
    backgroundColor: theme.palette.grey[100],
    [theme.breakpoints.down('md')]: {
      top: bikeName ? theme.spacing(1) : theme.spacing(2),
      position: bikeName ? 'absolute' : 'relative',
    },
    '& svg': {
      width: theme.spacing(3),
      height: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),
      },
    },
  },
  '& .bikeName': {
    fontSize: theme.spacing(1.75),
    paddingTop: theme.spacing(0.5),
    lineHeight: theme.spacing(2.625),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(2),
      fontSize: theme.spacing(2.5),
      lineHeight: theme.spacing(3.375),
    },
  },
  '& .MWDTDesc': {
    color: theme.palette.grey[300],
  },
  '& .imageBox-container': {
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      textAlign: 'center',
    },
    '& .imgbox': {
      [theme.breakpoints.down('lg')]: {
        maxWidth: theme.spacing(17.5),
        maxHeight: theme.spacing(10),
      },
    },
    '& img.img': {
      marginLeft: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(6),
      },
    },
  },
}));
const MainWidgetDialogTitle = (props) => {
  const {
    onClose, bikeName, image, bikeDesc,
  } = props;

  const { t } = useTranslation('fe_er_common_main_widget_dialog');
  return (
    <StyledBox className="mainWidgetDialogTitle" bikeName={bikeName}>
      {onClose && (
        <IconButton aria-label="close" onClick={onClose} className="MWDTIcon">
          <CloseIcon />
        </IconButton>
      )}
      {bikeName && (
        <Grid
          container
          columnSpacing={{ lg: 1 }}
          className="dialogTitleContainer">
          <Grid item lg={6} md={8} xs={12}>
            <Typography500 component="p" variant="h3" className="dialogTitle">
              {t('fe_er_common_main_widget_dialog:start_your_adventure')}
            </Typography500>
            <Typography500 component="p" variant="h4" className="bikeName">
              {bikeName}
            </Typography500>
            {bikeDesc && (
              <Typography
                component="p"
                variant="body2"
                className="MWDTDesc"
                mt={1}>
                {bikeDesc}
              </Typography>
            )}
          </Grid>

          {image && (
            <Grid item lg={6} md={4} xs={7} className="imageBox-container">
              <ImgBox
                image={image}
                imgWidth="207px"
                imgObjectFit="contain"
                imgHeight="118px"
              />
            </Grid>
          )}
        </Grid>
      )}
    </StyledBox>
  );
};
export { MainWidgetDialogTitle };
