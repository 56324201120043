import React from 'react';
import { Box, Skeleton } from '@mui/material';

const RefundMethodsSkeleton = () => (
  <Box>
    <Skeleton variant="text" width="200px" height={50} />
    <Box p={1.25}>
      <Skeleton variant="text" width="180px" height={40} />
      <Skeleton variant="text" width="200px" height={30} />
      <Skeleton variant="text" width="180px" height={30} />
    </Box>
    <Box p={1.25}>
      <Skeleton variant="text" width="180px" height={40} />
      <Skeleton variant="text" width="280px" height={30} />
    </Box>
    <Box p={1.25}>
      <Skeleton variant="text" width="180px" height={40} />
      <Skeleton variant="text" width="280px" height={30} />
    </Box>
  </Box>
);

export { RefundMethodsSkeleton };
