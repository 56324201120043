import React from 'react';
import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  '&.MuiSkeleton-root': {
    borderRadius: theme.spacing(1),
    margin: 'auto',
    width: theme.typography.pxToRem(880),
    height: theme.typography.pxToRem(200),
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      height: theme.typography.pxToRem(350),
    },
  },
}));

const OffRoadPromotionBannerSkeleton = () => (
  <StyledSkeleton variant="rectangular" />
);
export { OffRoadPromotionBannerSkeleton };
