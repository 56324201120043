import React from 'react';
import StyledDialogContent from 'views/common/components/Logical/MembershipOverlaySteps/MembershipOverlayStepTwo/MembershipOverlayStepTwo.styles';
import { Grid } from '@mui/material';
import {
  MembershipOverlayAside,
  MembershipOverlayAuthentication,
} from 'views/common/components/Logical/MembershipOverlaySteps';

const MembershipOverlayStepTwo = (props) => {
  const { overlayState, membershipOverlayData, handleOverlayStepChange } = props;

  return (
    <StyledDialogContent>
      <Grid container>
        <Grid item lg={5} md={12} className="MOSTAside MOSTSecPadd" p={3}>
          <MembershipOverlayAside
            overlayState={overlayState}
            membershipOverlayData={membershipOverlayData}
          />
        </Grid>
        <Grid item lg={7} md={12} className="MOSTSecPadd" pt={6}>
          <MembershipOverlayAuthentication
            handleOverlayStepChange={handleOverlayStepChange}
          />
        </Grid>
      </Grid>
    </StyledDialogContent>
  );
};

export { MembershipOverlayStepTwo };
