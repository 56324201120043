import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Typography, Grid, Box } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import Typography500 from 'views/common/components/UI/Typography500';
import SignUpForm from 'views/common/components/Logical/Auth/SignUpForm';
import SignInForm from 'views/common/components/Logical/Auth/SignInForm';
import PATHS from 'lib/navigation/paths';
import useAppNavigator from 'views/common/hooks/useAppNavigator';

const MembershipOverlayAuthentication = (props) => {
  const { handleOverlayStepChange } = props;
  const appNavigator = useAppNavigator();

  const { t } = useTranslation('fe_er_common_membership_overlay');
  const [showSignUpForm, setShowSignUpForm] = useState(true);
  const handleShowSignUpFormChange = (value) => {
    setShowSignUpForm(value);
  };

  return (
    <Box>
      {showSignUpForm ? (
        <>
          <Typography500
            variant="h4"
            component="h4"
            color="secondary"
            pb={1}
            pt={3}>
            {t('fe_er_common_membership_overlay:create_account')}
          </Typography500>
          <Grid
            container
            className="MOSOSecLight"
            flexWrap="nowrap"
            columnSpacing={1}>
            <Grid item>
              <LockIcon className="MOAlock" fontSize="small" />
            </Grid>
            <Grid item>
              <Typography variant="body2" component="p">
                {t(
                  'fe_er_common_membership_overlay:credit_card_secure_form_info',
                )}
              </Typography>
            </Grid>
          </Grid>
          <SignUpForm
            redirectCallback={() => {
              handleOverlayStepChange(null, showSignUpForm);
            }}
            signInLinkAction={() => handleShowSignUpFormChange(false)}
          />
        </>
      ) : (
        <>
          <Typography500 variant="h3" component="p">
            {t('fe_er_auth:sign_in_to_club_now')}
          </Typography500>
          <Typography
            variant="body2"
            component="p"
            color="secondary.light"
            py={1.25}
            dangerouslySetInnerHTML={{
              __html: t('fe_er_auth:already_have_es_acc', {
                ESPath: PATHS.eagleshare('', appNavigator.locale).url,
              }),
            }}
          />

          <SignInForm
            sso
            showSocialLogin={false}
            redirectCallback={() => {
              handleOverlayStepChange(null, showSignUpForm);
            }}
            signUpLinkAction={() => handleShowSignUpFormChange(true)}
          />
        </>
      )}
    </Box>
  );
};

export { MembershipOverlayAuthentication };
