import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import HeadingMD from 'views/common/components/UI/headings/HeadingMD';
import LightBulbHint from 'views/common/components/UI/LightBulbHint';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import dayjs from 'dayjs';
import { FormErrors } from 'views/common/components';
import PATHS from 'lib/navigation/paths';
import { useTranslation } from 'next-i18next';
import { InternationalPhone } from 'views/common/components/Logical/InternationalPhone';

const PREFIX = 'MoreAboutYou';

const classes = {
  secondaryColor: `${PREFIX}-secondaryColor`,
  termsChkbox: `${PREFIX}-termsChkbox`,
  termsChkboxFormControlLabel: `${PREFIX}-termsChkboxFormControlLabel`,
  linkBold: `${PREFIX}-linkBold`,
  numberInput: `${PREFIX}-numberInput`,
  textField: `${PREFIX}-textField`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.secondaryColor}`]: {
    color: theme.palette.secondary.light,
  },

  [`& .${classes.termsChkbox}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.termsChkboxFormControlLabel}`]: {
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
      alignItems: 'start',
      '& .MuiCheckbox-root': {
        paddingTop: theme.spacing(0.25),
      },
    },
  },

  [`& .${classes.linkBold}`]: {
    fontWeight: '700',
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(0.5),
    textTransform: 'uppercase',
  },
  [`& .${classes.textField}`]: {
    backgroundColor: theme.palette.common.white,
    '& .MuiFilledInput-root': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.grey[200],
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: theme.spacing(0),
    },
  },

  [`& .${classes.numberInput}`]: {
    backgroundColor: theme.palette.common.white,
    '& .MuiFilledInput-root': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.grey[200],
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: theme.spacing(0),
    },
    '& input::-webkit-outer-spin-button ,& input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));

const MoreAboutYou = (props) => {
  const {
    data, handleUserInputChange, userErrors, isGift,
  } = props;
  const { t } = useTranslation('fe_er_common_more_about_you');
  return (
    <Root>
      <Box mb={2}>
        <HeadingMD
          className={classes.secondaryColor}
          title={
            isGift
              ? t('fe_er_common_more_about_you:more_about_recipient')
              : t('fe_er_common_more_about_you:more_about_you')
          }
        />
      </Box>

      <LightBulbHint>
        <Typography
          component="span"
          dangerouslySetInnerHTML={{
            __html: t('fe_er_common_more_about_you:must_be_18', {
              er_term_path:
                'https://www.eaglerider.com/rentals/rental-terms-and-conditions',
              license_requirements_path:
                'https://www.eaglerider.com/motorcycle-rental-license-requirements',
            }),
          }}
        />
      </LightBulbHint>

      <Box mt={2} mb={2}>
        <RadioGroup
          row
          name="gender"
          value={data.gender || ''}
          onChange={(e) => handleUserInputChange(e.target.name, e.target.value)}>
          <FormControlLabel
            value="male"
            control={<Radio color="primary" />}
            label={t('fe_er_common_more_about_you:male')}
          />
          <FormControlLabel
            value="female"
            control={<Radio color="primary" />}
            label={t('fe_er_common_more_about_you:female')}
          />
        </RadioGroup>

        {userErrors?.errors && (
          <FormErrors errorMsgs={userErrors} errorField="gender_id" />
        )}
      </Box>

      <Grid container spacing={3}>
        <Grid item sm={12} md={6}>
          <DesktopDatePicker
            disableToolbar
            required
            fullWidth
            color="secondary"
            format="MM/DD/YYYY"
            maxDate={dayjs(new Date())}
            id="date_of_birth"
            placeholder="mm/dd/yyyy"
            value={dayjs(data.date_of_birth || null)}
            onChange={(date) => handleUserInputChange(
              'date_of_birth',
              dayjs(date).format('YYYY-MM-DD'),
            )}
            closeOnSelect
            label={t('fe_er_common_more_about_you:date_of_birth')}
            slotProps={{
              textField: {
                fullWidth: true,
                className: classes.textField,
                variant: 'filled',
                error: !!userErrors?.errors?.date_of_birth,
              },
            }}
          />
          {userErrors?.errors && (
            <FormErrors errorMsgs={userErrors} errorField="date_of_birth" />
          )}
        </Grid>

        <Grid item sm={12} md={6}>
          <InternationalPhone
            id="phone_number"
            fullWidth
            required
            color="secondary"
            type="number"
            variant="filled"
            label={t('fe_er_common_more_about_you:mobile_num')}
            name="phone_number"
            autoComplete="new-password"
            className={classes.numberInput}
            value={data.phone_number || ''}
            handleChange={(val) => {
              handleUserInputChange('phone_number', val.value);
              handleUserInputChange('full_phone_number', val.number);
            }}
            error={userErrors?.errors?.phone_number}
            helperText={userErrors?.errors?.phone_number}
          />
        </Grid>
      </Grid>

      <Box className={classes.termsChkbox}>
        <FormControlLabel
          className={classes.termsChkboxFormControlLabel}
          control={(
            <Checkbox
              color="secondary"
              checked={data.agreedToTerms}
              onChange={(e) => handleUserInputChange('agreedToTerms', e.target.checked)}
            />
          )}
          label={(
            <>
              {t('fe_er_common_more_about_you:i_have_read')}
              <NavigatorLink
                target="_blank"
                href={PATHS.membership.termsAndConditions()}
                className={classes.linkBold}>
                {t('fe_er_common_more_about_you:terms_conditions')}
              </NavigatorLink>
            </>
          )}
        />
      </Box>
    </Root>
  );
};

export default MoreAboutYou;
