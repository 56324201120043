import React from 'react';
import { TableCell } from '@mui/material';

const MembershipTableCell = (props) => {
  const { membership, children, overlayState } = props;
  return (
    <TableCell
      className={`MOSORelative
      ${
        overlayState.selectedMembership.id !== membership.id
          ? 'CSHiddencell'
          : ''
      }
      ${membership.promoted ? 'MOSOPromoted' : ''}`}>
      {children}
    </TableCell>
  );
};

export { MembershipTableCell };
