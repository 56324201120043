import { styled, keyframes } from '@mui/material/styles';

const dotBefore = keyframes`
0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
`;

const dot = keyframes`
0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
`;
const dotAfter = keyframes`
0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
`;

const LoadingDots = styled('div')(({ theme }) => ({
  position: 'relative',
  left: '-9999px',
  width: '8px',
  height: '8px',
  borderRadius: '5px',
  backgroundColor: theme.palette.secondary.dark,
  color: theme.palette.secondary.dark,
  boxShadow: '9999px 0 0 -5px',
  animation: `${dot} 1.5s infinite linear`,
  animationDelay: '0.25s',
  '&:before, &:after ': {
    content: "''",
    display: ' inline-block',
    position: 'absolute',
    top: 0,
    width: '8px',
    height: '8px',
    borderRadius: '5px',
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.dark,
  },
  '&:before': {
    boxShadow: ' 9984px 0 0 -5px',
    animation: `${dotBefore} 1.5s infinite linear`,
    animationDelay: '0s',
  },
  '&:after': {
    boxShadow: '10014px 0 0 -5px',
    animation: `${dotAfter} 1.5s infinite linear`,
    animationDelay: '0.5s',
  },
}));

export { LoadingDots };
