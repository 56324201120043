import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Grid, Autocomplete, TextField, Typography,
} from '@mui/material';
import StyledBox, {
  StyledCarousel,
  StyledImgBox,
} from 'views/common/components/UI/MotorcycleRentals/MotorcycleRentals.styles';
import Typography500 from 'views/common/components/UI/Typography500/Typography500';
import { useSelector } from 'react-redux';
import { MakeCarousel } from 'views/common/components/UI/MotorcycleRentals/MakeCarousel';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { useTranslation } from 'next-i18next';
import Dinero from 'lib/utils/Dinero';
import moneyFormat from 'lib/utils/moneyFormat';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1300 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1300, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1023, min: 600 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 599, min: 0 },
    items: 1,
  },
};

const MotorcycleRentals = (props) => {
  const {
    selectedLocation,
    handleLocationChange,
    vehicleMakes = [],
    participatingLocations = [],
    vehicleModels = [],
  } = props;
  const { t } = useTranslation('fe_er_common_motorcycle_rentals');
  const { isCrawler, deviceType } = useSelector((state) => state.globalData);

  const [selectedVehicleMakeID, setSelectedVehicleMakeID] = useState(
    vehicleMakes[0].id,
  );
  const [mountCarousel, setMountCarousel] = useState({
    makes: true,
    models: true,
  });

  const handleVehicleMakeChange = (id) => {
    setSelectedVehicleMakeID(id);
    // we do this to reset the postion of model Carousel when changing the vehicle make
    setMountCarousel({
      makes: true,
      models: false,
    });
    setTimeout(
      () => setMountCarousel({
        makes: true,
        models: true,
      }),
      0,
    );
  };

  const handleLocationFieldChange = async (e, value) => {
    await handleLocationChange({ id: value?.id });
    setMountCarousel({
      makes: false,
      models: false,
    });
    setTimeout(
      () => setMountCarousel({
        makes: true,
        models: true,
      }),
      0,
    );
  };

  const selectedVehicleModels = useMemo(
    () => vehicleModels.filter(
      (vehicleModel) => vehicleModel.vehicle_make.id === selectedVehicleMakeID,
    ) || [],
    [selectedVehicleMakeID, vehicleMakes],
  );

  useEffect(() => {
    setSelectedVehicleMakeID(vehicleMakes[0].id);
  }, [vehicleMakes]);

  return (
    <>
      <StyledBox>
        <Box mb={3}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
              {mountCarousel.makes ? (
                <MakeCarousel
                  selectedVehicleMakeID={selectedVehicleMakeID}
                  vehicleMakes={vehicleMakes}
                  handleVehicleMakeChange={handleVehicleMakeChange}
                />
              ) : (
                <Box height={116} />
              )}
            </Grid>
            <Grid item sm={12} md={6} className="MRGridRightMd">
              <Box className="MRLocationAutocompleteWrapper">
                <Autocomplete
                  disablePortal
                  disableClearable
                  ssr={isCrawler}
                  deviceType={deviceType}
                  className="MRLocationAutocomplete"
                  options={participatingLocations}
                  value={participatingLocations.find(
                    (location) => location.id === selectedLocation?.id,
                  )}
                  blurOnSelect="touch"
                  onChange={handleLocationFieldChange}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t(
                        'fe_er_common_motorcycle_rentals:select_location',
                      )}
                    />
                  )}
                />
                <LocationOnIcon className="MRLocationIcon" />
              </Box>
            </Grid>
          </Grid>
        </Box>

        {mountCarousel.models ? (
          <StyledCarousel
            swipeable
            draggable={false}
            ssr={isCrawler}
            deviceType={deviceType}
            keyBoardControl
            autoPlay={false}
            shouldResetAutoplay={false}
            containerClass="carousel-container"
            customTransition="transform 200ms ease-in"
            responsive={responsive}>
            {selectedVehicleModels.map((item) => (
              <Box height="100%" key={item.id}>
                <StyledImgBox
                  imgWidth="auto"
                  imgHeight="11.25rem"
                  image={{
                    src: item.image.medium_url,
                    alt: t(
                      'fe_er_common_motorcycle_rentals:moto_rental_image_alt',
                      {
                        vmModel: item.name,
                      },
                    ),
                  }}
                  imgObjectFit="contain"
                />
                <Box className="MRCarouselTextWrapper">
                  <Typography variant="body2" className="MRDisText">
                    {item.class_name}
                  </Typography>
                  <Typography500
                    variant="body1"
                    component="h5"
                    className="MRRoadText">
                    {item.name}
                  </Typography500>
                  <Box className="MRPriceBox">
                    <Typography500
                      variant="body2"
                      component="h5"
                      className="MRPriceText">
                      {t(
                        'fe_er_common_motorcycle_rentals:number_of_credit_per_day',
                        { numOFCredit: item.credits_num },
                      )}
                    </Typography500>
                    <Typography variant="body2" className="MRDisText">
                      {t('fe_er_common_motorcycle_rentals:price_per_day', {
                        price: moneyFormat(Dinero(item.daily_rate)),
                      })}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </StyledCarousel>
        ) : (
          <Box height={{ lg: 214, md: 214, sm: 178 }} />
        )}
      </StyledBox>
    </>
  );
};
export { MotorcycleRentals };
