import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Location = () => (
  <SvgIcon
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 0.191406C3.0975 0.191406 0.75 2.53891 0.75 5.44141C0.75 9.37891 6 15.1914 6 15.1914C6 15.1914 11.25 9.37891 11.25 5.44141C11.25 2.53891 8.9025 0.191406 6 0.191406ZM6 7.31641C4.965 7.31641 4.125 6.47641 4.125 5.44141C4.125 4.40641 4.965 3.56641 6 3.56641C7.035 3.56641 7.875 4.40641 7.875 5.44141C7.875 6.47641 7.035 7.31641 6 7.31641Z"
      fill="#5D5D5D"
    />
  </SvgIcon>
);

export default Location;
