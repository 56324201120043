import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import {
  Grid, Box, useTheme, useMediaQuery,
} from '@mui/material';
import { BikeCardSkeletonV3 } from 'views/common/components';
import { styled } from '@mui/material/styles';
import { MakeCarouselSkeleton } from 'views/common/components/UI/MotorcycleRentals/MakeCarousel/MakeCarouselSkeleton';

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[400]}`,
  '& .MRGridRightMd': {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  },
  '& .footer': {
    '& .MuiSkeleton-root': {
      margin: '0 auto',
    },
  },
}));

const MotorcycleRentalsSkeleton = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const getnumber = () => {
    let n;
    if (isTablet && !isMobile) {
      n = 2;
    } else if (isTablet && isMobile) {
      n = 1;
    } else {
      n = 4;
    }
    return n;
  };
  return (
    <StyledBox>
      <Box mb={3}>
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <MakeCarouselSkeleton />
          </Grid>
          <Grid item sm={12} md={6} className="MRGridRightMd">
            <Skeleton variant="rectangular" height={56} width={260} />
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2}>
        {[...Array(getnumber())].map((item) => (
          <Grid item xs={12} md={6} lg={3} key={item}>
            <BikeCardSkeletonV3 />
          </Grid>
        ))}
      </Grid>
    </StyledBox>
  );
};

export { MotorcycleRentalsSkeleton };
