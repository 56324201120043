import dayjs from 'dayjs';
import Dinero from 'lib/utils/Dinero';

export const prepareMembershipCheckoutBody = (
  appState,
  membershipPaymentData,
  membershipOverlayData,
  overlayState,
  formData,
  referralCode,
) => ({
  authenticity_token: appState.authenticity_token,
  credit_card_id: formData.selectedCardId,
  credit_card: {
    user_id: appState.current_user.id,
    name: formData.creditCardFormData.name,
    number: formData.creditCardFormData.number,
    expiration: {
      month:
        (formData.creditCardFormData?.expiration_date
          && `${
            dayjs(formData.creditCardFormData.expiration_date).month() + 1
          }`)
        || null,
      year:
        (formData.creditCardFormData.expiration_date
          && `${dayjs(formData.creditCardFormData.expiration_date).year()}`)
        || null,
    },
    cvv: formData.creditCardFormData.verification_value,
    billing_address: {
      name: formData.creditCardFormData.billing_address.name,
      country_id: formData.creditCardFormData.billing_address.country?.id,
      address: formData.creditCardFormData.billing_address.address,
      address_2: formData.creditCardFormData.billing_address.address_2,
      city: formData.creditCardFormData.billing_address.city,
      region_id: formData.creditCardFormData.billing_address.region?.id,
      postal_code: formData.creditCardFormData.billing_address.postal_code,
      first_name: formData.creditCardFormData.billing_address.first_name,
      last_name: formData.creditCardFormData.billing_address.last_name,
      email: formData.creditCardFormData.billing_address.email,
    },
  },
  user: {
    gender: formData.moreAboutYouData.gender,
    date_of_birth: formData.moreAboutYouData.date_of_birth,
    phone_number:
      formData.moreAboutYouData.full_phone_number
      || formData.moreAboutYouData.phone_number,
  },
  referral_code: referralCode,
  payment_frequency: 'monthly',
  user_id: appState.current_user.id,
  giftable: false,
  amount: membershipPaymentData.user.is_club_upgrade_eligible
    ? overlayState.selectedMembership.monthly_subscription_price.amount
    : Dinero(overlayState.selectedMembership.monthly_subscription_price)
      .add(
        Dinero(
          membershipOverlayData.promoted_membership_info.initiation_price,
        ),
      )
      .getAmount(),
  gifted: false,
  'g-recaptcha-response': formData.moreAboutYouData['g-recaptcha-response'],
});
