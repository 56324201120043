import React from 'react';
import {
  MenuItem, Select, InputLabel, FormControl,
} from '@mui/material';

const OccurrenceMenu = (props) => {
  const {
    occurrences,
    handleOccurenceChange,
    occurrenceId,
    label,
    getOccurrenceName,
  } = props;
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
      <Select
        value={occurrenceId || occurrences[0].id}
        id="tour_date"
        name="tour_date"
        variant="outlined"
        onChange={(e) => handleOccurenceChange(e)}
        fullWidth
        label={label}>
        {occurrences.map((occurrence) => (
          <MenuItem value={occurrence.id} key={occurrence.id}>
            {getOccurrenceName(occurrence)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export { OccurrenceMenu };
