import React from 'react';
import { Grid, Skeleton, Box } from '@mui/material';

const TabsBarSkeleton = () => (
  <Box my={2}>
    <Grid container>
      {[...Array(4)].map(() => (
        <Grid
          item
          lg={1}
          md={2}
          xs={3}
          sx={{ borderBottom: '1px solid silver' }}>
          <Skeleton variant="text" height={24} sx={{ mr: 1 }} />
        </Grid>
      ))}
    </Grid>
  </Box>
);
export { TabsBarSkeleton };
