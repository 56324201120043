import React from 'react';
import { Skeleton, Box } from '@mui/material';

const MembershipFAQSkeleton = () => (
  <>
    <Skeleton className="MFAQSTitle" variant="text" width="60%" height={38} />
    {[...Array(6).keys()].map((item) => (
      <Box
        key={item}
        mt={2}
        sx={{
          borderBottom: '1px solid #3A3A3A',
          paddingBottom: 1,
        }}>
        <Skeleton variant="text" width="40%" height={24} />
        <Skeleton variant="text" width="100%" height={12} />
        <Skeleton variant="text" width="50%" height={12} />
      </Box>
    ))}
  </>
);

export { MembershipFAQSkeleton };
