import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, Button, Divider, Typography,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const StyledBox = styled(Box)(({ theme }) => ({
  '&.mdr-pricesContainer': {
    overflowX: 'hidden',
    minHeight: theme.spacing(15.375),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  '&.mdr-scrollRight': {
    '& .mdr-moreDiscountWrapper': {
      transform: 'translateX(-100%)',
      transition: 'transform 300ms cubic-bezier(0.4, 0.0, 0.2, 1)',
    },
    '& .mdr-arrIcn': {
      transform: 'rotate(180deg)',
    },
  },
  '& .mdr-moreRates': {
    position: 'relative',
    zIndex: 2,
    color: theme.palette.grey['400'],
    lineHeight: theme.spacing(2),
    display: 'flex',
    marginLeft: 'auto',
    padding: 0,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
    fontSize: theme.spacing(1.5),
    '& svg': {
      marginLeft: theme.spacing(1),
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  '& .mdr-moreDiscountWrapper': {
    display: 'flex',
    alignItems: 'stretch',
    padding: theme.spacing(1, 1, 1, 0), // paddingRight 1 if dispayed cards are 4, and 4 if displayed cards are 3
    marginTop: theme.spacing(1),
    flexFlow: 'row nowrap',
    transform: 'translateX(0)',
    textAlign: 'center',
    transition: 'transform 300ms cubic-bezier(0.4, 0.0, 0.2, 1)',
  },
  '& .mdr-divider': {
    backgroundColor: theme.palette.grey[100],
  },
  '& .mdr-info': {
    position: 'relative',
    paddingRight: theme.spacing(1),
    marginRight: theme.spacing(1),
    flexFlow: 'column',
    flex: '1 1 32%',
    flexBasis: '32%',
    maxWidth: '7.5rem',
    minWidth: '32%',
    '&::after': {
      content: '""',
      position: 'absolute',
      backgroundColor: theme.palette.grey['100'],
      width: theme.spacing(0.125),
      height: theme.spacing(4.5),
      transform: 'translateY(-50%)',
      top: '50%',
      right: 0,
    },
    '&:last-child::after': {
      height: 0,
      width: 0,
    },
    '&:nth-child(3)::after': {
      height: 0,
      width: 0,
    },
  },
  '& .mdr-price': {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '500',
    color: theme.palette.secondary.dark,
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  '& .mdr-smallText': {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: '400',
    lineHeight: theme.spacing(2),
  },
  '& .mdr-grey300': {
    color: theme.palette.grey['300'],
  },
  '& .mdr-per': {
    fontSize: theme.spacing(1.75),
    lineHeight: theme.spacing(2),
  },
}));

const MoreDiscountRates = (props) => {
  const { discountsArr } = props;
  const { t } = useTranslation('fe_er_common_rental_card_horizontal_variant');
  const [scrollDiscountToggle, setScrollDiscountToggle] = useState(false);

  return (
    <StyledBox
      className={`mdr-pricesContainer ${
        scrollDiscountToggle ? 'mdr-scrollRight' : ''
      }`}>
      {discountsArr.length > 3 && (
        <>
          <Button
            color="secondary"
            className="mdr-moreRates"
            onClick={() => setScrollDiscountToggle(!scrollDiscountToggle)}>
            {t('fe_er_common_rental_card_horizontal_variant:more_disc_rates')}
            <ChevronRightIcon className="mdr-arrIcn" />
          </Button>
          <Divider className="mdr-divider" />
        </>
      )}
      {discountsArr.length > 0 && (
        <div className="mdr-moreDiscountWrapper">
          {discountsArr.map((discount, index) => (
            <div className="mdr-info" key={discount.rate}>
              <div>
                <Typography variant="h5" component="span" className="mdr-price">
                  {discount.rate}
                </Typography>
                <Typography component="span" className="mdr-per mdr-grey300">
                  {t('fe_er_common_rental_card_horizontal_variant:per_day')}
                </Typography>
              </div>
              {discount.charge_modifier && (
                <Typography className="mdr-smallText mdr-grey300">
                  {`${discount.start_day}${
                    // eslint-disable-next-line no-nested-ternary
                    discount.end_day
                      ? `-${discount.end_day}`
                      : index === 0
                        ? ''
                        : '+'
                  } `}
                  {t('fe_er_common_rental_card_horizontal_variant:days')}
                </Typography>
              )}
            </div>
          ))}
        </div>
      )}
    </StyledBox>
  );
};

export { MoreDiscountRates };
