import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import StyledBox from 'views/common/components/UI/RatingProgress/RatingProgress.styles';
import { Typography700 } from 'views/common/components';

const RatingProgress = (props) => {
  const { ratingProgressLabel, value } = props;

  return (
    <StyledBox>
      <Box className="RPRatingProgress">
        <Box component="span" className="RPOutline" />
        <CircularProgress variant="determinate" value={value} />
        <Box className="RPLabel">
          <Typography700 component="div" color="text.secondary">
            {`${Math.round(value)}%`}
          </Typography700>
        </Box>
      </Box>
      {ratingProgressLabel && (
        <Typography700 className="RProgressLabel" component="p">
          {ratingProgressLabel}
        </Typography700>
      )}
    </StyledBox>
  );
};

export { RatingProgress };
