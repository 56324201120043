import React from 'react';
import {
  Typography, List, ListItem, Grid,
} from '@mui/material';
import StyledBox from 'views/common/components/UI/BikeTechnicalDetails/BikeTechnicalDetails.styles';

const BikeTechnicalDetails = (props) => {
  const { data } = props;
  return (
    <StyledBox id="BikeTechnicalDetails" data-id="section">
      <List>
        {data.map((item) => (
          <ListItem key={item.name} divider>
            <Grid container>
              <Grid item xs={6}>
                <Typography component="p" variant="body2" className="BTDname">
                  {item.name}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography component="p" variant="body2" className="BTDvalue">
                  {item.value}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
    </StyledBox>
  );
};

export { BikeTechnicalDetails };
