import React from 'react';
import { Button, Box, TextField } from '@mui/material';
import StyledBox from 'views/common/components/Logical/MembershipOverlaySteps/ReferralCode/ReferralCode.styles';
import { useTranslation } from 'next-i18next';
import Typography500 from 'views/common/components/UI/Typography500';

const ReferralCode = (props) => {
  const {
    code,
    handleApplyReferralCode,
    handleMembershipReferralCodeChange,
    disabled,
  } = props;
  const { t } = useTranslation('fe_er_common_referral_code');

  return (
    <StyledBox>
      <Typography500 variant="h4" className="RCTitle">
        {t('fe_er_common_membership_overlay:referral_code')}
      </Typography500>

      <Box
        className="RCWrapper"
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="start">
        <TextField
          className="RCTextField"
          value={code}
          size="small"
          color="info"
          disabled={disabled}
          onChange={handleMembershipReferralCodeChange}
          focused
        />

        <Button
          className="RCButton"
          variant="contained"
          color="info"
          onClick={handleApplyReferralCode}>
          {t('fe_er_common_membership_overlay:apply')}
        </Button>
      </Box>
    </StyledBox>
  );
};

export { ReferralCode };
