import React, {
  useMemo, useState, useRef, useEffect,
} from 'react';
import { Chatbot } from 'views/common/components/UI/Chatbot/Chatbot';
import { API } from 'lib/config/axios';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';
import { ROLES } from 'views/common/components/Logical/ChatBotWrapper/constants';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import { scrollToChatBottom } from 'views/common/components/Logical/ChatBotWrapper/utils/scrollToChatBottom';
import dayjs from 'dayjs';
import omit from 'lib/utils/omit';

const { envVars } = require('next/config').default().publicRuntimeConfig;

// const AI_CHAT_HISTORY = 'ai_chat_history';
const DEBUGGING_DELIMETER = ';;;;';

let timeoutId;
const ChatBotWrapper = (props) => {
  const { className } = props;
  const appState = useSelector((state) => state.globalData.appState);
  const handleError = useErrorsHandler();
  const outlinedInputRef = useRef();
  const { t } = useTranslation('fe_er_chatbot_page');

  const [userInput, setUserInput] = useState();
  const welcomeMessage = useMemo(
    () => ({
      text: t('fe_er_chatbot_page:welcome_msg'),
      role: ROLES.agent,
    }),
    [],
  );

  const [chatResponses, setChatResponses] = useState([welcomeMessage]);

  const getReqBody = (chatConversation) => {
    const copeiedChatResponses = chatConversation || [...chatResponses];

    copeiedChatResponses.shift();
    !chatConversation
      && copeiedChatResponses.push({ role: ROLES.user, text: userInput });

    return {
      authenticity_token: appState.authenticity_token,
      messages: copeiedChatResponses,
      rider_ai_options: window.rider_ai_options,
      client_date: dayjs(),
    };
  };

  const stripQuotes = (str) => {
    let newStr = str;
    if (newStr.startsWith("'") || newStr.startsWith('"')) {
      newStr = newStr.slice(1);
    }
    if (newStr.endsWith("'") || newStr.endsWith('"')) {
      newStr = newStr.slice(0, newStr.length - 1);
    }
    return newStr;
  };
  const handleSendBtnCLick = async (chatConversation) => {
    timeoutId && clearTimeout(timeoutId);
    if (userInput?.trim()) {
      window.gtag('event', 'asked_chat_question', {
        question: userInput,
      });

      const reqBody = getReqBody(chatConversation);

      setChatResponses([
        {
          text: t('fe_er_chatbot_page:welcome_msg'),
          role: ROLES.agent,
        },
        ...reqBody.messages,
        { loading: true },
      ]);
      setUserInput('');

      fetch(`${envVars.CHATBOT_API_URL}${API.chats}`, {
        method: 'POST',
        body: JSON.stringify(reqBody),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(async (response) => {
          if (response.ok) {
            const reader = response.body
              // eslint-disable-next-line no-undef
              .pipeThrough(new TextDecoderStream())
              .getReader();
            let debugRes = '';
            let isDebugRes = true;
            let aiResText = '';
            // eslint-disable-next-line no-constant-condition
            while (true) {
              // eslint-disable-next-line no-await-in-loop
              const { value, done } = await reader.read();
              if (done) {
                break;
              }
              // in the future for debug mode
              try {
                if (value.includes(DEBUGGING_DELIMETER)) {
                  debugRes += value.split(DEBUGGING_DELIMETER)[0];
                  aiResText += value.split(DEBUGGING_DELIMETER)[1];
                  console.log(debugRes);
                  // const debugResObj = JSON.parse(debugRes);
                  // const newChatResponse = [
                  //   welcomeMessage,
                  //   ...debugResObj.conversation,
                  // ];
                  // newChatResponse[newChatResponse.length - 1] = {
                  //   ...newChatResponse[newChatResponse.length - 1],
                  //   text: '',
                  //   loading: true,
                  // };
                  // setChatResponses(newChatResponse);
                  isDebugRes = false;
                }
                if (isDebugRes) {
                  debugRes += value;
                } else {
                  !value.includes(DEBUGGING_DELIMETER)
                    && (aiResText += stripQuotes(value));

                  // eslint-disable-next-line no-loop-func
                  setChatResponses((prevData) => {
                    const newChat = [...prevData];
                    newChat[newChat.length - 1] = {
                      ...newChat[newChat.length - 1],
                      role: ROLES.agent,
                      text: aiResText,
                    };
                    return newChat;
                  });
                }
              } catch (err) {
                handleError(err);
              }
            }
            setChatResponses((prevData) => {
              const newChat = [...prevData];
              newChat[newChat.length - 1] = omit(newChat[newChat.length - 1], [
                'loading',
              ]);
              return newChat;
            });
          } else {
            throw response;
          }
        })
        .catch(async (err) => {
          const errorBody = await err.json();
          const statusCode = err.status;
          const timeout = dayjs(errorBody.details.next_service_time).diff(dayjs()) + 100;
          switch (statusCode) {
            case 429:
              timeoutId = setTimeout(() => {
                handleSendBtnCLick([
                  {
                    text: t('fe_er_chatbot_page:welcome_msg'),
                    role: ROLES.agent,
                  },
                  ...reqBody.messages,
                ]);
              }, timeout);
              break;

            default:
              // eslint-disable-next-line no-case-declarations
              const copeiedChatResponses = [...chatResponses];
              copeiedChatResponses.push({ role: ROLES.user, text: userInput });
              setChatResponses(copeiedChatResponses);
              handleError(err);

              break;
          }

          // chatResponses not updated in the promise so we don't need to remove it in the catch
        })
        .finally(() => {});
    }
  };

  const handleEnterKeyPressed = (e) => {
    if (e.key === 'Enter') {
      handleSendBtnCLick();
    }
  };

  const handleUserInputChange = (e) => {
    setUserInput(e.target.value);
  };

  useEffect(() => {
    scrollToChatBottom();
  }, [chatResponses]);
  useEffect(() => {
    window.rider_ai_options = JSON.parse(localStorage.getItem('rider_ai_options')) || {};
    const riderAiOptionsProxy = new Proxy(window.rider_ai_options, {
      set(target, prop, newValue, receiver) {
        localStorage.setItem(
          'rider_ai_options',
          JSON.stringify({ ...receiver, [prop]: newValue }),
        );
        // eslint-disable-next-line prefer-rest-params
        Reflect.set(...arguments);
      },
    });
    window.rider_ai_options = riderAiOptionsProxy;
  }, []);
  /**
   * on landing chech session storage for chat history
   * if there's ai_chat_history key update the state
   * if it's not this means there's no chat and it's a first landing/ tab and you should remove the localstogate
   * add the storage listener
   * the storage listere updates the session storage  with the new chat changes and updates the chat state
   * after the be response you set the localStorage and the session storage  with the new data
   *     we update the localstorage to fire the event and update the session storage in the other tabs
   *     we update the sessionstorage incase the user opens another tab
   *     we don't just the localstorage because then we can't distinguish between the sessions this way
   *          that's why we need session storage cuz it's stays persistent on opening a new tab and removed when closing all tabs
   */
  // useEffect(() => {
  //   // const aiChatHistory = sessionStorage.getItem(AI_CHAT_HISTORY);
  //   // if (aiChatHistory && JSON.parse(aiChatHistory)) {
  //   //   setChatResponses(JSON.parse(aiChatHistory));
  //   // } else {
  //   //   localStorage.removeItem(AI_CHAT_HISTORY);
  //   // }

  //   // window.addEventListener('storage', (e) => {
  //   //   if (e.key === AI_CHAT_HISTORY) {
  //   //     sessionStorage.setItem(AI_CHAT_HISTORY, e.newValue);
  //   //     setChatResponses(JSON.parse(e.newValue));
  //   //   }
  //   // });
  // }, []);
  return (
    <Chatbot
      className={className}
      chatMessages={chatResponses}
      userInput={userInput}
      ref={outlinedInputRef}
      maxCharLength={appState.settings?.rider_ai_max_chat_message_length}
      handleUserInputChange={handleUserInputChange}
      handleSendBtnCLick={() => handleSendBtnCLick()}
      handleEnterKeyPressed={handleEnterKeyPressed}
    />
  );
};

export { ChatBotWrapper };
