import { styled } from '@mui/material/styles';
import DatePicker from 'views/common/components/UI/DatePicker';

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  '& .MuiPickersDay-root': {
    fontSize: theme.typography.pxToRem(14),
  },
  '& .MuiTypography-caption': {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
  },
}));
export default StyledDatePicker;
