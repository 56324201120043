import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledTypography = styled(Typography)(() => ({
  fontWeight: '500',
}));

const HeadingMD = (props) => {
  const { title, className } = props;

  return (
    <StyledTypography variant="h4" className={className}>
      {title}
    </StyledTypography>
  );
};

export default HeadingMD;
