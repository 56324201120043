import React from 'react';
import {
  DialogContent,
  Grid,
  useMediaQuery,
  useTheme,
  IconButton,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import StyledDialogWithImageDialog from 'views/common/components/UI/DialogWithImage/DialogWithImage.styles';

const DialogWithImage = (props) => {
  const {
    openOverlay, handleCloseOverlay, image, children,
  } = props;

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <StyledDialogWithImageDialog
      open={openOverlay}
      onClose={handleCloseOverlay}
      className="DWdialog"
      maxWidth="lg"
      fullScreen={isTablet}>
      <DialogContent>
        <Grid container>
          {!isTablet && (
            <Grid item lg={5}>
              <img className="DWimage" src={image.src} alt={image.alt} />
            </Grid>
          )}

          <Grid item lg={7} sm={12} className="DWcontentContainer">
            <Box className="DWcloseBtn">
              <IconButton
                onClick={handleCloseOverlay}
                aria-label="Cancel"
                color="secondary"
                size="small">
                <CloseIcon />
              </IconButton>
            </Box>
            <Box>{children}</Box>
          </Grid>
        </Grid>
      </DialogContent>
    </StyledDialogWithImageDialog>
  );
};

export { DialogWithImage };
