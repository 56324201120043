import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const StyledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  fontWeight: '600',
  fontSize: theme.typography.pxToRem(16),
  padding: theme.spacing(2),
  borderRadius: theme.typography.pxToRem(4),
  boxShadow: 'none',
  margin: `${theme.spacing(1)} 0 ${theme.spacing(3)}`,
}));

const ContinueBtn = (props) => {
  const { text, disabled, onClickConBtn } = props;
  return (
    <StyledButton
      size="large"
      variant="contained"
      color="primary"
      onClick={onClickConBtn}
      disabled={disabled}
      endIcon={<ArrowForwardIcon />}>
      {text}
    </StyledButton>
  );
};

export { ContinueBtn };
