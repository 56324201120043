import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .RTFromToLine': {
    borderLeft: `1px solid ${theme.palette.secondary.main}`,
    position: 'absolute',
    left: theme.typography.pxToRem(-16),
    top: theme.typography.pxToRem(16),
    height: '100%',
    [theme.breakpoints.down('lg')]: {
      borderLeft: `1px solid ${theme.palette.grey[600]}`,
    },
  },
  '& .RTPickList': {
    position: 'relative',
    paddingBottom: theme.spacing(2),
    '&::before': {
      content: "''",
      position: 'absolute',
      left: theme.typography.pxToRem(-20.5),
      top: theme.typography.pxToRem(8),
      width: theme.spacing(1.25),
      height: theme.spacing(1.25),
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '50%',
      backgroundColor: theme.palette.secondary.main,
    },
  },
  '& .RTTourPickList': {
    '&::before': {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '50%',
      zIndex: 2,
    },
  },
  '& .RTTimeline': {
    position: 'relative',
    paddingLeft: theme.spacing(2),
    listStyleType: 'none',
  },
  '@media print': {
    '& .RTFromToLine': {
      display: 'none',
    },
    '& .RTPickList': {
      '&::before': {
        display: 'none',
      },
    },
    '& .RTTimeline': {
      paddingLeft: 0,
    },
    '& .RTDate , & .RTLocation': {
      fontWeight: 'bold',
    },
  },
}));

export default StyledBox;
