import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography500 from 'views/common/components/UI/Typography500';
import { LocationCard } from 'views/common/components/UI/LocationCard';
import PATHS from 'lib/navigation/paths';

const StyledBox = styled(Box)(({ theme }) => ({
  '&.mapGroup': {
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(3.25),
    },
    maxHeight: theme.spacing(62.75),
    marginTop: theme.spacing(2),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: theme.spacing(1),
    },
    '&::-webkit-scrollbar-track ': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey[400],
      borderRadius: theme.spacing(0.5),
    },
    '& .groupsTitle': {
      fontSize: theme.spacing(2.5),
      textDecoration: 'underline',
    },
  },
}));

const LocationList = (props) => {
  const {
    locationsList,
    handleEmailButtonClick,
    prepareMapUrl,
    handleBookNowButtonCLick,
    handleLocationCardButtonClick,
    handleLocationCardHover,
    target,
    selectedLocationId,
  } = props;

  return (
    <>
      <StyledBox className="mapGroup" id="locationsList">
        {locationsList.map((location, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            {location?.group?.name && (
              <Typography500
                key={location?.group?.name}
                variant="h5"
                component="p"
                className="groupsTitle"
                underline="always">
                {location.group.name}
              </Typography500>
            )}
            {location.locations.map((item, idx) => (
              <div key={item.id}>
                <LocationCard
                  id={item.id}
                  address={item.full_address}
                  link={prepareMapUrl(item.map_url)}
                  title={item.display_name}
                  phone={item.phone}
                  email={item.email}
                  location={PATHS.urlSlugs(item.slug)}
                  handleEmailButtonClick={handleEmailButtonClick}
                  handleBookNowButtonCLick={handleBookNowButtonCLick}
                  locationIndex={item.order || idx + 1}
                  activeCard={item.activeCard || selectedLocationId === item.id}
                  handleLocationCardButtonClick={handleLocationCardButtonClick}
                  handleLocationCardHover={handleLocationCardHover}
                  target={target}
                />
              </div>
            ))}
          </div>
        ))}
      </StyledBox>
    </>
  );
};

export { LocationList };
