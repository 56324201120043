import { styled } from '@mui/material/styles';
import { FormControl, MenuItem, ListSubheader } from '@mui/material';

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  '&.hiddenMenuItem': {
    marginBottom: theme.spacing(1),
  },
  '& .MuiTypography-root': {
    whiteSpace: 'break-spaces',
  },
}));

export const StyledMenuTitle = styled(ListSubheader)(({ theme }) => ({
  '&.menuTitle': {
    width: theme.spacing(31.25), // thame width of menu list
    position: 'fixed',
    top: 'unset',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    backgroundColor: theme.palette.grey.A000,
    '& .MuiTypography-root': {
      fontWeight: '600',
      color: theme.palette.text.primary,
      fontSize: theme.spacing(2),
      lineHeight: theme.spacing(3),
    },
    '& .iconbtn': {
      position: 'absolute',
      right: '2%',
      top: '20%',
    },
  },
}));

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1, 2, 1, 0),
  width: 'auto',
  '& .selectInput': {
    height: theme.spacing(5),
    fontSize: theme.spacing(1.75),
    color: theme.palette.grey[400],
    backgroundColor: theme.palette.grey.A000,
    borderColor: theme.palette.grey[100],
    borderRadius: theme.spacing(3),
    '&.Mui-focused': {
      backgroundColor: theme.palette.primary.A000,
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      background: 'transparent',
    },
  },
}));
