import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PLAN_CARD_VARIANTS } from 'views/common/components/UI/PlanCard/PlanCard';

const StyledBox = styled(Box)(({ theme, variant }) => ({
  '&.PCContainer': {
    textAlign: 'center',
    padding:
      variant === PLAN_CARD_VARIANTS.MINIMIZED
        ? theme.spacing(2)
        : theme.spacing(4, 2),
    backgroundColor: theme.palette.common.white,
    border: `${theme.spacing(0.125)} solid ${theme.palette.grey[100]}`,
    borderRadius: theme.spacing(2),
    height: '100%',
    '&:not(.current)': {
      cursor: 'pointer',
    },
    '&.active': {
      backgroundColor: theme.palette.primary.main,

      '& .PCCardTitle': {
        color: theme.palette.common.white,
        textTransform: 'uppercase',
      },
      '& .PCCardSubTitle': {
        color: theme.palette.common.white,
      },
      '& .PCPlanPrice': {
        color: theme.palette.common.white,
        marginBottom: theme.spacing(3),
      },
      '& .PCViewMore': {
        color: theme.palette.common.white,
      },
      '& .PVABenfit': {
        color: theme.palette.common.white,
      },
      '& .PVSelectBtn': {
        backgroundColor: theme.palette.common.white,
        border: `${theme.spacing(0.125)} solid ${theme.palette.common.white}`,
      },
      '& .PVQuestionMark': {
        color: theme.palette.common.white,
      },
      '& .PVAMark': {
        color: theme.palette.common.white,
      },
    },

    '& .PCCardTitle': {
      fontSize: variant === PLAN_CARD_VARIANTS.MINIMIZED && theme.spacing(2.5),
      lineHeight:
        variant === PLAN_CARD_VARIANTS.MINIMIZED && theme.spacing(3.25),
      fontWeight: '500',
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(1),
      },
    },

    '& .PCCardSubTitle': {
      margin: theme.spacing(0.5, 0),
    },

    '& .PCPlanPrice': {
      color: theme.palette.primary.main,
      fontWeight: '500',
      fontSize:
        variant === PLAN_CARD_VARIANTS.MINIMIZED
          ? theme.spacing(2.5)
          : theme.spacing(3.5),
      marginBottom:
        variant === PLAN_CARD_VARIANTS.MINIMIZED
          ? theme.spacing(1)
          : theme.spacing(0),
    },
    '& .PCViewMore': {
      color: theme.palette.grey['300'],
      fontStyle: 'italic',

      '& > b': {
        textDecoration: 'underline',
      },
    },

    '& .PCDevider': {
      height: '1px',
      backgroundColor: theme.palette.grey['100'],

      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(1.25, 2, 2),
      },
    },

    '& .PVAccordion': {
      '&.MuiAccordion-root': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        maxWidth: theme.typography.pxToRem(400),

        [theme.breakpoints.up('lg')]: {
          margin:
            variant === PLAN_CARD_VARIANTS.MINIMIZED
              ? theme.spacing(1, 2, 0)
              : `${theme.spacing(3, 2, 0)} !important`,
        },
        [theme.breakpoints.down('lg')]: {
          marginTop:
            variant === PLAN_CARD_VARIANTS.MINIMIZED
              ? theme.spacing(1)
              : theme.spacing(3),
        },
        '&::before': {
          display: 'none',
        },
      },
    },

    ' & .PVAccordionSummary': {
      '&.MuiAccordionSummary-root': {
        minHeight: '0 !important',
        height: '0',
      },
    },

    ' & .PVAccordionDetails': {
      '&.MuiAccordionDetails-root': {
        flexDirection: 'column',
        padding: '0',
      },
    },

    '& .PVABenfit': {
      display: 'flex',
      justifyContent:
        variant === PLAN_CARD_VARIANTS.MINIMIZED ? 'center' : 'flex-start',
      alignItems: 'flex-start',
      color: theme.palette.secondary.light,
      textAlign: 'left',
      fontSize: variant === PLAN_CARD_VARIANTS.MINIMIZED && theme.spacing(1.5),
      '&:not(:last-child)': {
        marginBottom:
          variant === PLAN_CARD_VARIANTS.MINIMIZED
            ? theme.spacing(0.5)
            : theme.spacing(1.5),
      },
    },

    '& .PVACheckMark': {
      color: theme.palette.success.main,
      marginRight: theme.spacing(0.5),
    },

    '& .PVQuestionMark': {
      color: theme.palette.secondary.light,
      marginLeft: theme.spacing(1),
    },

    '& .PVSelectBtn': {
      '&.MuiButton-root': {
        marginTop: theme.spacing(2),
      },
    },

    '& .PVSelectPlanLink': {
      '&.MuiButton-root': {
        marginTop: theme.spacing(2),
        padding:
          variant === PLAN_CARD_VARIANTS.MINIMIZED && theme.spacing(0.75),
      },
    },
    '& .PCPrepaidNotice': {
      fontSize:
        variant === PLAN_CARD_VARIANTS.MINIMIZED
          ? theme.typography.pxToRem(12)
          : theme.typography.pxToRem(14),
      color: theme.palette.secondary.light,
    },
  },
}));
export default StyledBox;
