import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .RSChip': {
    borderRadius: theme.spacing(0.5),
    height: 'auto',
    borderColor: theme.palette.success.main,
    '& .MuiChip-label': {
      fontSize: theme.typography.pxToRem(10),
      fontWeight: 500,
      textTransform: 'uppercase',
      lineHeight: theme.spacing(2.25),
      color: theme.palette.success.main,
    },
    '&.cancelled': {
      borderColor: theme.palette.grey[100],
      '& .MuiChip-label': {
        color: theme.palette.grey[200],
      },
    },
    '&.pending': {
      borderColor: theme.palette.warning.dark,
      '& .MuiChip-label': {
        color: theme.palette.warning.dark,
      },
    },
    '&.MuiChip-colorWarning': {
      border: `1px solid ${theme.palette.warning.dark}`,
      color: theme.palette.warning.dark,
    },
  },
}));

export default StyledBox;
