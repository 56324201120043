import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .TPSICard': {
    backgroundColor: theme.palette.grey.A000,
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${theme.palette.grey[50]}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    position: 'relative',
  },
  '& .TPSIPackageTitleContainers': {
    padding: theme.spacing(1, 1, 1, 3.8),
    width: '100%',
  },
  '& .TPSIPackageTitle': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    gap: theme.spacing(0.2),
    color: theme.palette.grey[600],
  },
  '& .TPSIPackagPerson': {
    color: theme.palette.secondary.main,
    lineHeight: theme.spacing(3),
  },
  '& .TPSIActive': {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.A000,
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.05)',
  },
  '& .TPSIIcon': {
    position: 'absolute',
    top: theme.spacing(1.2),
    left: theme.spacing(1),
    '&.active': {
      color: theme.palette.primary.main,
    },
    '& svg': {
      fontSize: theme.typography.pxToRem(20),
    },
  },

  '& .TPSIOptions': {
    backgroundColor: theme.palette.grey['50'],
    color: theme.palette.secondary.light,
    padding: theme.spacing(0.75, 1.25),
    width: '100%',
    flexGrow: 1,
    '&.active': {
      backgroundColor: '#FFE8D7',
      color: theme.palette.secondary.main,
    },
  },
  '& .TPSIOptionItems': {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    '&.TPSIOptionsNoMoto': {
      [theme.breakpoints.up('lg')]: {
        justifyContent: 'flex-start',
        gap: theme.spacing(1),
      },
    },
  },
  '& .TPSIOptionItem': {
    fontSize: theme.typography.pxToRem(10),
    lineHeight: theme.spacing(3),
  },
  '& .TPSIOptionIcon': {
    fontSize: theme.typography.pxToRem(14),
    position: 'relative',
    top: theme.typography.pxToRem(4),
    paddingRight: theme.typography.pxToRem(1),
    '&.TPSIPersonIcon': {
      fontSize: theme.typography.pxToRem(16),
      top: theme.typography.pxToRem(3),
    },
  },
}));
export default StyledBox;
