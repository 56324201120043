import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const AppAdMobileSkeleton = () => (
  <>
    <Skeleton variant="rectangular" width="100%" height={50} />
  </>
);

export { AppAdMobileSkeleton };
