import React from 'react';
// Material UI core
import { alpha, styled } from '@mui/material/styles';
import {
  Grid, Button, Container, Typography,
} from '@mui/material';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';

const Root = styled('div')(({ theme }) => ({
  '&': {
    padding: theme.spacing(3, 0),
    backgroundColor: alpha(theme.palette.warning.light, 0.3),
  },
  '& .icon': {
    fontSize: theme.typography.pxToRem(56),
    color: theme.palette.primary.light,
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.typography.pxToRem(37),
    },
  },
  '& .title': {
    fontSize: theme.typography.pxToRem(26),
    fontWeight: 600,
    letterSpacing: theme.typography.pxToRem(0.25),
    lineHeight: 'initial',
    marginBottom: theme.spacing(1.2),
    textTransform: 'capitalize',
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.typography.pxToRem(23),
    },
  },
  '& .subtitle': {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(19),
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.typography.pxToRem(17),
    },
  },
  '& .note': {
    fontSize: theme.typography.pxToRem(18),
    margin: theme.spacing(0.7, 0, 1),
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  '& .reviewButton': {
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const ReviewBookingStatusAlert = ({
  title, subtitle, content, link,
}) => (
  <Root>
    <Container maxWidth="xl">
      <Grid container spacing={3}>
        <Grid item md={1} xs={2}>
          <QueryBuilderOutlinedIcon className="icon" />
        </Grid>
        <Grid item md={11} xs={10}>
          <Typography className="title" variant="h3">
            {title}
          </Typography>
          <Typography className="subtitle" variant="h5">
            {subtitle}
          </Typography>
          <Typography className="note" variant="h5">
            {content}
          </Typography>
          <Button
            className="reviewButton"
            component={NavigatorLink}
            href={link.url}
            variant="contained"
            disableElevation>
            {link.text}
          </Button>
        </Grid>
      </Grid>
    </Container>
  </Root>
);

export { ReviewBookingStatusAlert };
