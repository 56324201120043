import React, { useState } from 'react';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { localeDateFormatMap } from 'lib/config/date-picker';
/**
 * Shared DatePicker that takes care of date format based on the locale
 * It also positioning the datepicker based on the media query
 */
const DatePicker = (props) => {
  const {
    error, helperText, className, slotProps, ...otherProps
  } = props;
  const [open, setOpen] = useState(false);

  return (
    <MuiDatePicker
      closeOnSelect
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      format={localeDateFormatMap.en}
      slotProps={{
        textField: {
          fullWidth: true,
          margin: 'none',
          variant: 'outlined',
          onClick: () => setOpen(true),
          helperText,
          error: !!error,
        },
        ...slotProps,
      }}
      error={error}
      classes={{ root: className }}
      {...otherProps}
    />
  );
};

export default DatePicker;
